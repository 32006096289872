import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import { Link } from 'react-router-dom';
import ToolsHead from '../components/toolsHead';
import { formatearFecha, timeZone } from '../../core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { all_routes } from '../../Router/all_routes';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setHolidayList, setShowRemoteResponseToast } from '../../core/redux/action';
import { showInternalErrorAlert } from '../components/customAlert';
import { Holidays } from '../../services/internal/holidays';
import { Spinner } from 'react-bootstrap';
import CheckInModal from '../../core/modals/hrm/checkIn';
import { Functions, Modules, verifyPermission } from '../../Router/authorization';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import es from 'date-fns/locale/es';
import { CheckIns } from '../../services/internal/checkIns';

const AttendanceEmployee = () => {

    const userDetails = useSelector((state) => state.userDetails);
    const holidayList = useSelector((state) => state.holidayList);
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const dispatch = useDispatch();
    const [showCheckInStartModal, setShowCheckInStartModal] = useState(false);
    const [showCheckInEndModal, setShowCheckInEndModal] = useState(false);
    const [hora, setHora] = useState(new Date());
    const [events, setEvents] = useState([]);
    const route = all_routes;

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadHolidays = async () => {
        try {

            let filters = {};
            filters.enabled = true
            dispatch(fetchDataRequest());
            const response = await Holidays.getList(token, filters);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setHolidayList(response.data.data));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadAttendance = async () => {
        try {
            let filters = {};
            filters.enabled = true;
            dispatch(fetchDataRequest());
            const response = await CheckIns.getList(token, filters);
            setEvents(buildEvents(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }

    }

    const buildEvents = (checkIns) => {
        const events = [];
        checkIns.map((c) => {
            c.records.map((r) => {
                events.push({
                    title: r.userAlias ? r.userAlias : `Usuario: ${r.userId}`,
                    start: r.checkIn,
                    end: r.checkOut,
                });
            });
        });
        return events;
    }

    const obtenerDiasDelMesActual = () => {
        const fechaActual = new Date();
        const año = fechaActual.getFullYear();
        const mes = fechaActual.getMonth() + 1;
        return new Date(año, mes, 0).getDate();
    }

    const filtrarFechas = () => {
        const date = new Date();
        const mesFiltro = date.getMonth() + 1;
        const anioFiltro = date.getFullYear();

        return holidayList.filter(fechaObj => {
            const fechaInicio = new Date(fechaObj.start); // Convertimos fechaInicio a Date
            const fechaFin = new Date(fechaObj.end);       // Convertimos fechaFin a Date

            const mesFechaInicio = fechaInicio.getMonth() + 1;
            const anioFechaInicio = fechaInicio.getFullYear();

            const mesFechaFin = fechaFin.getMonth() + 1;
            const anioFechaFin = fechaFin.getFullYear();

            return (mesFechaInicio === mesFiltro && anioFechaInicio === anioFiltro) ||
                (mesFechaFin === mesFiltro && anioFechaFin === anioFiltro);
        });
    };

    const calculateName = () => {
        if (!userDetails) {
            return "";
        }

        let fullName = `${userDetails.name ? userDetails.name : ''} ${userDetails.surname ? userDetails.surname : ''}`;
        return fullName.trim() ? fullName : userDetails.alias;
    }


    useEffect(() => {
        const temporizador = setInterval(() => {
            setHora(new Date());
        }, 1000);

        if (holidayList.length === 0) {
            onLoadHolidays();
        }

        onLoadAttendance();

        return () => {
            clearInterval(temporizador);
        };
    }, []);

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="attendance-header">
                        <div className="attendance-content">
                            <ImageWithBasePath
                                src="./assets/img/icons/hand01.svg"
                                className="hand-img"
                                alt="img"
                            />
                            <h3>
                                Hola, <span>{calculateName()}</span>
                            </h3>
                        </div>
                    </div>
                    <div className="attendance-widget">
                        <div className="row">
                            <div className="col-xl-4 col-lg-12 col-md-4 d-flex">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <h5>
                                            Asistencia<span>{formatearFecha(hora.toISOString(), "dd-MMMM-yyyy")}</span>
                                        </h5>
                                        <div className="card attendance">
                                            <div>
                                                <ImageWithBasePath src="./assets/img/icons/time-big.svg" alt="time-img" />
                                            </div>
                                            <div>
                                                <h2>{formatearFecha(hora.toISOString(), "hh:mm:ss aa")}</h2>
                                                <p>Hora actual</p>
                                            </div>
                                        </div>
                                        <div className="modal-attendance-btn flex-column flex-lg-row">
                                            <button
                                                className="btn btn-primary w-100"
                                                onClick={() => { setShowCheckInStartModal(true) }}
                                                disabled={loading}
                                            >
                                                Check In
                                            </button>
                                            <button
                                                className="btn btn-secondary w-100"
                                                onClick={() => { setShowCheckInEndModal(true) }}
                                                disabled={loading}
                                            >
                                                Check Out
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-12 col-md-8 d-flex">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <h5>Resumen del mes</h5>
                                        <ul className="widget-attend">
                                            <li className="box-attend">
                                                <div className="warming-card">
                                                    <h4>{obtenerDiasDelMesActual()}</h4>
                                                    <h6>Días</h6>
                                                </div>
                                            </li>
                                            <li className="box-attend">
                                                <div className="danger-card">
                                                    <h4>{obtenerDiasDelMesActual() - filtrarFechas().length}</h4>
                                                    <h6>Días laborales</h6>
                                                </div>
                                            </li>
                                            <li className="box-attend">
                                                <div className="light-card">
                                                    <h4>{loading ? <Spinner size='sm' /> : filtrarFechas().length}</h4>
                                                    <Link to={route.holidays}><h6>Días festivos</h6></Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Control de Asistencia</h4>
                            </div>
                        </div>
                        <ToolsHead
                            showExportData={verifyPermission(rolePermissions, Modules.Asistencia, Functions.Exportar)}
                            showExcel={true}
                            onReload={onLoadAttendance}
                        />
                    </div>
                    {/* /product list */}
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <FullCalendar
                                    timeZone={timeZone}
                                    plugins={[dayGridPlugin]}
                                    buttonText={{
                                        today: 'Hoy',
                                    }}
                                    allDaySlot={false}
                                    firstDay={1}
                                    locale={es}
                                    initialView='dayGridWeek'
                                    events={events}
                                />
                            </div>
                        </div>
                    </div>
                    {/* /product list */}
                </div>
            </div>
            {
                showCheckInStartModal &&
                <CheckInModal id="checkin-start-modal" show={showCheckInStartModal} onHide={() => setShowCheckInStartModal(false)} />
            }
            {
                showCheckInEndModal &&
                <CheckInModal id="checkin-end-modal" show={showCheckInEndModal} onHide={() => setShowCheckInEndModal(false)} isCheckOut={true} />
            }
        </div>
    )
}

export default AttendanceEmployee
