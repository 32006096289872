import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { useDispatch, useSelector } from "react-redux";
import { Users } from "../../services/internal/users";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast, setUserDetailsImagePath } from "../../core/redux/action";
import { showInternalErrorAlert } from "../components/customAlert";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { fechaFormat, formatearFecha, strongPassRegex } from "../../core/utils";
import { Password } from "../../services/internal/password";
import AvatarUpload from "../components/fileUpload/AvatarUpload";
import variables from "../../style/scss/utils/_variables.scss";
import { Info, Mail } from "react-feather";
import { useSearchParams } from "react-router-dom";
import { Client } from "../../services/internal/client";
import { Providers } from "../../services/internal/providers";

const Profile = () => {
  const { getValues, register, handleSubmit, reset, formState: { errors } } = useForm();
  const logonUserDetails = useSelector((state) => state.userDetails);
  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [inputType, setInputType] = useState("password");
  const [imagePath, setImagePath] = useState("assets/img/profiles/profile.png");
  const [userDetails, setUserDetails] = useState(null);

  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get('code');
  const customerParam = searchParams.get('customer');
  const supplierParam = searchParams.get('supplier');

  const onShowPassword = () => {
    if (inputType === "text") {
      setInputType("password");
      return;
    }
    setInputType("text");
  }

  const calculateName = () => {
    if (!userDetails) {
      return "";
    }

    let fullName = `${userDetails.name ? userDetails.name : ''} ${userDetails.surname ? userDetails.surname : ''}`;
    return fullName.trim() ? fullName : userDetails.alias;
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadUserDetails = async () => {
    const userId = Number(codeParam);
    if (isNaN(userId)) {
      return;
    }
    try {
      dispatch(fetchDataRequest());
      const response = await Users.getSingle(token, Number(userId));
      setUserDetails(response.data.data);
      setImagePath(response.data.data.imagePath);
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onLoadCustomerDetails = async () => {
    const clientId = Number(customerParam);
    if (isNaN(clientId)) {
      return;
    }
    try {
      dispatch(fetchDataRequest());
      const response = await Client.getSingle(token, Number(clientId));
      setUserDetails(response.data.data);
      setImagePath(response.data.data.imagePath);
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onLoadSupplierDetails = async () => {
    const supplierId = Number(supplierParam);
    if (isNaN(supplierId)) {
      return;
    }
    try {
      dispatch(fetchDataRequest());
      const response = await Providers.getSingle(token, Number(supplierId));
      setUserDetails(response.data.data);
      setImagePath(response.data.data.imagePath);
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onUpdateUserDetails = async (formData) => {
    //console.log(formData)
    try {
      let data = Password.requestData;
      data = {};

      data.currentPassword = formData.currentPass;
      data.password = formData.pass;

      dispatch(fetchDataRequest());

      const response = await Password.putChange(data);
      //console.log(response)
      dispatch(fetchDataSuccess(response.data.success));
      dispatch(setShowRemoteResponseToast(true));
      clearForm();
    }
    catch (err) {
      handleException(err);
    }
  }

  const clearForm = async () => {

    reset(formValues => ({
      ...formValues,
      currentPass: null,
      newPass: null,
      pass: null
    }));
  }

  const onChangedImage = async (newImagePath) => {
    if (newImagePath) {
      setImagePath(newImagePath);
      dispatch(setUserDetailsImagePath(newImagePath));

      try {
        dispatch(fetchDataRequest());

        const data = {
          alias: userDetails.alias,
          email: userDetails.email,
          roleId: userDetails.role?.id,
          id: userDetails.id,
          imagePath: newImagePath,
        }

        const response = await Users.putSingle(token, data);
        dispatch(fetchDataSuccess(response.data.success));
      }
      catch (err) {
        handleException(err);
      }

    }
  }

  const isUser = () => {
    if (supplierParam) {
      return false;
    }

    if (customerParam) {
      return false;
    }

    return true;
  }

  const isSameUser = () => {
    if (codeParam) {
      return codeParam === logonUserDetails?.id;
    }
    if (supplierParam) {
      return supplierParam === logonUserDetails?.id;
    }
    if (customerParam) {
      return customerParam === logonUserDetails?.id;
    }
    return true;
  }

  useEffect(() => {
    if (supplierParam) {
      onLoadSupplierDetails();
    }
    else if (customerParam) {
      onLoadCustomerDetails();
    }
    else if (logonUserDetails) {
      if (isUser() && !isSameUser()) {
        onLoadUserDetails();
      } else {
        setUserDetails(logonUserDetails);
        setImagePath(logonUserDetails.imagePath);
      }
    }
  }, [logonUserDetails, codeParam, supplierParam, customerParam]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Perfil</h4>
            {isSameUser()
              ? <h6>Te mostramos la información de tu perfil, algunos datos solo los puede editar el administrador.</h6>
              : <h6>Te mostramos la información del perfil.</h6>
            }
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body">
            <div className="profile-set">
              <div className="profile-head"></div>
              <div className="row">
                <div className="col-lg-2 col-sm-12 col-12 d-flex justify-content-center mb-3">
                  <AvatarUpload
                    prefix="user_image"
                    imageGrouperId="users"
                    userId={`${userDetails?.id}`}
                    avatarPath={imagePath}
                    onImageUploaded={onChangedImage}
                    showUploadButton={isUser() && isSameUser()}
                  />
                </div>
                <div className="col-lg-9 col-sm-12 d-flex align-items-center mb-3">
                  <div>
                    <h3 className="text-primary mb-1">{calculateName()}</h3>
                    {isUser() &&
                      <h4 className="mb-1">Fecha de ingreso: {userDetails ? formatearFecha(userDetails.joinDate ?? new Date().toISOString(), fechaFormat) : "Sin asignar"}</h4>
                    }
                    {isUser() &&
                      <h5 className="text-secondary mb-1">Puesto: {userDetails ? (userDetails.designation?.name ?? "Sin asignar") : "Sin asignar"}</h5>
                    }
                    {isUser() &&
                      <h5 className="text-secondary mb-1">No. de empleado: {userDetails ? (userDetails.employeeId?.name ?? "Sin asignar") : "Sin asignar"}</h5>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {isUser() &&
                <div className="col-lg-4 col-sm-12 mb-3">
                  <label className="form-label">Alias de usuario<span className="text-danger"> *</span></label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={userDetails?.alias}
                    disabled
                  />
                </div>
              }
              {isUser() &&
                <div className="col-lg-4 col-sm-12 mb-3">
                  <label className="form-label">Rol de sistema<span className="text-danger"> *</span></label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={userDetails?.role?.name}
                    disabled
                  />
                </div>
              }
              {isUser() &&
                <div className="col-lg-4 col-sm-12 mb-3">
                  <label className="form-label">Sucursal</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={userDetails?.branch?.name ?? "Sin asignar"}
                    disabled
                  />
                </div>
              }
              {isUser() &&
                <div className="col-lg-4 col-sm-12 mb-3">
                  <label className="form-label">Nombre(s)</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={userDetails?.name}
                    disabled
                  />
                </div>
              }
              {isUser() && isSameUser() &&
                < div className="col-lg-4 col-sm-12 mb-3">
                  <label className="form-label">Apellido Paterno</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={userDetails?.surname}
                    disabled
                  />
                </div>
              }
              {isUser() && isSameUser() &&
                <div className="col-lg-4 col-sm-12 mb-3">
                  <label className="form-label">Apellido Materno</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={userDetails?.lastname}
                    disabled
                  />
                </div>
              }
              <div className="col-lg-6 col-sm-12 mb-3">
                <label className="form-label">Correo electrónico<span className="text-danger"> *</span></label>
                <div className="input-group">
                  <div className="input-group-text"><Mail size={15} color={variables.primaryColor} /></div>
                  <input
                    type="email"
                    className="form-control"
                    defaultValue={userDetails?.email}
                    disabled
                  />
                </div>
              </div>
              {(!isUser() || isSameUser()) &&
                <div className="col-lg-6 col-sm-12 mb-4">
                  <label className="form-label">Teléfono</label>
                  <div className="input-group">
                    <ImageWithBasePath
                      src="assets/img/flags/mx.png"
                      alt="img"
                      id="mx"
                      style={{ height: 40 }}
                      className={"input-group-text"}
                    />
                    <span className="input-group-text">+52</span>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={userDetails?.phone}
                      disabled />
                  </div>
                </div>
              }
            </div>
            {isUser() && isSameUser() &&
              <form onSubmit={handleSubmit((data) => onUpdateUserDetails(data))}>
                <div className="row">
                  <div className="card-title-head">
                    <h6>
                      <span>
                        <Info />
                      </span>
                      Cambio de contraseña
                    </h6>
                  </div>
                  <div className="col-lg-12 col-sm-12 mb-3">
                    <p className="mb-2">
                      Para actualizar tu contraseña necesitamos que nos proporciones
                      la actual y una nueva que cumpla con las siguientes reglas:
                    </p>
                    <div className="alert alert-info" role="alert">
                      <li>Al menos una letra minúscula (a-z).</li>
                      <li>Al menos una letra mayúscula (A-Z).</li>
                      <li>Al menos un dígito (0-9).</li>
                      <li>Al menos un carácter especial (como @ $ % # . ! *).</li>
                      <li>Longitud mínima de 8 caracteres.</li>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <label className="form-label">Contraseña actual<span className="text-danger"> *</span></label>
                    <div className="pass-group">
                      <input
                        type={inputType}
                        className={!errors.pass ? "form-control" : "form-control is-invalid"}
                        {...register("currentPass", {
                          required: "Requerido.",
                          pattern: {
                            value: strongPassRegex,
                            message: "Tu contraseña no cumple las reglas"
                          }
                        })}
                        maxLength={300}
                        disabled={loading}
                      />
                      {
                        !errors.currentPass ?
                          <span className={inputType === "text" ? "fas toggle-password fa-eye-slash" : "fas toggle-password fa-eye"}
                            onClick={() => onShowPassword()} />
                          :
                          <div className="invalid-feedback">
                            {errors.currentPass.message}
                          </div>
                      }
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <label className="form-label">Nueva contraseña<span className="text-danger"> *</span></label>
                    <div className="pass-group">
                      <input
                        type={inputType}
                        className={!errors.pass ? "form-control" : "form-control is-invalid"}
                        {...register("pass", {
                          required: "Requerido.",
                          pattern: {
                            value: strongPassRegex,
                            message: "Tu contraseña no cumple las reglas"
                          },
                          validate: {
                            confirmPass: (value) => {
                              const { currentPass } = getValues();
                              return currentPass !== value || "Tu contraseña nueva no puede ser igual a la actual";
                            }
                          }
                        })}
                        maxLength={300}
                        disabled={loading}
                      />
                      {
                        !errors.pass ?
                          <span className={inputType === "text" ? "fas toggle-password fa-eye-slash" : "fas toggle-password fa-eye"}
                            onClick={() => onShowPassword()} />
                          :
                          <div className="invalid-feedback">
                            {errors.pass.message}
                          </div>
                      }
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <label className="form-label">Confirmar contraseña<span className="text-danger"> *</span></label>
                    <div className="pass-group">
                      <input
                        type={inputType}
                        className={!errors.newPass ? "form-control" : "form-control is-invalid"}
                        {...register("newPass", {
                          required: "Requerido.",
                          pattern: {
                            value: strongPassRegex,
                            message: "Tu contraseña no cumple las reglas"
                          },
                          validate: {
                            confirmPass: (value) => {
                              const { pass } = getValues();
                              return pass === value || "Contraseñas no coinciden";
                            }
                          }
                        })}
                        maxLength={300}
                        disabled={loading}
                      />
                      {
                        !errors.newPass ?
                          <span
                            className={inputType === "text" ? "fas toggle-password fa-eye-slash" : "fas toggle-password fa-eye"}
                            onClick={() => onShowPassword()}
                          />
                          : <div className="invalid-feedback">
                            {errors.newPass.message}
                          </div>
                      }
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-4">
                      <div className="d-flex justify-content-center mb-4">
                        {
                          loading ?
                            <button disabled={loading} className="btn btn-submit">
                              <Spinner animation="border" role="status" size="sm" />
                            </button>
                            :
                            <input type="submit" value="Cambiar mi contraseña" className="btn btn-submit" />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            }
          </div>
        </div>
        {/* /product list */}
      </div>
    </div >
  );
};

export default Profile;
