import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setRecipeList, setShowRemoteResponseToast, setStoreOptions } from "../../core/redux/action";
import { showInternalErrorAlert } from "../components/customAlert";
import { Catalogs } from "../../services/internal/catalogs";
import { Functions, Modules, verifyPermission } from "../../Router/authorization";
import { convertToOptions, fechaFormat, fechaHoraFormat, formatearFecha, generarCodigo, noOptionsText } from "../../core/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Calendar, RefreshCw } from "react-feather";
import { Table } from "antd";
import FilterControl from "../components/filterControl";
import ToolsHead from "../components/toolsHead";
import { Reports } from "../../services/internal/reports";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import variables from "../../style/scss/utils/_variables.scss";
import es from 'date-fns/locale/es';
import { Controller, useForm } from "react-hook-form";
import ReactApexChart from "react-apexcharts";
import alasql from "alasql";
import { Recipes } from "../../services/internal/recipes";

const SupplierReport = () => {

  registerLocale('es', es)

  const sd = new Date();
  const ed = new Date();
  sd.setHours(0, 0, 0);
  ed.setHours(23, 59, 59);
  const chartHeight = 350;

  const loading = useSelector((state) => state.loading);
  const token = useSelector(state => state.token);
  const rolePermissions = useSelector(state => state.rolePermissions);
  const recipeList = useSelector(state => state.recipeList);
  const storeOptions = useSelector(state => state.storeOptions);
  const dispatch = useDispatch();
  const { handleSubmit, setValue, formState: { errors }, control } = useForm();
  const [filterStore, setFilterStore] = useState(null);
  const [filterRecipe, setFilterRecipe] = useState(null);
  const [filterStart, setFilterStart] = useState(sd);
  const [filterEnd, setFilterEnd] = useState(ed);
  const [dataSource, setDataSource] = useState([]);
  const [recipeOptions, setRecipeOptions] = useState([]);
  const [chartOptions, setChartOptions] = useState(null);
  const [chartSeries, setChartSeries] = useState(null);
  const [pieOptions, setPieOptions] = useState(null);
  const [pieSeries, setPieSeries] = useState(null);

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadOptions = async () => {
    try {
      dispatch(fetchDataRequest());

      if (storeOptions.length === 0) {
        const responseA = await Catalogs.getOptions(token, Catalogs.Name.Branches);
        dispatch(setStoreOptions(responseA.data.data));
      }

      if (recipeList.length === 0) {
        const responseB = await Recipes.getList(token, { enabled: true });
        dispatch(setRecipeList(responseB.data.data));
        setRecipeOptions(convertToOptions(responseB.data.data));
      }
      else {
        setRecipeOptions(convertToOptions(recipeList));
      }

      dispatch(fetchDataSuccess(true));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onLoadReport = async () => {
    let filters = {};
    if (filterStore) {
      if (Array.isArray(filterStore)) {
        filters.branches = filterStore?.map(o => (o?.value));
      } else {
        filters.branches = filterStore?.value;
      }
    }
    if (filterRecipe) {
      if (Array.isArray(filterRecipe)) {
        filters.recipes = filterRecipe?.map(o => (o?.value));
      } else {
        filters.recipes = filterRecipe?.value;
      }
    }
    if (filterStart) {
      const date = new Date(filterStart);
      date.setHours(0, 0, 0);
      filters.dateStart = date.toISOString();
    }
    if (filterEnd) {
      const date = new Date(filterEnd);
      date.setHours(23, 59, 59);
      filters.dateEnd = date.toISOString();
    }

    try {
      dispatch(fetchDataRequest());
      const response = await Reports.getProduction(token, filters);
      setDataSource(response.data.data);
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onChangeStart = (field) => {
    const fe = new Date(filterEnd);
    if (fe?.getTime() < field?.getTime()) {
      setFilterEnd(fe?.setDate(field?.getDate() + 1));
    }
    setFilterStart(field);
  }

  const onChangeEnd = (field) => {
    setFilterEnd(field);
  }

  const getPieSeries = () => {
    const branches = getDistinct("branchName");
    if (branches) {
      const data = branches.map(b => (getSum("executions", "branchName", b) ?? 0));
      return data;
    }
    return [];
  }

  const getPieOptions = () => {
    return {
      chart: {
        type: 'pie',
        fontFamily: 'Nunito',
      },
      labels: getDistinct("branchName"),
      dataLabels: {
        enabled: true,
        formatter: (val) => {
          return `${val.toFixed(2)}%`; // Muestra valores con 2 decimales
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      tooltip: {
        y: {
          formatter: (value) => `${value}`, // Personaliza los valores mostrados en el tooltip
        },
      },
      theme: {
        palette: 'palette2',
      }
    };
  }

  const getChartSeries = () => {
    const recipes = getDistinct("recipeName");
    if (recipes) {
      const data = recipes.map(r => (getSum("executions", "recipeName", r) ?? 0));
      return data;
    }
    return [];
  }

  const getChartOptions = () => {
    return {
      chart: {
        type: 'donut',
        fontFamily: 'Nunito',
      },
      labels: getDistinct("recipeName"),
      dataLabels: {
        enabled: true,
        formatter: (val) => {
          return `${val.toFixed(2)}%`; // Muestra valores con 2 decimales
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
            labels: {
              show: true,
              value: {
                show: true,
                formatter: (value) => value,
              },
              total: {
                show: true,
                label: "Ejecuciones",
                formatter: () => `${getChartSeries().reduce((acc, val) => acc + val, 0)}`,
              },
            },
          },
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      tooltip: {
        y: {
          formatter: (value) => `${value}`, // Personaliza los valores mostrados en el tooltip
        },
      },
    };
  }

  const getDistinct = (property) => {
    if (dataSource?.length === 0) {
      return [];
    }

    try {
      const result = alasql(`COLUMN OF SELECT DISTINCT [${property}] FROM ? ORDER BY [${property}]`, [dataSource]);
      return result;
    }
    catch (e) {
      console.error(e);
      return [];
    }
  }

  const getSum = (sumProp, groupProp, whereProp) => {
    if (dataSource?.length === 0) {
      return [];
    }

    try {
      const result = alasql(`VALUE OF SELECT SUM([${sumProp}]) AS summatory FROM ? WHERE [${groupProp}]='${whereProp}' GROUP BY [${groupProp}] ORDER BY [${groupProp}]`, [dataSource]);
      return result;
    }
    catch (e) {
      console.error(e);
      return [];
    }
  }

  const onRefresh = async () => {
    setFilterRecipe([]);
    setFilterStore([]);
    setValue("start", sd);
    setValue("end", ed);
    setFilterStart(sd);
    setFilterEnd(ed);
    await onLoadReport();
  }

  useEffect(() => {
    onLoadOptions();
    onLoadReport();
  }, []);

  useEffect(() => {
    setChartSeries(getChartSeries());
    setChartOptions(getChartOptions());

    setPieSeries(getPieSeries());
    setPieOptions(getPieOptions());
  }, [dataSource]);

  const columns = [
    {
      title: "Fecha",
      align: 'center',
      defaultSortOrder: "descend",
      render: (text, record) => (
        <span>{record.date ? formatearFecha(record.date ?? new Date().toISOString(), fechaHoraFormat) : "Aún no hay datos"}</span>
      ),
      sorter: (a, b) => a.date?.localeCompare(b.date)
    },
    {
      title: "Sucursal",
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{record.branchName}</span>
      ),
      sorter: (a, b) => a.branchName?.localeCompare(b.branchName)
    },
    {
      title: "Usuario",
      render: (text, record) => (
        <span className="text-primary fw-semibold">{record.userAlias}</span>
      ),
      sorter: (a, b) => a.userAlias?.localeCompare(b.userAlias),
    },
    {
      title: "Receta",
      render: (text, record) => (
        <span className="text-dark fw-semibold">{record.recipeName}</span>
      ),
      sorter: (a, b) => a.recipeName?.localeCompare(b.recipeName)
    },
    {
      title: "Ejecuciones",
      dataIndex: "executions",
      align: 'center',
      render: (text, record) => (
        record.executions !== 0 ? <span className="badges bg-purple">{text}</span> : null
      ),
      sorter: (a, b) => a.executions - b.executions,
    }
  ];

  const renderInfoTooltip = (props, text) => (
    <Tooltip id="info-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Reporte de Producción</h4>
              <h6>Utiliza los filtros para personalizar los resultados.</h6>
            </div>
          </div>
          <ToolsHead
            showExportData={true}
            showExcel={verifyPermission(rolePermissions, Modules.Existencias, Functions.Exportar)}
            onReload={onRefresh}
          />
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <form className="pe-4 pt-4 ps-4" onSubmit={handleSubmit((data) => onLoadReport(data))}>
              <div className="row">
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <div className="input-group">
                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => renderInfoTooltip(props, "Fecha Inicio")}
                    >
                      <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                    </OverlayTrigger>
                    <Controller
                      control={control}
                      name='start'
                      render={({ field }) => (
                        <DatePicker
                          maxDate={sd}
                          onChange={(date) => { field.onChange(date); onChangeStart(date) }}
                          selected={filterStart}
                          locale="es"
                          dateFormat={fechaFormat}
                          todayButton="Clic aquí para Hoy"
                          className={!errors.start ? "form-control" : "form-control is-invalid"}
                          wrapperClassName={"form-control"}
                          isDisabled={loading}
                          placeholderText="Fecha inicio"
                          showMonthDropdown
                          showYearDropdown
                        />
                      )}
                      rules={{ required: 'Requerido' }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <div className="input-group">
                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => renderInfoTooltip(props, "Fecha Fin")}
                    >
                      <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                    </OverlayTrigger>
                    <Controller
                      control={control}
                      name='end'
                      render={({ field }) => (
                        <DatePicker
                          minDate={filterStart}
                          onChange={(date) => { field.onChange(date); onChangeEnd(date) }}
                          selected={filterEnd}
                          locale="es"
                          dateFormat={fechaFormat}
                          todayButton="Clic aquí para Hoy"
                          className={!errors.end ? "form-control" : "form-control is-invalid"}
                          wrapperClassName={"form-control"}
                          isDisabled={loading}
                          placeholderText="Fecha fin"
                          showMonthDropdown
                          showYearDropdown
                        />
                      )}
                      rules={{ required: 'Requerido' }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <Select
                    options={storeOptions}
                    placeholder="Elige una sucursal"
                    value={filterStore}
                    onChange={(value) => setFilterStore(value)}
                    noOptionsMessage={() => noOptionsText}
                    components={{ Control: FilterControl }}
                    isDisabled={loading}
                    isClearable
                    isMulti
                  />
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <Select
                    options={recipeOptions}
                    onChange={(value) => setFilterRecipe(value)}
                    value={filterRecipe}
                    noOptionsMessage={() => noOptionsText}
                    isClearable
                    placeholder="Elige una receta"
                    components={{ Control: FilterControl }}
                    isDisabled={loading}
                    isMulti
                  />
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => onLoadReport()}
                    disabled={loading}
                  >
                    Filtrar <span><RefreshCw size={18} /></span>
                  </button>
                </div>
              </div>
            </form>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table"
                rowKey={() => generarCodigo()}
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                size="small"
                pagination={
                  {
                    position: ["topRight"],
                    total: dataSource?.length,
                    showTotal: (total) => `Total: ${total}`,
                    showSizeChanger: true
                  }
                }
              />
            </div>
            <div className="row">
              {pieOptions &&
                <div className="col-lg-6 col-sm-6 col-12 p-4">
                  <h4>Producción por Sucursal</h4>
                  <ReactApexChart
                    options={pieOptions}
                    series={pieSeries}
                    type="pie"
                    height={chartHeight}
                  />
                </div>
              }
              {chartOptions &&
                <div className="col-lg-6 col-sm-6 col-12 p-4">
                  <h4>Producción por Receta</h4>
                  <ReactApexChart
                    options={chartOptions}
                    series={chartSeries}
                    type="donut"
                    height={chartHeight}
                  />
                </div>
              }
            </div>
          </div>
        </div>
        {/* /product list */}
      </div >
    </div >
  );
};

export default SupplierReport;
