import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest } from '../../axiosFunctions';
import QueryString from 'qs';

const getInventories = (token, filters) => {
  let config = setToken(token);
  config.params = {
    branches: filters.branches,
    categories: filters.categories,
    article: filters.article,
    dateStart: filters.dateStart,
    dateEnd: filters.dateEnd
  };
  config.paramsSerializer = params => {
    return QueryString.stringify(params)
  }
  return getRequest(
    axiosClientApi,
    "/Reports/Inventories",
    config
  );
}

const getSales = (token, filters) => {
  let config = setToken(token);
  config.params = {
    branches: filters.branches,
    dateStart: filters.dateStart,
    dateEnd: filters.dateEnd
  };
  config.paramsSerializer = params => {
    return QueryString.stringify(params)
  }
  return getRequest(
    axiosClientApi,
    "/Reports/Sales",
    config
  );
}

const getSalesByProduct = (token, filters) => {
  let config = setToken(token);
  config.params = {
    branches: filters.branches,
    dateStart: filters.dateStart,
    dateEnd: filters.dateEnd
  };
  config.paramsSerializer = params => {
    return QueryString.stringify(params)
  }
  return getRequest(
    axiosClientApi,
    "/Reports/SalesByProduct",
    config
  );
}

const getCashOutcome = (token, filters) => {
  let config = setToken(token);
  config.params = {
    branches: filters.branches,
    concepts: filters.concepts,
    dateStart: filters.dateStart,
    dateEnd: filters.dateEnd
  };
  config.paramsSerializer = params => {
    return QueryString.stringify(params)
  }
  return getRequest(
    axiosClientApi,
    "/Reports/CashOutcome",
    config
  );
}

const getProduction = (token, filters) => {
  let config = setToken(token);
  config.params = {
    branches: filters.branches,
    recipes: filters.recipes,
    dateStart: filters.dateStart,
    dateEnd: filters.dateEnd
  };
  config.paramsSerializer = params => {
    return QueryString.stringify(params)
  }
  return getRequest(
    axiosClientApi,
    "/Reports/Production",
    config
  );
}

export const Reports = {
  getInventories,
  getSales,
  getSalesByProduct,
  getCashOutcome,
  getProduction,
};
