import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest } from '../../axiosFunctions';

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    name: filters.name,
    enabled: filters.enabled
  };
  return getRequest(
    axiosClientApi,
    "/List",
    config
  );
}

export const Logs = {
  getList,
};
