import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCategoryOptions, setShowRemoteResponseToast, setStoreOptions } from "../../core/redux/action";
import { showInternalErrorAlert } from "../components/customAlert";
import { Catalogs } from "../../services/internal/catalogs";
import { Functions, Modules, verifyPermission } from "../../Router/authorization";
import { fechaFormat, formatearMoneda, generarCodigo, noOptionsText } from "../../core/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Calendar, RefreshCw } from "react-feather";
import { Table } from "antd";
import FilterControl from "../components/filterControl";
import ToolsHead from "../components/toolsHead";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import variables from "../../style/scss/utils/_variables.scss";
import es from 'date-fns/locale/es';
import { Controller, useForm } from "react-hook-form";
import ReactApexChart from "react-apexcharts";
import alasql from "alasql";
import { Reports } from "../../services/internal/reports";

const SalesReport = () => {

  registerLocale('es', es)

  const sd = new Date();
  const ed = new Date();
  sd.setHours(0, 0, 0);
  ed.setHours(23, 59, 59);
  const chartHeight = 350;

  const loading = useSelector((state) => state.loading);
  const token = useSelector(state => state.token);
  const rolePermissions = useSelector(state => state.rolePermissions);
  const storeOptions = useSelector(state => state.storeOptions);
  const dispatch = useDispatch();
  const { handleSubmit, setValue, formState: { errors }, control } = useForm();
  const [filterStore, setFilterStore] = useState(null);
  const [filterStart, setFilterStart] = useState(sd);
  const [filterEnd, setFilterEnd] = useState(ed);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceByProducts, setDataSourceByProducts] = useState([]);
  const [areaOptions, setAreaOptions] = useState(null);
  const [areaSeries, setAreaSeries] = useState(null);
  const [pieOptions, setPieOptions] = useState(null);
  const [pieSeries, setPieSeries] = useState(null);
  const [donutOptions, setDonutOptions] = useState(null);
  const [donutSeries, setDonutSeries] = useState(null);
  const [sales, setSales] = useState([]);
  const [salesPayment, setSalesPayment] = useState([]);
  const [salesProduct, setSalesProduct] = useState([]);
  const [salesPackage, setSalesPackage] = useState([]);

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadOptions = async () => {
    try {
      dispatch(fetchDataRequest());

      const responseA = await Catalogs.getOptions(token, Catalogs.Name.Branches);
      dispatch(setStoreOptions(responseA.data.data));

      const responseB = await Catalogs.getOptions(token, Catalogs.Name.Categories);
      dispatch(setCategoryOptions(responseB.data.data));

      dispatch(fetchDataSuccess(responseB.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onLoadReport = async () => {
    let filters = {};
    if (filterStore) {
      if (Array.isArray(filterStore)) {
        filters.branches = filterStore?.map(o => (o?.value));
      } else {
        filters.branches = filterStore?.value;
      }
    }
    if (filterStart) {
      const date = new Date(filterStart);
      date.setHours(0, 0, 0);
      filters.dateStart = date.toISOString();
    }
    if (filterEnd) {
      const date = new Date(filterEnd);
      date.setHours(23, 59, 59);
      filters.dateEnd = date.toISOString();
    }

    try {
      dispatch(fetchDataRequest());
      const responseA = await Reports.getSales(token, filters);
      setDataSource(responseA.data.data);
      const responseB = await Reports.getSalesByProduct(token, filters);
      setDataSourceByProducts(responseB.data.data);
      dispatch(fetchDataSuccess(responseB.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onChangeStart = (field) => {
    const fe = new Date(filterEnd);
    if (fe?.getTime() < field?.getTime()) {
      setFilterEnd(fe?.setDate(field?.getDate() + 1));
    }
    setFilterStart(field);
  }

  const onChangeEnd = (field) => {
    setFilterEnd(field);
  }

  const getAreaSeries = () => {
    getSalesByBranch();
    return [{
      name: "Total",
      data: getSales()
    }];
  }

  const locales = [
    {
      name: 'es',
      options: {
        months: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ],
        shortMonths: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Ago',
          'Sep',
          'Oct',
          'Nov',
          'Dic',
        ],
        days: [
          'Domingo',
          'Lunes',
          'Martes',
          'Miércoles',
          'Jueves',
          'Viernes',
          'Sábado',
        ],
        shortDays: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
        toolbar: {
          exportToSVG: 'Exportar a SVG',
          exportToPNG: 'Exportar a PNG',
          menu: 'Menú',
        },
      },
    },
  ];

  const getAreaOptions = () => {
    return {
      chart: {
        type: 'area',
        fontFamily: 'Nunito',
        height: chartHeight,
        zoom: {
          enabled: false
        },
        locales: locales,
        defaultLocale: 'es',
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'HH:mm', // Formato para mostrar horas
        },
        title: {
          text: "Horas"
        }
      },
      yaxis: {
        opposite: true,
        title: {
          text: "$ Ventas"
        },
        labels: {
          formatter: (val) => {
            return `${formatearMoneda(val)}`;
          }
        }
      },
      legend: {
        horizontalAlign: 'left'
      },
      tooltip: {
        x: {
          format: 'dd MMM, HH:mm', // Formato del tooltip para fechas y horas
        },
        y: {
          formatter: (value) => `${formatearMoneda(value)}`,
        },
      },
    };
  }

  const getPieSeries = () => {
    if (dataSource?.length === 0) {
      return [0];
    }

    try {
      const result = alasql(`COLUMN OF SELECT SUM([total]) AS summatory, [paymentMethodName] AS payment FROM ? GROUP BY [paymentMethodName] ORDER BY [paymentMethodName]`, [dataSource]);
      //console.log(result)
      return result;
    }
    catch (e) {
      console.error(e);
      return [0];
    }
  }

  const getPieLabels = () => {
    if (dataSource?.length === 0) {
      return [0];
    }

    try {
      const result = alasql(`COLUMN OF SELECT [paymentMethodName] AS payment FROM ? GROUP BY [paymentMethodName] ORDER BY [paymentMethodName]`, [dataSource]);
      //console.log(result)
      return result;
    }
    catch (e) {
      console.error(e);
      return [0];
    }
  }

  const getPieOptions = () => {
    return {
      chart: {
        type: 'pie',
        fontFamily: 'Nunito',
      },
      labels: getPieLabels(),
      dataLabels: {
        enabled: true,
        formatter: (val) => {
          return `${val.toFixed(2)}%`; // Muestra valores con 2 decimales
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      tooltip: {
        y: {
          formatter: (value) => `${formatearMoneda(value)}`, // Personaliza los valores mostrados en el tooltip
        },
      },
    };
  }

  const getDonutSeries = () => {
    if (dataSource?.length === 0) {
      return [0];
    }

    try {
      const result = alasql(`COLUMN OF SELECT SUM([total]) FROM ? GROUP BY [branchName] ORDER BY [branchName]`, [dataSource]);
      //console.log(result)
      return result;
    }
    catch (e) {
      console.error(e);
      return [0];
    }
  }

  const getDonutLabels = () => {
    if (dataSource?.length === 0) {
      return [];
    }

    try {
      const result = alasql(`COLUMN OF SELECT [branchName] FROM ? GROUP BY [branchName] ORDER BY [branchName]`, [dataSource]);
      //console.log(result)
      return result;
    }
    catch (e) {
      console.error(e);
      return [];
    }
  }

  const getProductSummary = () => {
    if (dataSourceByProducts?.length === 0) {
      return [];
    }

    try {
      const result = alasql(`
        SELECT 
          [productName], 
          SUM([subTotal]) AS summatory, 
          SUM([quantity])  AS quantity, 
          ROUND((SUM([subTotal]) * 100.0 / (VALUE OF SELECT SUM([subTotal]) FROM ? WHERE [packageId] IS NULL)),2) AS porcentaje 
        FROM ? 
        WHERE [packageId] IS NULL 
        GROUP BY [productName] 
        ORDER BY [date]`, [dataSourceByProducts, dataSourceByProducts]);
      //console.log(result)
      return result;
    }
    catch (e) {
      console.error(e);
      return [];
    }
  }

  const getPackageSummary = () => {
    if (dataSourceByProducts?.length === 0) {
      return [];
    }

    try {
      const result = alasql(`
        SELECT 
          [packageName], 
          SUM([subTotal]) AS summatory, 
          SUM([quantity])  AS quantity, 
          ROUND((SUM([subTotal]) * 100.0 / (VALUE OF SELECT SUM([subTotal]) FROM ? WHERE [productId] IS NULL)),2) AS porcentaje 
        FROM ? 
        WHERE [productId] IS NULL 
        GROUP BY [packageName] 
        ORDER BY [date]`, [dataSourceByProducts, dataSourceByProducts]);
      //console.log(result)
      return result;
    }
    catch (e) {
      console.error(e);
      return [];
    }
  }

  const getDonutOptions = () => {
    return {
      chart: {
        type: 'donut',
        fontFamily: 'Nunito',
      },
      labels: getDonutLabels(),
      dataLabels: {
        enabled: true,
        formatter: (val) => {
          return `${val.toFixed(2)}%`; // Muestra valores con 2 decimales
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
            labels: {
              show: true,
              value: {
                show: true,
                formatter: (value) => `${formatearMoneda(value)}`,
              },
              total: {
                show: true,
                label: "Total",
                formatter: () => `${formatearMoneda(getDonutSeries().reduce((acc, val) => acc + val, 0))}`,
              },
            },
          },
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      tooltip: {
        y: {
          formatter: (value) => `${formatearMoneda(value)}`, // Personaliza los valores mostrados en el tooltip
        },
      },
    };
  }

  const getSales = () => {
    if (dataSource?.length === 0) {
      return [{ x: new Date(), y: 0 }];
    }

    try {
      const result = alasql(`
        SELECT 
          [date] AS x, 
          [total] AS y 
        FROM ? 
        ORDER BY date ASC`, [dataSource]);
      return result;
    }
    catch (e) {
      console.error(e);
      return [{ x: new Date(), y: 0 }];
    }
  }

  const getSalesByBranch = () => {
    if (dataSource?.length === 0) {
      return [];
    }

    try {
      const result = alasql(`
        SELECT 
          [branchName] AS branch, 
          COUNT([branchName]) AS quantity, 
          SUM([total]) AS summatory, 
          AVG([total]) AS ticket,
          ROUND((SUM([total]) * 100.0 / (VALUE OF SELECT SUM([total]) FROM ?)),2) AS porcentaje 
        FROM ? 
        GROUP BY [branchName]
      `, [dataSource, dataSource]);
      //console.log(result)
      return result;
    }
    catch (e) {
      console.error(e);
      return [];
    }
  }

  const getSalesByPaymentMethod = () => {
    if (dataSource?.length === 0) {
      return [];
    }

    try {
      const result = alasql(`
        SELECT 
          [paymentMethodName] AS payment, 
          COUNT([paymentMethodName]) AS quantity, 
          SUM([total]) AS summatory, 
          ROUND((SUM([total]) * 100.0 / (VALUE OF SELECT SUM([total]) FROM ?)),2) AS porcentaje 
        FROM ? 
        GROUP BY [paymentMethodName]
      `, [dataSource, dataSource]);
      //console.log(result)
      return result;
    }
    catch (e) {
      console.error(e);
      return [];
    }
  }

  const onRefresh = async () => {
    setFilterStore([]);
    setValue("start", sd);
    setValue("end", ed);
    setFilterStart(sd);
    setFilterEnd(ed);
    await onLoadReport();
  }

  useEffect(() => {
    if (storeOptions.length === 0) {
      onLoadOptions();
    }
    onLoadReport();
  }, []);

  useEffect(() => {
    setAreaSeries(getAreaSeries());
    setAreaOptions(getAreaOptions());

    setPieOptions(getPieOptions());
    setPieSeries(getPieSeries());

    setDonutOptions(getDonutOptions());
    setDonutSeries(getDonutSeries());

    setSales(getSalesByBranch());
    setSalesPayment(getSalesByPaymentMethod());

  }, [dataSource]);

  useEffect(() => {
    setSalesProduct(getProductSummary());
    setSalesPackage(getPackageSummary());
  }, [dataSourceByProducts]);

  const columnsSucursal = [
    {
      title: "Sucursal",
      render: (text, record) => (
        <span className="text-primary-emphasis fw-semibold">{record.branch}</span>
      ),
      sorter: (a, b) => a.branch?.localeCompare(b.branch)
    },
    {
      title: "Total en Ventas",
      align: 'right',
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{formatearMoneda(record.summatory)}</span>
      ),
      sorter: (a, b) => a.summatory - b.summatory
    },
    {
      title: "Cantidad de Ventas",
      align: 'center',
      render: (text, record) => (
        <span className="badge badges-purple">{record.quantity}</span>
      ),
      sorter: (a, b) => a.quantity - b.quantity
    },
    {
      title: "Porcentaje de Ventas",
      align: 'center',
      defaultSortOrder: "descend",
      render: (text, record) => (
        <span className="text-primary fw-semibold">
          {record.porcentaje}%
        </span>
      ),
      sorter: (a, b) => a.porcentaje - b.porcentaje
    },
    {
      title: "Ticket Promedio",
      align: 'right',
      render: (text, record) => (
        <span className="text-orange">{formatearMoneda(record.ticket)}</span>
      ),
      sorter: (a, b) => a.ticket - b.ticket
    },
  ];

  const columnsProducto = [
    {
      title: "Producto",
      render: (text, record) => (
        <span className="text-dark fw-semibold">
          {record.productName}
        </span>
      ),
      sorter: (a, b) => a.productName?.localeCompare(b.productName)
    },
    {
      title: "Total en Ventas",
      align: 'center',
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{formatearMoneda(record.summatory)}</span>
      ),
      sorter: (a, b) => a.summatory - b.summatory
    },
    {
      title: "Cantidad de productos",
      align: 'center',
      render: (text, record) => (
        <span className="badge badges-purple">{record.quantity}</span>
      ),
      sorter: (a, b) => a.quantity?.localeCompare(b.quantity)
    },
    {
      title: "Porcentaje de Ventas",
      align: 'center',
      defaultSortOrder: "descend",
      render: (text, record) => (
        <span className="text-primary fw-semibold">{record.porcentaje}%</span>
      ),
      sorter: (a, b) => a.porcentaje - b.porcentaje
    },
  ];

  const columnsPaquete = [
    {
      title: "Paquete",
      render: (text, record) => (
        <span className="text-dark fw-semibold">
          {record.packageName}
        </span>
      ),
      sorter: (a, b) => a.packageName?.localeCompare(b.packageName)
    },
    {
      title: "Total en Ventas",
      align: 'center',
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{formatearMoneda(record.summatory)}</span>
      ),
      sorter: (a, b) => a.summatory - b.summatory
    },
    {
      title: "Cantidad de paquetes",
      align: 'center',
      render: (text, record) => (
        <span className="badge badges-purple">{record.quantity}</span>
      ),
      sorter: (a, b) => a.quantity?.localeCompare(b.quantity)
    },
    {
      title: "Porcentaje de Ventas",
      align: 'center',
      defaultSortOrder: "descend",
      render: (text, record) => (
        <span className="text-primary fw-semibold">{record.porcentaje}%</span>
      ),
      sorter: (a, b) => a.porcentaje - b.porcentaje
    },
  ];

  const columnsMetodoPago = [
    {
      title: "Método de Pago",
      render: (text, record) => (
        <span className="text-primary-emphasis fw-semibold">{record.payment}</span>
      ),
      sorter: (a, b) => a.payment?.localeCompare(b.payment)
    },
    {
      title: "Total en Ventas",
      align: 'right',
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{formatearMoneda(record.summatory)}</span>
      ),
      sorter: (a, b) => a.summatory - b.summatory
    },
    {
      title: "Cantidad de Ventas",
      align: 'center',
      render: (text, record) => (
        <span className="badge badges-purple">{record.quantity}</span>
      ),
      sorter: (a, b) => a.quantity - b.quantity
    },
    {
      title: "Porcentaje de Ventas",
      align: 'center',
      defaultSortOrder: "descend",
      render: (text, record) => (
        <span className="text-primary fw-semibold">
          {record.porcentaje}%
        </span>
      ),
      sorter: (a, b) => a.porcentaje - b.porcentaje
    },
  ];

  const renderInfoTooltip = (props, text) => (
    <Tooltip id="info-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Reporte de Ventas</h4>
              <h6>Utiliza los filtros para personalizar los resultados.</h6>
            </div>
          </div>
          <ToolsHead
            showExportData={true}
            showExcel={verifyPermission(rolePermissions, Modules.Existencias, Functions.Exportar)}
            onReload={onRefresh}
          />
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <form className="pe-4 pt-4 ps-4" onSubmit={handleSubmit((data) => onLoadReport(data))}>
              <div className="row">
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <div className="input-group">
                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => renderInfoTooltip(props, "Fecha Inicio")}
                    >
                      <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                    </OverlayTrigger>
                    <Controller
                      control={control}
                      name='start'
                      render={({ field }) => (
                        <DatePicker
                          maxDate={sd}
                          onChange={(date) => { field.onChange(date); onChangeStart(date) }}
                          selected={filterStart}
                          locale="es"
                          dateFormat={fechaFormat}
                          todayButton="Clic aquí para Hoy"
                          className={!errors.start ? "form-control" : "form-control is-invalid"}
                          wrapperClassName={"form-control"}
                          isDisabled={loading}
                          placeholderText="Fecha inicio"
                          showMonthDropdown
                          showYearDropdown
                        />
                      )}
                      rules={{ required: 'Requerido' }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <div className="input-group">
                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => renderInfoTooltip(props, "Fecha Fin")}
                    >
                      <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                    </OverlayTrigger>
                    <Controller
                      control={control}
                      name='end'
                      render={({ field }) => (
                        <DatePicker
                          minDate={filterStart}
                          onChange={(date) => { field.onChange(date); onChangeEnd(date) }}
                          selected={filterEnd}
                          locale="es"
                          dateFormat={fechaFormat}
                          todayButton="Clic aquí para Hoy"
                          className={!errors.end ? "form-control" : "form-control is-invalid"}
                          wrapperClassName={"form-control"}
                          isDisabled={loading}
                          placeholderText="Fecha fin"
                          showMonthDropdown
                          showYearDropdown
                        />
                      )}
                      rules={{ required: 'Requerido' }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <Select
                    options={storeOptions}
                    placeholder="Elige una sucursal"
                    value={filterStore}
                    onChange={(value) => setFilterStore(value)}
                    noOptionsMessage={() => noOptionsText}
                    components={{ Control: FilterControl }}
                    isDisabled={loading}
                    isClearable
                  //isMulti
                  />
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => onLoadReport()}
                    disabled={loading}
                  >
                    Filtrar <span><RefreshCw size={18} /></span>
                  </button>
                </div>
              </div>
            </form>
            {/* /Filter */}
            <div className="p-4 mb-3">
              <h4>Ventas por hora</h4>
              {areaOptions && <ReactApexChart
                options={areaOptions}
                series={areaSeries}
                type="area"
                height={chartHeight}
              />
              }
            </div>
            <div className="row p-4 mb-3">
              <h4>Ventas por sucursal</h4>
              <div className="col-lg-5 col-sm-5 col-12 mb-3">
                {donutOptions && <ReactApexChart
                  options={donutOptions}
                  series={donutSeries}
                  type="donut"
                  height={chartHeight}
                />
                }
              </div>
              <div className="col-lg-7 col-sm-7 col-12 mb-3">
                <div className="table-responsive">
                  <Table
                    className="table"
                    rowKey={() => generarCodigo()}
                    columns={columnsSucursal}
                    loading={loading}
                    dataSource={sales}
                    size="small"
                    pagination={false}
                    footer={() => (
                      <div className="text-center">
                        <span className="text-secondary me-4"><strong>Ventas:</strong> {formatearMoneda(sales.reduce((acc, item) => acc + item.summatory, 0))}</span>
                        <span className="text-purple me-4"><strong>Cantidad:</strong> {sales.reduce((acc, item) => acc + item.quantity, 0)}</span>
                        <span className="text-primary"><strong>Porcentaje:</strong> {sales.reduce((acc, item) => acc + item.porcentaje, 0)}%</span>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="ps-4 pb-2 mb-3">
              <h4>Ventas por producto</h4>
            </div>
            <div className="table-responsive mb-4">
              <Table
                className="table"
                rowKey={() => generarCodigo()}
                columns={columnsProducto}
                loading={loading}
                dataSource={salesProduct}
                size="small"
                pagination={false}
                footer={() => (
                  <div className="text-center">
                    <span className="text-secondary me-4"><strong>Ventas:</strong> {formatearMoneda(salesProduct.reduce((acc, item) => acc + item.summatory, 0))}</span>
                    <span className="text-purple me-4"><strong>Cantidad:</strong> {salesProduct.reduce((acc, item) => acc + item.quantity, 0)}</span>
                    <span className="text-primary"><strong>Porcentaje:</strong> {salesProduct.reduce((acc, item) => acc + item.porcentaje, 0)}%</span>
                  </div>
                )}
              />
            </div>
            <div className="ps-4 pb-2 mb-3">
              <h4>Ventas por paquete</h4>
            </div>
            <div className="table-responsive mb-4">
              <Table
                className="table"
                rowKey={() => generarCodigo()}
                columns={columnsPaquete}
                loading={loading}
                dataSource={salesPackage}
                size="small"
                pagination={false}
                footer={() => (
                  <div className="text-center">
                    <span className="text-secondary me-4"><strong>Ventas:</strong> {formatearMoneda(salesPackage.reduce((acc, item) => acc + item.summatory, 0))}</span>
                    <span className="text-purple me-4"><strong>Cantidad:</strong> {salesPackage.reduce((acc, item) => acc + item.quantity, 0)}</span>
                    <span className="text-primary"><strong>Porcentaje:</strong> {salesPackage.reduce((acc, item) => acc + item.porcentaje, 0)}%</span>
                  </div>
                )}
              />
            </div>
            <div className="row p-4 mb-3">
              <h4>Ventas por Método de Pago</h4>
              <div className="col-lg-5 col-sm-5 col-12 mb-3">
                {pieOptions && <ReactApexChart
                  options={pieOptions}
                  series={pieSeries}
                  type="pie"
                  height={chartHeight}
                />
                }
              </div>
              <div className="col-lg-7 col-sm-7 col-12 mb-3">
                <div className="table-responsive">
                  <Table
                    className="table"
                    rowKey={() => generarCodigo()}
                    columns={columnsMetodoPago}
                    loading={loading}
                    dataSource={salesPayment}
                    size="small"
                    pagination={false}
                    footer={() => (
                      <div className="text-center">
                        <span className="text-secondary me-4"><strong>Ventas:</strong> {formatearMoneda(salesPayment.reduce((acc, item) => acc + item.summatory, 0))}</span>
                        <span className="text-purple me-4"><strong>Cantidad:</strong> {salesPayment.reduce((acc, item) => acc + item.quantity, 0)}</span>
                        <span className="text-primary"><strong>Porcentaje:</strong> {salesPayment.reduce((acc, item) => acc + item.porcentaje, 0)}%</span>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /product list */}
      </div >
    </div >
  );
};

export default SalesReport;
