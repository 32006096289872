import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { PurchaseOrders } from '../../services/internal/purchaseOrders';
import { Table } from 'antd';
import { convertToOptions, fechaHoraFormat, formatearFecha, formatearMoneda, generarCodigo, noOptionsText } from '../../core/utils';
import { CheckCircle, CheckSquare, Download, Edit, Eye, Filter, HelpCircle, MessageSquare, Share, ShoppingBag, Shuffle, X, XCircle } from 'react-feather';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setNotes, setProviderOptions, setPurchaseList, setShowRemoteResponseToast, setStoreOptions, setTransferList, setUserList } from '../../core/redux/action';
import { Users } from '../../services/internal/users';
import { showInternalErrorAlert } from '../components/customAlert';
import { all_routes } from '../../Router/all_routes';
import ToolsHead from '../components/toolsHead';
import FilterControl from '../components/filterControl';
import AddPurchases from '../../core/modals/purchases/addpurchases';
import { Catalogs } from '../../services/internal/catalogs';
import NotesEditModal from '../../core/modals/applications/notesEditModal';
import { Statuses } from '../../services/internal/statuses';
import NotesModal from '../../core/modals/applications/notesModal';
import { Functions, Modules, verifyPermission } from '../../Router/authorization';

const PurchasesList = () => {
    const dataSource = useSelector((state) => state.purchaseList);
    const storeOptions = useSelector((state) => state.storeOptions);
    const userList = useSelector((state) => state.userList);
    const providerOptions = useSelector((state) => state.providerOptions);
    const notes = useSelector((state) => state.notes);
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const [filterCode, setFilterCode] = useState("");
    const [filterBranchDestination, setBranchDestination] = useState("");
    const [filterProvider, setFilterProvider] = useState("");
    const [filterUser, setFilterUser] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [filterBrachToBranch, setFilterBrachToBranch] = useState(false);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [showNotesEditModal, setShowNotesEditModal] = useState(false);
    const [newPurchase, setNewPurchase] = useState(false);
    const [newTransfer, setNewTransfer] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const route = all_routes;
    const statusOptions = [
        Statuses.Values.Registered,
        Statuses.Values.Updated,
        Statuses.Values.Requested,
        Statuses.Values.Pending,
        Statuses.Values.Approved,
        Statuses.Values.Received,
        Statuses.Values.Rejected,
        Statuses.Values.Canceled,
    ];

    const onChangeName = (e) => {
        setFilterCode(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadPurchaseOrders = async () => {

        let filters = {};
        filters.enabled = true;

        if (filterCode) {
            filters.code = filterCode;
        }
        if (filterBranchDestination) {
            filters.branchIdDestination = filterBranchDestination.value;
        }
        if (filterProvider) {
            filters.providerId = filterProvider.value;
        }
        if (filterUser) {
            filters.userId = filterUser.value;
        }
        if (filterStatus) {
            filters.statusId = filterStatus.value;
        }
        if (filterBrachToBranch) {
            filters.branchToBranch = filterBrachToBranch.value === "true" ? true : null;
        }

        try {
            dispatch(fetchDataRequest());

            if (storeOptions.length === 0) {
                const responseB = await Catalogs.getOptions(token, Catalogs.Name.Branches);
                dispatch(setStoreOptions(responseB.data.data));
            }
            if (providerOptions.length === 0) {
                const responseP = await Catalogs.getOptions(token, Catalogs.Name.Providers);
                dispatch(setProviderOptions(responseP.data.data));
            }
            if (userList.length === 0) {
                const responseU = await Users.getList(token, filters);
                dispatch(setUserList(responseU.data.data));
            }
            /*if (statusOptions.length === 0) {
                const responseS = await Catalogs.getOptions(token, Catalogs.Name.Statuses);
                dispatch(setStatusOptions(responseS.data.data));
            }*/
            const response = await PurchaseOrders.getList(token, filters);
            //console.log(response)
            dispatch(setPurchaseList(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onChangePurchase = async (orderId, orderStatus) => {
        try {
            let data = PurchaseOrders.requestData;
            data = {};
            data.status = orderStatus.id;
            data.id = orderId;
            data.transferCode = generarCodigo();
            dispatch(fetchDataRequest());

            const response = await PurchaseOrders.putChangeStatus(token, data);
            //console.log(response)
            dispatch(setShowRemoteResponseToast(true));
            dispatch(setTransferList([]));
            dispatch(fetchDataSuccess(response.data.success));

            await onLoadPurchaseOrders();
        }
        catch (err) {
            handleException(err);
        }
    }

    const onSaveNotes = async () => {
        try {
            let data = PurchaseOrders.requestData;
            data = {};
            data.comment = JSON.stringify(cleanNotes(notes));
            data.id = currentItem.id;
            data.idStatus = currentItem.status?.id;
            dispatch(fetchDataRequest());

            const response = await PurchaseOrders.putSingle(token, data);
            //console.log(response)
            dispatch(setShowRemoteResponseToast(true));
            dispatch(setNotes(null));
            dispatch(fetchDataSuccess(response.data.success));

            await onLoadPurchaseOrders();
        }
        catch (err) {
            handleException(err);
        }
    }

    const onNewPurchase = () => {
        setCurrentItem(null);
        setNewPurchase(true);
        setShowPurchaseModal(true);
    }

    const onNewTransfer = () => {
        setCurrentItem(null);
        setNewTransfer(true);
        setShowTransferModal(true);
    }

    const onEditItem = (record) => {
        let notes = record.comment;
        if (!notes) {
            notes = "[]";
        }
        dispatch(setNotes(JSON.parse(notes)))

        setCurrentItem(record);
        if (record.provider?.id) {
            setNewPurchase(false);
            setShowPurchaseModal(true);
        } else {
            setNewTransfer(false);
            setShowTransferModal(true);
        }
    }

    const onHidePurchaseModal = async (refresh) => {
        setCurrentItem(null);
        setShowPurchaseModal(false);
        if (refresh) {
            await onLoadPurchaseOrders();
        }
    }

    const onHideTransferModal = async (refresh) => {
        setCurrentItem(null);
        setShowTransferModal(false);
        if (refresh) {
            await onLoadPurchaseOrders();
        }
    }

    const onShowNotesModal = (record) => {
        setCurrentItem(record);
        let notes = record.comment;
        if (!notes) {
            notes = "[]";
        }
        dispatch(setNotes(JSON.parse(notes)))
        setShowNotesModal(true);
    }

    const onHideNotesModal = async () => {
        setCurrentItem(null);
        dispatch(setNotes(null));
        setShowNotesModal(false);
    }

    const onShowNotesEditModal = (record) => {
        setCurrentItem(record);
        let notes = record.comment;
        if (!notes) {
            notes = "[]";
        }
        dispatch(setNotes(JSON.parse(notes)))
        setShowNotesEditModal(true);
    }

    const onHideNotesEditModal = async (notesChanged) => {
        if (notesChanged) {
            await onSaveNotes();
        }
        setCurrentItem(null);
        dispatch(setNotes(null));
        setShowNotesEditModal(false);
    }

    const showConfirmAlert = async (record, status) => {
        setCurrentItem(record);

        let title = "";
        let html = "";
        let confirmButtonText = "";
        let confirmButtonColor = null;
        let icon = "";
        switch (status.value) {
            case Statuses.Values.Requested.value:
                title = "¿<strong>Solicitar</strong> orden de compra?";
                html = `Al solicitar la orden no necesitará aprobación ni generará un traslado y podrás marcarla como recibida.`;
                icon = "info";
                confirmButtonText = "Solicitar orden";
                confirmButtonColor = "#3fc3ee";
                break;
            case Statuses.Values.Pending.value:
                title = "¿<strong>Enviar</strong> orden de compra?";
                html = `Vamos a enviar la órden de compra a aprobación, asegúrate de revisar las cantidades y productos.`;
                icon = "info";
                confirmButtonText = "Enviar orden";
                confirmButtonColor = "#3fc3ee";
                break;
            case Statuses.Values.Approved.value:
                title = "¿<strong>Aprobar</strong> orden de compra?";
                html = "No podrás realizar cambios una vez que confirmes.";
                icon = "info";
                confirmButtonText = "Aprobar orden";
                confirmButtonColor = "#3fc3ee";
                break;
            case Statuses.Values.Rejected.value:
                title = "¿<strong>Rechazar</strong> orden de compra?";
                html = "No podrás realizar cambios una vez que confirmes.";
                icon = "warning";
                confirmButtonText = "Rechazar orden";
                confirmButtonColor = "#FF9900";
                break;
            case Statuses.Values.Canceled.value:
                title = "¿<strong>No recibir</strong> orden de compra?";
                html = "La orden cambiará a estus <strong>Cancelado</strong>. No podrás realizar cambios una vez que confirmes.";
                icon = "warning";
                confirmButtonText = "No recibir la orden";
                confirmButtonColor = "#FF9900";
                break;
            default:
                title = "¿<strong>Recibir</strong> orden de compra?";
                html = "No podrás realizar cambios una vez que confirmes.";
                icon = "info";
                confirmButtonText = "He recibido la orden";
                confirmButtonColor = "#3fc3ee";
        }

        const result = await MySwal.fire({
            title: title,
            html: html,
            icon: icon,
            showCancelButton: true,
            cancelButtonText: "Regresar",
            confirmButtonText: confirmButtonText,
            confirmButtonColor: confirmButtonColor,
        });

        if (result.isConfirmed) {
            await onChangePurchase(record.id, status);
        } else {
            MySwal.close();
        }
    }

    const toggleFilterVisibility = () => {
        setUserOptions(convertToOptions(userList));
        setIsFilterVisible((prevVisibility) => !prevVisibility);
        onLoadPurchaseOrders();
    };

    const calculateImagePath = (userDetails) => {
        return userDetails?.imagePath ? userDetails?.imagePath : "assets/img/profiles/profile.png";
    }

    const calculateName = (userDetails) => {
        let fullName = `${userDetails.name ? userDetails.name : ''} ${userDetails.surname ? userDetails.surname : ''} ${userDetails.lastname ? userDetails.lastname : ''}`;
        return fullName.trim() ? fullName : userDetails.alias;
    }

    const cleanNotes = () => {
        const cleanedNotes = notes.map((obj) => {
            const newObj = {};
            for (const prop in obj) {
                if (prop !== 'key') {
                    newObj[prop] = obj[prop];
                }
            }
            return newObj;
        });
        return cleanedNotes;
    }

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadPurchaseOrders();
        }
    }, []);

    const columns = [
        {
            title: "Fecha y Hora",
            dataIndex: "registered",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record.registered ? record.registered : new Date().toISOString(), fechaHoraFormat)}</span>
            ),
            sorter: (a, b) => a.registered?.localeCompare(b.registered),
            defaultSortOrder: "descend"
        },
        {
            title: "Código de orden",
            dataIndex: "code",
            align: "center",
            render: (text, record) => (
                <Link
                    to={`${route.purchasedetails}?code=${record.id}`}
                    className="text-orange">
                    {record.code}
                </Link>
            ),
            sorter: (a, b) => a.code?.localeCompare(b.code)
        },
        {
            title: "Proveedor ó Sucursal origen",
            dataIndex: "provider.name",
            render: (text, record) => (
                <span className="text-primary-emphasis fw-semibold">{record.provider?.name ? record.provider?.name : record.originBranch?.name}</span>
            ),
            sorter: (a, b) => a.provider?.name?.localeCompare(b.provider?.name)
        },
        {
            title: "Sucursal Destino",
            dataIndex: "destinyBranch.name",
            render: (text, record) => (
                <span className="text-secondary fw-semibold">{record.destinyBranch?.name}</span>
            ),
            sorter: (a, b) => a.destinyBranch?.name.localeCompare(b.destinyBranch?.name)
        },
        {
            title: "Solicitante",
            dataIndex: "userId",
            align: "center",
            render: (text, record) => (
                <span className="productimgname">
                    <Link
                        key={record.id}
                        to={`${route.profile}?code=${record.user?.id}`}
                        className="product-img stock-img"
                    >
                        <ImageWithBasePath alt="avatar" src={calculateImagePath(record.user)} />
                    </Link>
                    <Link to={`${route.profile}?code=${record.user?.id}`} className="text-primary fw-semibold">{calculateName(record.user)}</Link>
                </span>
            ),
            sorter: (a, b) => a.userId - b.userId
        },
        {
            title: "Total",
            dataIndex: "total",
            align: "right",
            render: (text, record) => (
                <span className="text-info fw-bold">{formatearMoneda(record.total)}</span>
            ),
            sorter: (a, b) => a.total - b.total
        },
        {
            title: "Estatus",
            dataIndex: "status.name",
            align: "center",
            render: (text, record) => (
                <>
                    {record.status?.value === "Registrado" && (
                        <span className="badge badges-info">{record.status?.value}</span>
                    )}
                    {record.status?.value === "Actualizado" && (
                        <span className="badge badges-dark">{record.status?.value}</span>
                    )}
                    {record.status?.value === "Aprobado" && (
                        <span className="badge badges-success">{record.status?.value}</span>
                    )}
                    {record.status?.value === "Rechazado" && (
                        <span className="badge badges-danger">{record.status?.value}</span>
                    )}
                    {record.status?.value === "Solicitado" && (
                        <span className="badge badges-purple">{record.status?.value}</span>
                    )}
                    {record.status?.value === "Cancelado" && (
                        <span className="badge badges-danger">{record.status?.value}</span>
                    )}
                    {record.status?.value === "Pendiente" && (
                        <span className="badge badges-warning">{record.status?.value}</span>
                    )}
                    {record.status?.value === "Recibido" && (
                        <span className="badge badges-secondary">{record.status?.value}</span>
                    )}
                </>
            ),
            sorter: (a, b) => a.status?.name?.localeCompare(b.status?.name)
        },
        {
            title: "Notas",
            align: "center",
            render: (text, record) => (
                record.comment
                    ? <a className="text-blue fw-semibold" onClick={() => onShowNotesModal(record)}>Ver Notas</a>
                    : <span>Ninguna</span>

            ),
        },
        {
            title: "Acción",
            align: "center",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link
                            className="me-2 p-2"
                            to={`${route.purchasedetails}?code=${record.id}`}
                        >
                            <Eye />
                        </Link>
                        {(record.status?.name === "Registrado" || record.status?.name === "Actualizado")
                            &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Editar</Tooltip>}
                            >
                                <a className="confirm-text me-2 p-2" onClick={() => { onEditItem(record) }}>
                                    <Edit className="feather-edit" />
                                </a>
                            </OverlayTrigger>

                        }
                        {(record.status?.name === "Pendiente"
                            || record.status?.name === "Aprobado"
                            || record.status?.name === "Actualizado"
                            || record.status?.name === "Solicitado")
                            &&
                            verifyPermission(rolePermissions, Modules.OrdenesCompra, Functions.Modificar) &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Comentar</Tooltip>}
                            >
                                <a className="confirm-text me-2 p-2" onClick={() => { onShowNotesEditModal(record) }}>
                                    <MessageSquare className="feather-truck" />
                                </a>
                            </OverlayTrigger>
                        }
                        {(record.status?.name === "Registrado" || record.status?.name === "Actualizado")
                            &&
                            verifyPermission(rolePermissions, Modules.OrdenesCompra, Functions.Modificar) &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Solicitar</Tooltip>}
                            >
                                <a
                                    className="confirm-text me-2 p-2"
                                    onClick={() => showConfirmAlert(record, Statuses.Values.Requested)}
                                >
                                    <CheckSquare className="feather-check-circle" />
                                </a>
                            </OverlayTrigger>
                        }
                        {(record.status?.name === "Registrado" || record.status?.name === "Actualizado")
                            &&
                            verifyPermission(rolePermissions, Modules.OrdenesCompra, Functions.Modificar) &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Enviar</Tooltip>}
                            >
                                <a
                                    className="confirm-text me-2 p-2"
                                    onClick={() => showConfirmAlert(record, Statuses.Values.Pending)}
                                >
                                    <Share className="feather-send" />
                                </a>
                            </OverlayTrigger>
                        }
                        {(record.status?.name === "Pendiente")
                            &&
                            verifyPermission(rolePermissions, Modules.OrdenesCompra, Functions.Aprobar) &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Aprobar</Tooltip>}
                            >
                                <a
                                    className="confirm-text me-2 p-2"
                                    onClick={() => onEditItem(record)}
                                >
                                    <CheckCircle className="feather-check-circle" />
                                </a>
                            </OverlayTrigger>
                        }
                        {(record.status?.name === "Pendiente")
                            &&
                            verifyPermission(rolePermissions, Modules.OrdenesCompra, Functions.Aprobar) &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Rechazar</Tooltip>}
                            >
                                <a
                                    className="confirm-text me-2"
                                    onClick={() => showConfirmAlert(record, Statuses.Values.Rejected)} >
                                    <XCircle className="feather-x-circle" />
                                </a>
                            </OverlayTrigger>
                        }
                        {(record.status?.name === "Aprobado" || record.status?.name === "Solicitado")
                            &&
                            verifyPermission(rolePermissions, Modules.OrdenesCompra, Functions.Modificar) &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Recibir</Tooltip>}
                            >
                                <a
                                    className="confirm-text me-2 p-2"
                                    onClick={() => onEditItem(record)} >
                                    <Download className="feather-check-circle" />
                                </a>
                            </OverlayTrigger>
                        }
                        {(record.status?.name === "Aprobado" || record.status?.name === "Solicitado")
                            &&
                            verifyPermission(rolePermissions, Modules.OrdenesCompra, Functions.Modificar) &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">No Recibir</Tooltip>}
                            >
                                <a
                                    className="confirm-text p-2"
                                    onClick={() => showConfirmAlert(record, Statuses.Values.Canceled)} >
                                    <X className="feather-x-circle" />
                                </a>
                            </OverlayTrigger>
                        }
                    </div>
                </div>
            )
        },
    ];

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el código de la orden de compra
        </Tooltip>
    );
    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Órdenes de Compra</h4>
                            <h6>Puedes gestionar la información de las ordenes de compra registradas en el sistema.</h6>
                        </div>
                    </div>
                    <ToolsHead
                        showExportData={verifyPermission(rolePermissions, Modules.OrdenesCompra, Functions.Exportar)}
                        showExcel={true}
                        onReload={onLoadPurchaseOrders}
                    />
                    {verifyPermission(rolePermissions, Modules.OrdenesCompra, Functions.Registrar) &&
                        <div className="page-btn">
                            <a
                                className="btn btn-added"
                                onClick={() => onNewPurchase()}
                            >
                                <ShoppingBag className="me-2 iconsize" />
                                Nueva orden de Compra
                            </a>
                        </div>
                    }
                    {verifyPermission(rolePermissions, Modules.OrdenesCompra, Functions.Transferir) &&
                        <div className="page-btn">
                            <a
                                className="btn btn-added"
                                onClick={() => onNewTransfer()}
                            >
                                <Shuffle className="me-2 iconsize" />
                                Transferir entre sucursales
                            </a>
                        </div>
                    }
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeName(e)}
                                        onBlur={onLoadPurchaseOrders}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                            <div className="search-path">
                                <div
                                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
                                    id="filter_search"
                                    onClick={toggleFilterVisibility}
                                >
                                    <Filter className="filter-icon"
                                    />
                                    <span>
                                        <X className="filter-icon" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* /Filter */}
                        <div
                            className={`card${isFilterVisible ? " visible" : ""}`}
                            id="filter_inputs"
                            style={{ display: isFilterVisible ? "block" : "none" }}
                        >
                            <div className="card-body pb-0">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={providerOptions}
                                            onChange={(value) => setFilterProvider(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            isClearable
                                            placeholder="Elige un proveedor"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={storeOptions}
                                            placeholder="Elige una sucursal de destino"
                                            onChange={(value) => setBranchDestination(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                            isClearable
                                        //isMulti
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={userOptions}
                                            onChange={(value) => setFilterUser(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            isClearable
                                            placeholder="Elige un empleado"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={statusOptions}
                                            onChange={(value) => setFilterStatus(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            isClearable
                                            placeholder="Elige un estatus"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={[{ label: "No", value: "false" }, { label: "Si", value: "true" }]}
                                            onChange={(value) => setFilterBrachToBranch(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            placeholder="¿Solo tranferencias?"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <button
                                            className="btn btn-outline-primary w-100"
                                            onClick={() => onLoadPurchaseOrders()}
                                            disabled={loading}
                                        >
                                            <span data-feather="check" className="feather-check" />Aplicar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Filter */}
                        <div className="table-responsive">
                            <Table
                                className="table"
                                rowKey={(record) => record?.id}
                                columns={columns}
                                loading={loading}
                                dataSource={dataSource}
                                size="small"
                                pagination={
                                    {
                                        position: ["topRight"],
                                        total: dataSource.length,
                                        showTotal: (total) => `Total: ${total}`,
                                        showSizeChanger: true
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            {newPurchase
                ? showPurchaseModal
                    ? <AddPurchases id="add-purchase" show={showPurchaseModal} onHide={onHidePurchaseModal} isNew={true} />
                    : null
                : showPurchaseModal
                    ? <AddPurchases id="edit-purchase" show={showPurchaseModal} onHide={onHidePurchaseModal} currentId={currentItem.id} />
                    : null
            }
            {newTransfer
                ? showTransferModal
                    ? <AddPurchases id="add-transfer" show={showTransferModal} isTransfer={true} onHide={onHideTransferModal} isNew={true} />
                    : null
                : showTransferModal
                    ? <AddPurchases id="edit-transfer" show={showTransferModal} isTransfer={true} onHide={onHideTransferModal} currentId={currentItem.id} />
                    : null
            }
            {showNotesModal
                ? <NotesModal id="notes" show={showNotesModal} onHide={onHideNotesModal} />
                : null
            }
            {showNotesEditModal
                ? <NotesEditModal id="notes" show={showNotesEditModal} onHide={onHideNotesEditModal} />
                : null
            }
        </div>
    );
}

export default PurchasesList
