import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { BlobServiceClient } from "@azure/storage-blob";
import { useDispatch, useSelector } from "react-redux";
import { Upload } from "antd";
import { Spinner } from "react-bootstrap";
import { Edit3 } from "react-feather";
import { showInternalErrorAlert } from "../customAlert";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from "../../../core/redux/action";
import { obtenerExtensionDeArchivo } from "../../../core/utils";
import { Images } from "../../../services/internal/images";

const AvatarUpload = ({ prefix, imageGrouperId, userId, avatarPath, onImageUploaded, showUploadButton = true }) => {
    const [status, setStatus] = useState();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const [imageUrl, setImageUrl] = useState();
    const dispatch = useDispatch();

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const uploadFiles = async (file) => {
        try {
            setStatus("");
            if (!validateFileType(file)) {
                return;
            }
            if (!validateFileSize(file)) {
                return;
            }

            dispatch(fetchDataRequest());

            const blobSasUrl = await getSasToken();
            if (!blobSasUrl) {
                setStatus("Encontramos un problema al cargar tu imágen, por favor intenta más tarde.");
                dispatch(fetchDataSuccess(false));
                return;
            }
            const blobServiceClient = new BlobServiceClient(blobSasUrl);
            const containerClient = blobServiceClient.getContainerClient(`${imageGrouperId}`);

            const blobName = `${prefix}_${userId}.${obtenerExtensionDeArchivo(file.name)}`
            const blockBlobClient = await containerClient.getBlockBlobClient(blobName);
            await blockBlobClient.uploadData(file);

            const blobUrl = await containerClient.getBlockBlobClient(blobName).url;// + `&t=${new Date().getTime()}`;
            const url = new URL(blobUrl);
            dispatch(fetchDataSuccess());

            if (onImageUploaded) {
                onImageUploaded(url.origin + url.pathname);
            } else {
                setImageUrl(url.origin + url.pathname);
            }
        }
        catch (err) {
            setStatus(err.message);
            dispatch(fetchDataFailure(err));
            showInternalErrorAlert(true, err.code);
        }
    }

    const getSasToken = async () => {
        try {
            const response = await Images.getGenerateToken(token, "avatares");
            return response.data.data;
        }
        catch (err) {
            handleException(err);
        }
    }

    const validateFileType = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setStatus('Solo se permiten archivos de tipo imagen JPG/PNG.');
        }
        return isJpgOrPng;
    }

    const validateFileSize = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setStatus('El tamaño de la imagen debe ser menor a 2MB.');
        }
        return isLt2M;
    }

    const uploadButton = (
        <div className="profileupload">
            <a className="text-white">
                {loading ? <Spinner size="sm" /> : <Edit3 />}
            </a>
        </div>
    );


    useEffect(() => {
        setImageUrl(avatarPath);
    }, [avatarPath]);

    return (
        <div className="profile-top">
            <div className="profile-content">
                <Upload
                    accept="image/*"
                    listType="picture-circle"
                    rootClassName="profile-contentimg"
                    showUploadList={false}
                    onChange={() => setStatus("")}
                    customRequest={({ file }) => uploadFiles(file)}
                    disabled={loading || showUploadButton === false}
                    style={{ width: '130px', height: '130px' }}
                >
                    <img
                        src={imageUrl + `?t=${new Date().getTime()}`}
                        style={{ maxWidth: '110px', height: '110px' }} alt="avatar"
                    />
                    {showUploadButton && uploadButton}
                </Upload>
                <span className={status ? "alert alert-warning m-2" : ""} style={{ maxWidth: '160px' }}>{status}</span>
            </div>
        </div>
    );
};

AvatarUpload.propTypes = {
    prefix: PropTypes.string.isRequired,
    imageGrouperId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    avatarPath: PropTypes.string,
    onImageUploaded: PropTypes.func,
    showUploadButton: PropTypes.bool
};

export default AvatarUpload;
