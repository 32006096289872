import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { ArrowLeft, DollarSign, Plus, Trash2 } from "react-feather";
import { DragSortTable } from "@ant-design/pro-components";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Checkbox } from "antd";
import AddIngredient from "../../../core/modals/recipes/addingredient";
import FormCreatedBy from "../../components/createdByForm";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCategoryOptions, setPackageList, setShowRemoteResponseToast } from "../../../core/redux/action";
import { showInternalErrorAlert } from "../../components/customAlert";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import ImageUploader from "../../components/fileUpload/ImageUpload";
import variables from "../../../style/scss/utils/_variables.scss";
import { Package } from "../../../services/internal/package";
import { useForm } from "react-hook-form";
import { formatearMoneda, generarCodigo, noOptionsText, priceRegex, validateQuantity, validateRegexQty } from "../../../core/utils";
import ReactSelect from "react-select";
import { Catalogs } from "../../../services/internal/catalogs";
import { Functions, Modules, verifyPermission } from "../../../Router/authorization";

const PackageDetail = () => {

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setValue, register, handleSubmit, formState: { errors } } = useForm();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const categoryOptions = useSelector((state) => state.categoryOptions);
    const [productsDataSource, setProductsDataSource] = useState([]);
    const [variantsDataSource, setVariantsDataSource] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [imagesPath, setImagesPath] = useState([]);
    const [currentCategoryOptions, setCurrentCategoryOptions] = useState([]);

    const [searchParams] = useSearchParams();
    const paramPackageId = searchParams.get('code');
    const route = all_routes

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadCategories = async () => {
        try {
            dispatch(fetchDataRequest());
            const response = await Catalogs.getOptions(token, Catalogs.Name.Categories);
            dispatch(setCategoryOptions(response.data.data));
            setCurrentCategoryOptions(response.data.data);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadPackage = async () => {
        try {
            dispatch(fetchDataRequest());

            let catOptions = [];
            if (categoryOptions) {
                const response = await Catalogs.getOptions(token, Catalogs.Name.Categories);
                catOptions = response.data.data;
                dispatch(setCategoryOptions(response.data.data));
                setCurrentCategoryOptions(response.data.data);
            } else {
                catOptions = categoryOptions;
            }

            const response = await Package.getSingle(token, paramPackageId);
            if (response.data.data.products) {
                setProductsDataSource(convertProducts(response.data.data.products));
            }
            setValue("name", response.data.data.name);
            setValue("unitPrice", formatearMoneda(response.data.data.unitPrice, false));
            setValue("description", response.data.data.description);
            if (response.data.data.imagePath) {
                setImagesPath([response.data.data.imagePath]);
            }
            if (response.data.data.categories) {
                setVariantsDataSource(convertCategoriesToDataSource(response.data.data.categories, catOptions));
            }
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const convertCategoriesToDataSource = (categories, categoryOptions) => {
        let dsCat = [];
        categoryOptions.map((c) => {
            const category = categories.find((c2) => c2.category?.id === c.value);
            if (category) {
                dsCat.push({
                    key: generarCodigo(),
                    category: c,
                    categoryId: c.value,
                    maxQuantity: category.maxQuantity,
                    minQuantity: category.minQuantity,
                    subtotal: 1
                });
            }
        });
        return dsCat;
    }

    const onSaveItem = async (formData) => {
        try {
            let data = Package.requestData;
            data = {};
            data.id = paramPackageId ? Number(paramPackageId) : null;
            if (isNaN(data.id)) {
                return;
            }
            data.name = formData.name;
            data.description = formData.description;
            data.unitPrice = formData.unitPrice;
            data.products = productsDataSource;
            data.categories = variantsDataSource;
            if (imagesPath?.length > 0) {
                data.imagePath = imagesPath[0];
            } else {
                data.imagePath = null;
            }
            dispatch(fetchDataRequest());

            if (paramPackageId) {
                const response = await Package.putSingle(token, data);
                dispatch(fetchDataSuccess(response.data.success));
            } else {
                const response = await Package.postSingle(token, data);
                dispatch(fetchDataSuccess(response.data.success));
            }
            dispatch(setPackageList([]));
            dispatch(setShowRemoteResponseToast(true));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onDeleteItem = async () => {
        try {
            dispatch(fetchDataRequest());
            const response = await Package.deleteSingle(token, paramPackageId);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setPackageList([]));
            dispatch(setShowRemoteResponseToast(true));
            navigate(route.packagegrid);
        }
        catch (err) {
            handleException(err);
        }
    }

    const showDeleteItemAlert = async (text) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onDeleteItem();
        } else {
            MySwal.close();
        }
    };

    const convertProducts = (dataSource) => {
        let products = [];
        dataSource.map((i) => {
            products.push(
                {
                    productId: i.product?.id,
                    quantity: i.quantity,
                    subtotal: i.quantity * i.product?.salePrice,
                    inventoried: i.product?.inventoried,
                    key: generarCodigo(),
                    unit: i.product?.unmed?.abbreviation,
                    name: i.product?.name,
                    salePrice: i.product?.salePrice
                }
            );
        });
        return products;
    }

    const handleIngredientDragSortEnd = (
        beforeIndex,
        afterIndex,
        dataSource
    ) => {
        setProductsDataSource(dataSource);
    };

    const handleVariantDragSortEnd = (
        beforeIndex,
        afterIndex,
        dataSource
    ) => {
        setVariantsDataSource(dataSource);
    };

    const onAddProduct = (product) => {
        if (productsDataSource.find((f) => f.productId === product?.id)) {
            dispatch(fetchDataFailure({ message: "Ya tienes este producto en la lista." }));
            dispatch(setShowRemoteResponseToast(true));
            setShowAddModal(false);
            return;
        }

        const newProduct = {
            productId: product?.id,
            quantity: product.quantity,
            subtotal: product.quantity * product?.salePrice,
            inventoried: product?.inventoried,
            key: generarCodigo(),
            unit: product?.unmed?.abbreviation,
            name: product?.name,
            salePrice: product?.salePrice,
        };
        setShowAddModal(false);
        setProductsDataSource(prevDataSource => [...prevDataSource, newProduct]);
    };

    const onChangeProductQuantity = (e, record) => {
        e?.preventDefault();
        const newQuantity = validateRegexQty(e.target.value);
        record.quantity = newQuantity;
        record.subtotal = newQuantity * record.salePrice;

        setProductsDataSource(prevDataSource => [...prevDataSource.filter(item => item?.key !== record?.key), record]);
    };

    const onDeleteProduct = (record) => {
        setProductsDataSource(prevDataSource => [...prevDataSource.filter(f => f?.key !== record?.key)]);
    };

    const onAddVariant = (e) => {
        e?.preventDefault();

        const newVariant = {
            key: generarCodigo(),
            category: null,
            categoryId: null,
            maxQuantity: 1,
            minQuantity: 1,
            subtotal: 1
        };

        setVariantsDataSource(prevDataSource => [...prevDataSource, newVariant]);
    }

    const onChangeCategory = (option, record) => {
        record.category = option;
        record.categoryId = option?.value;

        const categoryIds = variantsDataSource
            .map(v => (
                v.categoryId
            ));

        setCurrentCategoryOptions(categoryOptions.filter((f) => !categoryIds.includes(f.value)));
        setVariantsDataSource(prevDataSource => [...prevDataSource.filter(item => item?.key !== record?.key), record]);

        return option;
    };

    const onChangeVariantQtyMax = (e, record) => {
        e?.preventDefault();
        const newQuantity = validateQuantity(e.target.value);
        record.maxQuantity = newQuantity;

        setVariantsDataSource(prevDataSource => [...prevDataSource.filter(item => item?.key !== record?.key), record]);
    };

    const onChangeVariantQtyMin = (e, record) => {
        e?.preventDefault();
        const newQuantity = validateQuantity(e.target.value);
        record.minQuantity = newQuantity;
        setVariantsDataSource(prevDataSource => [...prevDataSource.filter(item => item?.key !== record?.key), record]);
    };

    const onDeleteVariant = (record) => {
        const categoryIds = [variantsDataSource
            .filter((f) => f.categoryId !== record.categoryId)
            .map(v => (
                v.categoryId
            ))];

        setCurrentCategoryOptions(categoryOptions.filter((f) => !categoryIds.includes(f.value)));
        setVariantsDataSource(prevDataSource => [...prevDataSource.filter(f => f?.key !== record?.key)]);
    };

    const onImagesChanged = (newImages) => {
        console.log(newImages);
        setImagesPath(newImages);
    }

    const onShowAddModal = (e) => {
        e?.preventDefault();
        setShowAddModal(true);
    }

    const isValid = () => {
        if (productsDataSource.length <= 0) {
            return false;
        }

        if (productsDataSource.length > 0 && variantsDataSource.length === 0)
            return true;

        return variantsDataSource.length > 0
            && variantsDataSource.filter((f) => !f.categoryId).length === 0;
    }

    useEffect(() => {
        if (paramPackageId) {
            onLoadPackage();
        } else {
            onLoadCategories();
        }
    }, [paramPackageId]);

    const shortColumnSize = 30;
    const minColumnSize = 130;
    const middleColumnSize = 160;

    const columns = [
        {
            dataIndex: 'sort',
            width: shortColumnSize,
            className: 'drag-visible',
        },
        {
            title: "Afecta existencias",
            dataIndex: "qty",
            align: "center",
            width: middleColumnSize,
            render: (text, record) => (
                <Checkbox checked={record?.inventoried} disabled />
            ),
        },
        {
            title: "Cantidad",
            dataIndex: "quantity",
            align: "center",
            width: middleColumnSize,
            render: (text, record) => (
                <input
                    type="text"
                    className="form-control text-center"
                    defaultValue={text}
                    onBlur={(e) => onChangeProductQuantity(e, record)}
                    maxLength={14}
                    disabled={loading}
                />
            ),
        },
        {
            title: "Unidad",
            dataIndex: "unit",
            align: "center",
            width: middleColumnSize,
            render: (text, record) => (
                <span>{record?.unit}</span>
            ),
        },
        {
            title: "Producto",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <Link
                    to={route.productdetails + `?code=${record?.productId}`}
                    target="_blank" rel="noopener noreferrer"
                    className="text-primary fw-semibold"
                >
                    {record?.name}
                </Link>
            ),
        },
        {
            title: "Acción",
            dataIndex: "actions",
            align: "center",
            width: minColumnSize,
            key: "actions",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <a className="confirm-text p-2" onClick={() => onDeleteProduct(record)}>
                            <Trash2 className="feather-trash-2" />
                        </a>
                    </div>
                </div>
            ),
        },

    ];

    const columns2 = [
        {
            dataIndex: 'sort',
            width: shortColumnSize,
            className: 'drag-visible',
        },
        {
            title: "Cant. min.",
            dataIndex: "minQuantity",
            align: "center",
            width: middleColumnSize,
            render: (text, record) => (
                <input
                    type="text"
                    className="form-control text-center"
                    value={text}
                    onChange={(e) => onChangeVariantQtyMin(e, record)}
                    maxLength={4}
                    disabled={loading}
                />
            ),
        },
        {
            title: "Cant. max.",
            dataIndex: "maxQuantity",
            align: "center",
            width: middleColumnSize,
            render: (text, record) => (
                <input
                    type="text"
                    className="form-control text-center"
                    value={text}
                    onChange={(e) => onChangeVariantQtyMax(e, record)}
                    maxLength={4}
                    disabled={loading}
                />
            ),
        },
        {
            title: "Categoria",
            dataIndex: "category",
            key: "name",
            render: (text, record) => (
                <ReactSelect
                    options={currentCategoryOptions}
                    placeholder="Elige una categoría"
                    onChange={(value) => onChangeCategory(value, record)}
                    value={record.category}
                    noOptionsMessage={() => noOptionsText}
                    isDisabled={loading}
                    isClearable
                />
            ),
        },
        {
            title: "Acción",
            dataIndex: "actions",
            align: "center",
            width: minColumnSize,
            key: "actions",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <a className="confirm-text p-2" onClick={() => onDeleteVariant(record)}>
                            <Trash2 className="feather-trash-2" />
                        </a>
                    </div>
                </div>
            ),
        },

    ];

    return (
        <div className="page-wrapper">
            <form className="content" onSubmit={handleSubmit((data) => onSaveItem(data))}>
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Detalle del Paquete</h4>
                            <h6>Puedes editar los productos que conforman el paquete.</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                        <Link to={route.packagegrid} className="btn btn-secondary">
                            <ArrowLeft className="me-2" />
                            Lista de Paquetes
                        </Link>
                    </div>
                    {paramPackageId && verifyPermission(rolePermissions, Modules.Paquetes, Functions.Eliminar) &&
                        <div className="page-btn">
                            <a className="btn btn-primary" onClick={() => showDeleteItemAlert("el paquete")}>
                                <Trash2 className="feather-trash-2" />
                            </a>
                        </div>
                    }
                </div>
                {/* /product list */}
                <div className="card table-list-card p-3">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 col-12 mb-3">
                            <div className="row">
                                <div className="col-lg-12 col-sm-12 col-12 mb-4">
                                    <label className="form-label">Nombre<span className="text-danger"> *</span></label>
                                    <input
                                        type="text"
                                        className={!errors.name ? "form-control" : "form-control is-invalid"}
                                        {...register("name",
                                            {
                                                required: "Nombre es requerido.",
                                            }
                                        )}
                                        disabled={loading}
                                        maxLength={300}
                                    />
                                    {
                                        errors.name ?
                                            <div className="invalid-feedback">
                                                {errors.name.message}
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className="col-lg-12 col-sm-12 col-12 mb-3">
                                    <label className="form-label">Precio unitario <span className="text-danger">*</span></label>
                                    <div className="input-group flex-wrap">
                                        <span className="input-group-text"><DollarSign size={15} color={variables.textColor} /></span>
                                        <input
                                            type="text"
                                            className={!errors.unitPrice ? "form-control" : "form-control is-invalid"}
                                            {...register("unitPrice",
                                                {
                                                    required: "Necesitamos el precio unitario.",
                                                    pattern: {
                                                        message: "Precio inválido.",
                                                        value: priceRegex,
                                                    },
                                                    validate: {
                                                        mayorCero: (value) => {
                                                            return Number(value) > 0 || "El precio debe ser mayor a cero.";
                                                        }
                                                    }
                                                }
                                            )}
                                            disabled={loading}
                                        />
                                        {
                                            errors.unitPrice ?
                                                <div className="invalid-feedback">
                                                    {errors.unitPrice.message}
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 d-flex align-items-center">
                            <ImageUploader
                                prefix={"package_image"}
                                containerName="imagenes"
                                imageGrouperId={`packages/${paramPackageId ? paramPackageId : generarCodigo()}`}
                                imagesPath={imagesPath}
                                onImagesChanged={onImagesChanged}
                            />
                        </div>
                        <div className="col-12">
                            <label className="form-label">Descripción</label>
                            <textarea
                                className="form-control"
                                rows={3}
                                maxLength={800}
                                placeholder="Escribe una descripción..."
                                {...register("description",
                                    {
                                        maxLength: 800
                                    }
                                )}
                                disabled={loading}
                            />
                            <p className="mb-3">Máximo 800 caracteres</p>
                        </div>
                    </div>
                    <div className="page-header mb-1">
                        <h4>Productos</h4>
                        <div className="page-btn">
                            <button
                                className="btn btn-added"
                                onClick={(e) => onShowAddModal(e)}
                                disabled={loading}
                            >
                                <Plus className="me-2" />
                                Añadir
                            </button>
                        </div>
                    </div>
                    <div className="table-responsive p-0 mb-4">
                        <DragSortTable
                            className="table"
                            showHeader={productsDataSource.length > 0}
                            toolBarRender={false}
                            search={false}
                            toolbar={false}
                            columns={columns}
                            rowKey="key"
                            pagination={false}
                            dataSource={productsDataSource}
                            dragSortKey="sort"
                            size="small"
                            onDragSortEnd={handleIngredientDragSortEnd}
                        />
                        {
                            productsDataSource.length <= 0 &&
                            <div className="col-lg-6">
                                <div className='invalid-feedback' style={{ display: 'block' }}>Se requiere al menos 1 producto.</div>
                            </div>
                        }
                    </div>
                    <div className="page-header mb-1">
                        <div>
                            <h4>Productos variados</h4>
                            <p>Añade productos para acompañar el paquete.</p>
                        </div>
                        <div className="page-btn">
                            <button
                                className="btn btn-added"
                                onClick={(e) => onAddVariant(e)}
                                disabled={loading}
                            >
                                <Plus className="me-2" />
                                Añadir
                            </button>
                        </div>
                    </div>
                    <div className="p-0 mb-4">
                        <DragSortTable
                            className="table"
                            showHeader={variantsDataSource.length > 0}
                            toolBarRender={false}
                            search={false}
                            toolbar={false}
                            columns={columns2}
                            rowKey="key"
                            pagination={false}
                            dataSource={variantsDataSource}
                            dragSortKey="sort"
                            size="small"
                            onDragSortEnd={handleVariantDragSortEnd}
                        />
                        {
                            !isValid() &&
                            <div className="col-lg-6">
                                <div className='invalid-feedback' style={{ display: 'block' }}>Por favor configura las categorías faltantes.</div>
                            </div>
                        }
                    </div>
                    <FormCreatedBy />
                </div>
                <div className="d-flex justify-content-center mb-4">
                    {
                        loading ?
                            <button disabled={loading} className="btn btn-submit">
                                <Spinner animation="border" role="status" size="sm" />
                            </button>
                            :
                            <input type="submit" disabled={!isValid()} value="Guardar" className="btn btn-primary" />
                    }
                </div>
            </form>
            {
                showAddModal
                    ? <AddIngredient id="add-product" show={showAddModal} onAddItem={onAddProduct} onHide={() => setShowAddModal(false)} />
                    : null
            }
        </div>
    );
};

export default PackageDetail;
