import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { Authentication } from "../../../services/internal/authentication";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, fetchPermissions, setShowRemoteResponseToast, setToken } from "../../../core/redux/action";
import { Spinner } from "react-bootstrap";
import { showInternalErrorAlert } from "../../components/customAlert";
import { useForm } from "react-hook-form";
import { strongPassRegex } from "../../../core/utils";

const Signin = () => {
  const route = all_routes;
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const loading = useSelector(state => state.loading);
  const dispatch = useDispatch();
  const [inputType, setInputType] = useState("password");

  const onShowPassword = () => {
    if (inputType === "text") {
      setInputType("password");
      return;
    }
    setInputType("text");
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLogin = async (formData) => {
    try {
      let data = Authentication.requestData;
      data = {};
      data.user = formData.user.trim();
      data.pass = formData.pass;

      dispatch(fetchDataRequest());
      const response = await Authentication.postLogin(data);
      //console.log(response)
      Authentication.saveToken(response.data.data.token);
      dispatch(setToken(response.data.data.token));
      Authentication.savePermissions(response.data.data.rolePermisions);
      dispatch(fetchPermissions(response.data.data.rolePermisions));
      dispatch(fetchDataSuccess(response.data.success));
      navigate(all_routes.home);
    }
    catch (err) {
      handleException(err);
      clearForm();
    }
  }

  const clearForm = () => {
    reset(formValues => ({
      ...formValues,
      pass: null,
      user: null,
    }));
  }

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper bg-img">
          <div className="login-content">
            <form className="container" onSubmit={handleSubmit((data) => onLogin(data))}>
              <div className="login-logo logo-normal">
                <ImageWithBasePath src="assets/img/logo.png" alt="logo" />
              </div>
              <Link to={route.home} className="login-logo logo-white">
                <ImageWithBasePath src="assets/img/logo-white.png" alt="logo" />
              </Link>
              <div className="login-userheading">
                <h3>Inicio de sesión</h3>
                <h4>
                  Por favor ingresa con tu cuenta <strong>Casa Meper</strong> usando tu alias de usuario y contraseña.
                </h4>
              </div>
              <div className="row">
                <div className="col-12 mb-3">
                  <label className="form-label">Alias de usuario<span className="text-danger"> *</span></label>
                  <div className="pass-group">
                    <input
                      type="text"
                      className={!errors.user ? "form-control" : "form-control is-invalid"}
                      placeholder="Escribe tu alias de usuario"
                      {...register("user", {
                        required: "Requerido.",
                      })}
                      maxLength={300}
                      disabled={loading}
                    />
                    {
                      !errors.user ?
                        <span className="fas toggle-password fa-user" />
                        :
                        <div className="invalid-feedback">
                          {errors.user.message}
                        </div>
                    }
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label">Contraseña<span className="text-danger"> *</span></label>
                  <div className="pass-group">
                    <input
                      type={inputType}
                      className={!errors.pass ? "form-control" : "form-control is-invalid"}
                      placeholder="Escribe tu contraseña"
                      {...register("pass", {
                        required: "Requerido.",
                        pattern: {
                          value: strongPassRegex,
                          message: "Formato inválido"
                        }
                      })}
                      maxLength={300}
                      disabled={loading}
                    />
                    {
                      !errors.pass ?
                        <span className={inputType === "text" ? "fas toggle-password fa-eye-slash" : "fas toggle-password fa-eye"}
                          onClick={onShowPassword} />
                        :
                        <div className="invalid-feedback">
                          {errors.pass.message}
                        </div>
                    }
                  </div>
                </div>
                <div className="col-12 mb-3 text-end">
                  <Link to={route.forgotPassword}>
                    <h5 className="link-primary">¿Olvidé mi contraseña?</h5>
                  </Link>
                </div>
                <div className="col-12">
                  {
                    loading ?
                      <button disabled={loading} className="btn btn-primary w-100">
                        <Spinner animation="border" role="status" size="sm" />
                      </button>
                      :
                      <input className="btn btn-primary w-100" type="submit" value="Ingresar" />
                  }
                </div>
              </div>
              <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                <p>Copyright © 2024 Casa Meper. Todos los derechos reservados.</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
