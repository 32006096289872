import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setConceptOptions, setShowRemoteResponseToast, setStoreOptions } from "../../core/redux/action";
import { showInternalErrorAlert } from "../components/customAlert";
import { Catalogs } from "../../services/internal/catalogs";
import { Functions, Modules, verifyPermission } from "../../Router/authorization";
import { fechaFormat, fechaHoraFormat, formatearFecha, formatearMoneda, generarCodigo, noOptionsText } from "../../core/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Calendar, RefreshCw } from "react-feather";
import { Table } from "antd";
import FilterControl from "../components/filterControl";
import ToolsHead from "../components/toolsHead";
import { Reports } from "../../services/internal/reports";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import variables from "../../style/scss/utils/_variables.scss";
import es from 'date-fns/locale/es';
import { Controller, useForm } from "react-hook-form";
import ReactApexChart from "react-apexcharts";
import alasql from "alasql";

const ExpenseReport = () => {

  registerLocale('es', es)

  const sd = new Date();
  const ed = new Date();
  sd.setHours(0, 0, 0);
  ed.setHours(23, 59, 59);
  const chartHeight = 350;

  const loading = useSelector((state) => state.loading);
  const token = useSelector(state => state.token);
  const rolePermissions = useSelector(state => state.rolePermissions);
  const conceptOptions = useSelector(state => state.conceptOptions);
  const storeOptions = useSelector(state => state.storeOptions);
  const dispatch = useDispatch();
  const { handleSubmit, setValue, formState: { errors }, control } = useForm();
  const [filterStore, setFilterStore] = useState(null);
  const [filterConcept, setFilterConcept] = useState(null);
  const [filterStart, setFilterStart] = useState(sd);
  const [filterEnd, setFilterEnd] = useState(ed);
  const [dataSource, setDataSource] = useState([]);
  const [chartOptions, setChartOptions] = useState(null);
  const [chartSeries, setChartSeries] = useState(null);
  const [chartTitle, setChartTitle] = useState(null);

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadOptions = async () => {
    try {
      dispatch(fetchDataRequest());

      const responseA = await Catalogs.getOptions(token, Catalogs.Name.Branches);
      dispatch(setStoreOptions(responseA.data.data));

      const responseB = await Catalogs.getOptions(token, Catalogs.Name.Concept);
      dispatch(setConceptOptions(responseB.data.data));

      setFilterConcept(responseB.data.data.filter(c => c?.label?.toLowerCase() !== "corte final"));

      dispatch(fetchDataSuccess(responseB.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onLoadReport = async () => {
    try {
      let filters = {};
      if (filterStore) {
        if (Array.isArray(filterStore)) {
          filters.branches = filterStore?.map(o => (o?.value));
        } else {
          filters.branches = filterStore?.value;
        }
      }
      if (filterConcept) {
        if (Array.isArray(filterConcept)) {
          filters.concepts = filterConcept?.map(o => (o?.value));
        } else {
          filters.concepts = filterConcept?.value;
        }
      }
      if (filterStart) {
        const date = new Date(filterStart);
        date.setHours(0, 0, 0);
        filters.dateStart = date.toISOString();
      }
      if (filterEnd) {
        const date = new Date(filterEnd);
        date.setHours(23, 59, 59);
        filters.dateEnd = date.toISOString();
      }

      dispatch(fetchDataRequest());
      const response = await Reports.getCashOutcome(token, filters);
      setDataSource(response.data.data);
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onChangeStart = (field) => {
    const fe = new Date(filterEnd);
    if (fe?.getTime() < field?.getTime()) {
      setFilterEnd(fe?.setDate(field?.getDate() + 1));
    }
    setFilterStart(field);
  }

  const onChangeEnd = (field) => {
    setFilterEnd(field);
  }

  const getChartSeries = () => {
    const names = getDistinct("conceptName");
    const branches = getDistinct("branchName");
    const series = [];
    if (names) {
      names.map((n) => {
        const data = branches.map(b => (getConceptByBranch(b, n) ?? 0));
        series.push({
          name: n,
          data: data
        });
      });

      return series;
    }

    return [];

    /*return [{
      name: 'PRE-CORTE',
      data: [44, 55, 41, 67, 22, 43]
    }, {
      name: 'CORTE FINAL',
      data: [13, 23, 20, 8, 13, 27]
    }, {
      name: 'GAS LP',
      data: [11, 17, 15, 15, 21, 14]
    }, {
      name: 'AGUA',
      data: [21, 7, 25, 13, 22, 8]
    }];*/
  }

  const getChartOptions = () => {
    return {
      chart: {
        type: 'bar',
        height: chartHeight,
        fontFamily: 'Nunito',
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      dataLabels: {
        enabled: true,
        formatter: (val) => {
          return `${formatearMoneda(val)}`;
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          borderRadiusApplication: 'end', // 'around', 'end'
          borderRadiusWhenStacked: 'last', // 'all', 'last'
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900
              },
              formatter: (val) => {
                return `${formatearMoneda(val)}`;
              }
            }
          }
        },
      },
      xaxis: {
        type: 'category',
        categories: getDistinct("branchName"),
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return `${formatearMoneda(val)}`;
          }
        },
        title: {
          text: "Montos"
        }
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${formatearMoneda(val)}`;
          }
        }
      },
    };
  }

  const getDistinct = (property) => {
    if (dataSource?.length === 0) {
      return [];
    }

    try {
      const result = alasql(`COLUMN OF SELECT DISTINCT [${property}] FROM ? ORDER BY [${property}]`, [dataSource]);
      return result;
    }
    catch (e) {
      console.error(e);
      return [];
    }
  }

  const getConceptByBranch = (branchName, conceptName) => {
    if (dataSource?.length === 0) {
      return [];
    }

    try {
      const result = alasql(`VALUE OF SELECT SUM([amount]) AS summatory FROM ? WHERE [branchName]='${branchName}' AND [conceptName]='${conceptName}' GROUP BY [branchName],[conceptName] ORDER BY [branchName],[conceptName]`, [dataSource]);
      return result;
    }
    catch (e) {
      console.error(e);
      return [];
    }
  }

  const getChartTitle = () => {
    if (filterStore?.length > 0) {
      if (Array.isArray(filterStore)) {
        return `Gastos de ${filterStore?.map(s => (s?.label))?.join(', ')}`;
      }

      return `Gastos de ${filterStore?.label}`;
    }

    if (filterConcept?.length > 0) {
      if (Array.isArray(filterConcept)) {
        return `Gastos de ${filterConcept?.map(s => (s?.label))?.join(', ')}`;
      }

      return `Gastos de ${filterConcept?.label} por Producto`
    }

    return "Gastos por Sucursales";
  }

  const onRefresh = async () => {
    setFilterStore([]);
    setFilterConcept(conceptOptions.filter(c => c?.label?.toLowerCase() !== "corte final"));
    setValue("start", sd);
    setValue("end", ed);
    setFilterStart(sd);
    setFilterEnd(ed);
    await onLoadReport();
  }

  useEffect(() => {
    if (storeOptions.length === 0 || conceptOptions.length === 0) {
      onLoadOptions();
    } else {
      setFilterConcept(conceptOptions.filter(c => c?.label?.toLowerCase() !== "corte final"));
    }
    onLoadReport();
  }, []);

  useEffect(() => {
    setChartSeries(getChartSeries());
    setChartOptions(getChartOptions());
    setChartTitle(getChartTitle());
  }, [dataSource]);

  const columns = [
    {
      title: "Fecha",
      defaultSortOrder: "descend",
      align: "center",
      render: (text, record) => (
        <span>{formatearFecha(record?.date, fechaHoraFormat)}</span>
      ),
      sorter: (a, b) => a.date?.localeCompare(b.date),
    },
    {
      title: "Sucursal",
      dataIndex: "branchName",
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{record?.branchName}</span>
      ),
      sorter: (a, b) => a?.branchName?.localeCompare(b.branchName),
    },
    {
      title: "Usuario",
      dataIndex: "userAlias",
      render: (text, record) => (
        <span className="text-primary fw-semibold">{record?.userAlias}</span>
      ),
      sorter: (a, b) => a?.userAlias?.localeCompare(b.userAlias),
    },
    {
      title: "Concepto",
      align: "center",
      dataIndex: "concept",
      render: (text, record) => (
        <span className="badge badges-dark">{record?.conceptName}</span>
      ),
      sorter: (a, b) => a.conceptName?.localeCompare(b.conceptName),
    },
    {
      title: "Monto",
      align: "right",
      render: (text, record) => (
        <span className="text-info fw-semibold">{formatearMoneda(record?.amount)}</span>
      ),
      sorter: (a, b) => a?.amount - b.amount
    },
    {
      title: "Comentario",
      dataIndex: "comment",
      render: (text) => (
        <span>{text}</span>
      ),
    },
  ];

  const renderInfoTooltip = (props, text) => (
    <Tooltip id="info-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Reporte de Gastos</h4>
              <h6>Utiliza los filtros para personalizar los resultados.</h6>
            </div>
          </div>
          <ToolsHead
            showExportData={true}
            showExcel={verifyPermission(rolePermissions, Modules.Existencias, Functions.Exportar)}
            onReload={onRefresh}
          />
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <form className="pe-4 pt-4 ps-4" onSubmit={handleSubmit((data) => onLoadReport(data))}>
              <div className="row">
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <div className="input-group">
                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => renderInfoTooltip(props, "Fecha Inicio")}
                    >
                      <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                    </OverlayTrigger>
                    <Controller
                      control={control}
                      name='start'
                      render={({ field }) => (
                        <DatePicker
                          maxDate={sd}
                          onChange={(date) => { field.onChange(date); onChangeStart(date) }}
                          selected={filterStart}
                          locale="es"
                          dateFormat={fechaFormat}
                          todayButton="Clic aquí para Hoy"
                          className={!errors.start ? "form-control" : "form-control is-invalid"}
                          wrapperClassName={"form-control"}
                          isDisabled={loading}
                          placeholderText="Fecha inicio"
                          showMonthDropdown
                          showYearDropdown
                        />
                      )}
                      rules={{ required: 'Requerido' }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <div className="input-group">
                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => renderInfoTooltip(props, "Fecha Fin")}
                    >
                      <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                    </OverlayTrigger>
                    <Controller
                      control={control}
                      name='end'
                      render={({ field }) => (
                        <DatePicker
                          minDate={filterStart}
                          onChange={(date) => { field.onChange(date); onChangeEnd(date) }}
                          selected={filterEnd}
                          locale="es"
                          dateFormat={fechaFormat}
                          todayButton="Clic aquí para Hoy"
                          className={!errors.end ? "form-control" : "form-control is-invalid"}
                          wrapperClassName={"form-control"}
                          isDisabled={loading}
                          placeholderText="Fecha fin"
                          showMonthDropdown
                          showYearDropdown
                        />
                      )}
                      rules={{ required: 'Requerido' }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <Select
                    options={storeOptions}
                    placeholder="Elige una sucursal"
                    value={filterStore}
                    onChange={(value) => setFilterStore(value)}
                    noOptionsMessage={() => noOptionsText}
                    components={{ Control: FilterControl }}
                    isDisabled={loading}
                    isClearable
                    isMulti
                  />
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <Select
                    options={conceptOptions}
                    onChange={(value) => setFilterConcept(value)}
                    value={filterConcept}
                    noOptionsMessage={() => noOptionsText}
                    isClearable
                    placeholder="Elige un concepto"
                    components={{ Control: FilterControl }}
                    isDisabled={loading}
                    isMulti
                  />
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => onLoadReport()}
                    disabled={loading}
                  >
                    Filtrar <span><RefreshCw size={18} /></span>
                  </button>
                </div>
              </div>
            </form>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table"
                rowKey={() => generarCodigo()}
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                size="small"
                pagination={
                  {
                    position: ["topRight"],
                    total: dataSource?.length,
                    showTotal: (total) => `Total: ${total}`,
                    showSizeChanger: true
                  }
                }
              />
            </div>
            {chartOptions && chartSeries &&
              <div className="chart p-4">
                <h4>{chartTitle}</h4>
                <ReactApexChart
                  options={chartOptions}
                  series={chartSeries}
                  type="bar"
                  height={chartHeight}
                />
              </div>
            }
          </div>
        </div>
        {/* /product list */}
      </div >
    </div >
  );
};

export default ExpenseReport;
