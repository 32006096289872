import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Search, XCircle } from "react-feather";
import { all_routes } from "../../Router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast, setUserDetails } from "../../core/redux/action";
import { Users } from "../../services/internal/users";
import { showInternalErrorAlert } from "../../feature-module/components/customAlert";
import CheckInModal from "../../core/modals/hrm/checkIn";
import { CheckIns } from "../../services/internal/checkIns";

const Header = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const userInfo = useSelector((state) => state.userInfo);
  const userDetails = useSelector((state) => state.userDetails);
  const [toggle, SetToggle] = useState(false);
  const [imagePath, setImagePath] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showCheckInStartModal, setShowCheckInStartModal] = useState(false);
  const [showCheckInEndModal, setShowCheckInEndModal] = useState(false);
  const [layoutColor, setlayoutColor] = useState(
    localStorage.getItem("colorschema")
  );

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadUserDetails = async () => {
    try {
      dispatch(fetchDataRequest());

      const response = await Users.getSingle(token, Number(userInfo.nameid));
      const responseCheckIn = await CheckIns.getSingle(token, Number(userInfo.nameid));
      let ud = response.data.data;
      ud.checkIn = responseCheckIn.data.data;
      dispatch(setUserDetails(ud));
      dispatch(fetchDataSuccess(responseCheckIn.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const isElementVisible = (element) => {
    return element.offsetWidth > 0 || element.offsetHeight > 0;
  };

  const slideDownSubmenu = () => {
    const subdropPlusUl = document.getElementsByClassName("subdrop");
    for (let i = 0; i < subdropPlusUl.length; i++) {
      const submenu = subdropPlusUl[i].nextElementSibling;
      if (submenu && submenu.tagName.toLowerCase() === "ul") {
        submenu.style.display = "block";
      }
    }
  };

  const slideUpSubmenu = () => {
    const subdropPlusUl = document.getElementsByClassName("subdrop");
    for (let i = 0; i < subdropPlusUl.length; i++) {
      const submenu = subdropPlusUl[i].nextElementSibling;
      if (submenu && submenu.tagName.toLowerCase() === "ul") {
        submenu.style.display = "none";
      }
    }
  };

  const DarkThemes = () => {
    localStorage.setItem("colorschema", "dark_mode");
    console.log("check dark mode");
    setlayoutColor("dark_mode");
    document.documentElement.setAttribute("data-layout-mode", "dark_mode");
  };

  const LightThemes = () => {
    localStorage.setItem("colorschema", "light_mode");
    setlayoutColor("light_mode");
    document.documentElement.setAttribute("data-layout-mode", "light_mode");
  };


  useEffect(() => {
    if (!userDetails && userInfo) {
      onLoadUserDetails();
    }
    if (userDetails?.imagePath) {
      setImagePath(userDetails?.imagePath);
    }
  }, [userDetails, userInfo]);

  useEffect(() => {
    const handleMouseover = (e) => {
      e.stopPropagation();

      const body = document.body;
      const toggleBtn = document.getElementById("toggle_btn");

      if (
        body.classList.contains("mini-sidebar") &&
        isElementVisible(toggleBtn)
      ) {
        const target = e.target.closest(".sidebar, .header-left");

        if (target) {
          body.classList.add("expand-menu");
          slideDownSubmenu();
        } else {
          body.classList.remove("expand-menu");
          slideUpSubmenu();
        }

        e?.preventDefault();
      }
    };

    document.addEventListener("mouseover", handleMouseover);

    return () => {
      document.removeEventListener("mouseover", handleMouseover);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);
  useEffect(() => {
    document.documentElement.setAttribute("data-layout-mode", layoutColor);
  }, [layoutColor]);
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
    SetToggle((current) => !current);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const sidebarOverlay = () => {
    document?.querySelector(".main-wrapper")?.classList?.toggle("slide-nav");
    document?.querySelector(".sidebar-overlay")?.classList?.toggle("opened");
    document?.querySelector("html")?.classList?.toggle("menu-opened");
  };

  let pathname = location.pathname;

  const exclusionArray = [
    "/reactjs/template/dream-pos/index-three",
    "/reactjs/template/dream-pos/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  const toggleFullscreen = (elem) => {
    elem = elem || document.documentElement;
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };

  return (
    <>
      <div className="header">
        {/* Logo */}
        <div
          className={`header-left ${toggle ? "" : "active"}`}
          onMouseLeave={expandMenu}
          onMouseOver={expandMenuOpen}
        >
          <Link to={all_routes.home} className="logo logo-normal">
            <ImageWithBasePath src="assets/img/logo.png" alt="img" />
          </Link>
          <Link to={all_routes.home} className="logo logo-white">
            <ImageWithBasePath src="assets/img/logo.png" alt="img" />
          </Link>
          <Link to={all_routes.home} className="logo-small">
            <ImageWithBasePath src="assets/img/logo.png" alt="img" />
          </Link>
          <a
            id="toggle_btn"
            style={{
              display: pathname.includes("tasks")
                ? "none"
                : pathname.includes("compose")
                  ? "none"
                  : "",
            }}
            onClick={handlesidebar}
          >
            <FeatherIcon icon="chevrons-left" className="feather-16" />
          </a>
        </div>
        {/* /Logo */}
        <a
          id="mobile_btn"
          className="mobile_btn"
          onClick={sidebarOverlay}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </a>
        {/* Header Menu */}
        <ul className="nav user-menu">
          {/* Search */}
          <li className="nav-item nav-searchinputs">
            <div className="top-nav-search">
              <a to="#" className="responsive-search">
                <Search />
              </a>
              <form action="#" className="dropdown">
                <div
                  className="searchinputs dropdown-toggle"
                  id="dropdownMenuClickable"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="false"
                >
                  <input type="text" placeholder="Buscar" />
                  <div className="search-addon">
                    <span>
                      <XCircle className="feather-14" />
                    </span>
                  </div>
                </div>
                <div
                  className="dropdown-menu search-dropdown"
                  aria-labelledby="dropdownMenuClickable"
                >
                  <div className="search-info">
                    <h6>
                      <span>
                        <i data-feather="search" className="feather-16" />
                      </span>
                      Búsquedas recientes
                    </h6>
                    <ul className="search-tags">
                      <li>
                        <Link to={all_routes.productlist}>Productos</Link>
                      </li>
                      <li>
                        <Link to={all_routes.saleslist}>Ventas</Link>
                      </li>
                      <li>
                        <Link to={all_routes.purchaselist}>Compras</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </div>
          </li>
          {/* /Search */}
          <li className="nav-item nav-item-box">
            <a onClick={() => userDetails?.checkIn ? setShowCheckInEndModal(true) : setShowCheckInStartModal(true)}>
              <FeatherIcon icon="clock" />
              {!userDetails?.checkIn && <span className="badge rounded-pill">!</span>}
            </a>
          </li>
          <li className="nav-item nav-item-box">
            <a
              id="btnFullscreen"
              onClick={() => toggleFullscreen()}
              className={isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"}
            >
              <FeatherIcon icon={isFullscreen ? "minimize" : "maximize"} />
            </a>
          </li>
          <li className="nav-item nav-item-box">
            <a
              id="btnFullscreen"
              onClick={layoutColor === "dark_mode" ? LightThemes : DarkThemes}
              className={layoutColor === "dark_mode" ? "Exit Fullscreen" : "Go Fullscreen"}
            >
              <FeatherIcon icon={layoutColor === "light_mode" ? "moon" : "sun"} />
            </a>
          </li>
          <li className="nav-item nav-item-box">
            <Link to={all_routes.generalsettings}>
              <FeatherIcon icon="settings" />
            </Link>
          </li>
          <li className="nav-item dropdown has-arrow main-drop">
            <a
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="user-letter">
                  <ImageWithBasePath
                    src={imagePath ? imagePath : "assets/img/profiles/profile.png"}
                    alt="img"
                    className="img-fluid"
                  />
                </span>
                <span className="user-detail">
                  <span className="user-name text-primary">{userDetails?.alias}</span>
                  <span className="user-role text-secondary">{userDetails?.role?.name}</span>
                </span>
              </span>
            </a>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <h6 className="text-primary">{userInfo?.alias}</h6>
                <h5 className="text-secondary">{userInfo?.role?.name}</h5>
                <hr className="m-0" />
                <Link className="dropdown-item" to={all_routes.profile}>
                  <i className="me-2" data-feather="user" /> Mi Perfil
                </Link>
                <hr className="m-0" />
                <Link className="dropdown-item logout pb-0" to={all_routes.signout}>
                  <ImageWithBasePath
                    src="assets/img/icons/log-out.svg"
                    alt="img"
                    className="me-2"
                  />
                  Cerrar sesión
                </Link>
              </div>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <a
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to={all_routes.profile}>
              Mi Perfil
            </Link>
            <Link className="dropdown-item" to={all_routes.generalsettings}>
              Configuración
            </Link>
            <Link className="dropdown-item" to={all_routes.signout}>
              Cerrar Sesión
            </Link>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
      {
        showCheckInStartModal &&
        <CheckInModal id="checkin-start-modal" show={showCheckInStartModal} onHide={() => setShowCheckInStartModal(false)} />
      }
      {
        showCheckInEndModal &&
        <CheckInModal id="checkin-end-modal" show={showCheckInEndModal} onHide={() => setShowCheckInEndModal(false)} isCheckOut={true} />
      }
    </>
  );
};

export default Header;
