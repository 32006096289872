import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { PlusCircle, ArrowLeft } from 'react-feather';
import { Switch, Table } from 'antd';
import ToolsHead from '../components/toolsHead';
import AddCatalog from '../../core/modals/inventory/addcatalog';
import { all_routes } from '../../Router/all_routes';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setRoleOptions, setShowRemoteResponseToast } from '../../core/redux/action';
import { showInternalErrorAlert } from '../components/customAlert';
import { useDispatch, useSelector } from 'react-redux';
import { Catalogs } from '../../services/internal/catalogs';
import { noOptionsText } from '../../core/utils';
import Select from "react-select";
import { Roles } from '../../services/internal/roles';
import { Functions, Modules, verifyPermission } from '../../Router/authorization';

const Permissions = () => {
    const roleOptions = useSelector((state) => state.roleOptions);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const [dataSource, setDataSource] = useState([]);
    const [functionList, setFunctionsList] = useState([]);
    const [roleFunctions, setRoleFunctions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentRole, setCurrentRole] = useState(null);

    const [searchParams] = useSearchParams();
    const paramRoleId = searchParams.get('code');
    const route = all_routes;

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadOptions = async () => {
        try {
            dispatch(fetchDataRequest());
            let rOptions = [];
            if (roleOptions.length === 0) {
                const response = await Catalogs.getOptions(token, Catalogs.Name.Roles);
                dispatch(setRoleOptions(response.data.data));
                rOptions = response.data.data;
            } else {
                rOptions = roleOptions;
            }

            const response = await Roles.getFunctionList(token);
            checkOptions(rOptions, response.data.data);
            setDataSource(response.data.data);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadRoleSettings = async (roleId) => {
        try {
            dispatch(fetchDataRequest());

            const response = await Roles.getRoleSettings(token, roleId);
            setRoleFunctions(response.data.data);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onUpdateRoleSettings = async (moduleId, functionId) => {
        try {
            dispatch(fetchDataRequest());

            let data = {};
            data.roleId = currentRole?.value;
            data.moduleId = moduleId;
            data.functionId = functionId;

            if (data.roleId === 1) {
                return;
            }

            const response = await Roles.putRoleSettings(token, data);

            dispatch(fetchDataSuccess(response.data.success));
            if (response.data.success) {
                await onLoadRoleSettings(currentRole?.value);
            }
        }
        catch (err) {
            handleException(err);
        }
    }

    const checkOptions = async (options, functions) => {
        if (!isNaN(Number(paramRoleId))) {
            let option = options.find((f) => f.value === Number(paramRoleId));
            if (option) {
                setCurrentRole(option);
                setFunctionsList(functions);
                await onLoadRoleSettings(option?.value);
            }
        }
    }

    const onChangeRole = async (value) => {
        setCurrentRole(value);
        if (value) {
            await onLoadRoleSettings(value?.value);
            setFunctionsList(dataSource);
        }
    }

    const onChange = async (value, functionName, record) => {
        let currentModule = functionList.find(m => m.name === record.name);

        if (currentModule) {
            let currentFunction = currentModule.functions.find(f => f.name === functionName);
            if (currentFunction) {
                await onUpdateRoleSettings(currentModule.id, currentFunction.id);
            }
        }
    };

    const calculateValue = (moduleName, functionName) => {
        let currentModule = roleFunctions.find(m => m.name === moduleName);
        if (currentModule) {
            let currentFunction = currentModule.functions.find(f => f.name === functionName);
            return currentFunction ? true : false;
        }
        return false;
    }

    useEffect(() => {
        onLoadOptions();
    }, [paramRoleId]);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Módulo",
            dataIndex: "name",
            render: (text, record) => (
                <span className="text-primary fw-bold">{record.description}</span>
            )
        },
        {
            title: "Permiso",
            children: [
                {
                    title: Functions.Producir,
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === Functions.Producir) ? (
                        <Switch value={calculateValue(record.name, Functions.Producir)} onChange={(value) => onChange(value, Functions.Producir, record)} disabled={loading || currentRole?.value === 1} />
                    ) : null,
                },
                {
                    title: Functions.Transferir,
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === Functions.Transferir) ? (
                        <Switch value={calculateValue(record.name, Functions.Transferir)} onChange={(value) => onChange(value, Functions.Transferir, record)} disabled={loading || currentRole?.value === 1} />
                    ) : null,
                },
                {
                    title: Functions.Aprobar,
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === Functions.Aprobar) ? (
                        <Switch value={calculateValue(record.name, Functions.Aprobar)} onChange={(value) => onChange(value, Functions.Aprobar, record)} disabled={loading || currentRole?.value === 1} />
                    ) : null,
                },
                {
                    title: Functions.Copiar,
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === Functions.Copiar) ? (
                        <Switch value={calculateValue(record.name, Functions.Copiar)} onChange={(value) => onChange(value, Functions.Copiar, record)} disabled={loading || currentRole?.value === 1} />
                    ) : null,
                },
                {
                    title: Functions.Importar,
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === Functions.Importar) ? (
                        <Switch value={calculateValue(record.name, Functions.Importar)} onChange={(value) => onChange(value, Functions.Importar, record)} disabled={loading || currentRole?.value === 1} />
                    ) : null,
                },

                {
                    title: Functions.Exportar,
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === Functions.Exportar) ? (
                        <Switch value={calculateValue(record.name, Functions.Exportar)} onChange={(value) => onChange(value, Functions.Exportar, record)} disabled={loading || currentRole?.value === 1} />
                    ) : null,
                },
                {
                    title: Functions.Registrar,
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === Functions.Registrar) ? (
                        <Switch value={calculateValue(record.name, Functions.Registrar)} onChange={(value) => onChange(value, Functions.Registrar, record)} disabled={loading || currentRole?.value === 1} />
                    ) : null,
                },
                {
                    title: Functions.Modificar,
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === Functions.Modificar) ? (
                        <Switch value={calculateValue(record.name, Functions.Modificar)} onChange={(value) => onChange(value, Functions.Modificar, record)} disabled={loading || currentRole?.value === 1} />
                    ) : null,
                },
                {
                    title: Functions.Eliminar,
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === Functions.Eliminar) ? (
                        <Switch value={calculateValue(record.name, Functions.Eliminar)} onChange={(value) => onChange(value, Functions.Eliminar, record)} disabled={loading || currentRole?.value === 1} />
                    ) : null,
                },
                {
                    title: Functions.Ver,
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === Functions.Ver) ? (
                        <Switch value={calculateValue(record.name, Functions.Ver)} onChange={(value) => onChange(value, Functions.Ver, record)} disabled={loading || currentRole?.value === 1} />
                    ) : null,
                },
            ],
        },

    ];

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Administración de Permisos</h4>
                                <h6>Puedes administrar las funcionalidades permitidas para cada rol registrado en el sistema.</h6>
                            </div>
                        </div>
                        <ToolsHead
                            showExportData={false}
                            onReload={onLoadOptions}
                        />
                        {verifyPermission(rolePermissions, Modules.Roles, Functions.Ver) &&
                            <div className="page-btn">
                                <Link to={route.rolespermission} className="btn btn-added btn-secondary">
                                    <ArrowLeft className="me-2" />
                                    Lista de Roles
                                </Link>
                            </div>
                        }
                        {verifyPermission(rolePermissions, Modules.Roles, Functions.Registrar) &&
                            <div className="page-btn">
                                <a
                                    className="btn btn-added"
                                    onClick={() => setShowModal(true)}
                                >
                                    <PlusCircle className="me-2" />
                                    Nuevo Rol
                                </a>
                            </div>
                        }
                    </div>
                    <div className="card p-3">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 col-12 mb-3">
                                <label className="form-label">Elige un rol para configurar sus permisos<span className="text-danger"> *</span></label>
                                <Select
                                    options={roleOptions}
                                    placeholder="Elige un rol"
                                    value={currentRole}
                                    onChange={onChangeRole}
                                    noOptionsMessage={() => noOptionsText}
                                    isDisabled={loading}
                                    isClearable
                                />
                            </div>
                        </div>
                        {
                            currentRole &&
                            <div className="m-0">
                                <p>Da clic sobre el permiso para activar o desactivar.</p>
                                <div className="table-responsive">
                                    <Table
                                        columns={columns}
                                        rowKey={(record) => (record.id)}
                                        dataSource={functionList}
                                        loading={loading}
                                        size="small"
                                        pagination={false}
                                        bordered
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                showModal && <AddCatalog id="add-role" show={showModal} onHide={() => setShowModal(false)} type='role' isNew={true} />
            }
        </div>
    )
}

export default Permissions
