import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { BlobServiceClient } from "@azure/storage-blob";
import { useDispatch, useSelector } from "react-redux";
import { Upload } from "antd";
import { Spinner } from "react-bootstrap";
import { PlusCircle, X } from "react-feather";
import { showInternalErrorAlert } from "../customAlert";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from "../../../core/redux/action";
import { obtenerExtensionDeArchivo } from "../../../core/utils";
import { Images } from "../../../services/internal/images";
import variables from "../../../style/scss/utils/_variables.scss";

const ImageUploader = ({ prefix, imageGrouperId, containerName, imagesPath = [], onImagesChanged }) => {
    const [status, setStatus] = useState();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const [imagesUrl, setImagesUrl] = useState([]);
    const [imageCount, setImageCount] = useState(0);
    const dispatch = useDispatch();

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const uploadFiles = async (file) => {
        try {
            setStatus("");
            if (!validateFileType(file)) {
                return;
            }
            if (!validateFileSize(file)) {
                return;
            }

            dispatch(fetchDataRequest());

            const blobSasUrl = await getSasToken();
            if (!blobSasUrl) {
                setStatus("Encontramos un problema al cargar tu imágen, por favor intenta más tarde.");
                dispatch(fetchDataSuccess(false));
                return;
            }
            const blobServiceClient = new BlobServiceClient(blobSasUrl);
            const containerClient = blobServiceClient.getContainerClient(`${imageGrouperId}`);

            const blobName = `${prefix}_${imageCount + 1}.${obtenerExtensionDeArchivo(file.name)}`
            const blockBlobClient = await containerClient.getBlockBlobClient(blobName);
            await blockBlobClient.uploadData(file);

            const blobUrl = await containerClient.getBlockBlobClient(blobName).url;// + `&t=${new Date().getTime()}`;
            const url = new URL(blobUrl);

            dispatch(fetchDataSuccess(true));

            if (onImagesChanged) {
                onImagesChanged([...imagesUrl, url.origin + url.pathname]);
            } else {
                setImagesUrl([...imagesUrl, url.origin + url.pathname]);
                setImageCount(imageCount + 1);
            }
        }
        catch (err) {
            setStatus(err.message);
            dispatch(fetchDataFailure(err));
            showInternalErrorAlert(true, err.code);
        }
    }

    const getSasToken = async () => {
        try {
            const response = await Images.getGenerateToken(token, containerName);
            return response.data.data;
        }
        catch (err) {
            handleException(err);
        }
    }

    const validateFileType = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setStatus('Solo se permiten archivos de tipo imagen JPG/PNG.');
        }
        return isJpgOrPng;
    }

    const validateFileSize = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setStatus('El tamaño de la imagen debe ser menor a 2MB.');
        }
        return isLt2M;
    }

    const onRemoveImage = (index) => {
        let newImagesUrl = imagesUrl.filter((_, i) => i !== index);
        if (onImagesChanged) {
            onImagesChanged(newImagesUrl);
        } else {
            setImagesUrl(newImagesUrl);
            setImageCount(newImagesUrl.length);
        }
    }

    const uploadButton = (
        <div className="">
            {loading
                ? <Spinner size="sm" />
                :
                <div className="d-flex flex-column align-items-center">
                    <PlusCircle size={16} color={variables.textColor} />
                    <span className="text-secondary fw-semib">Agregar Imagen</span>
                </div>
            }
        </div>
    );

    /*const calculateSasToken = () => {
        const time = new Date().getTime();
        if (containerName === "productos") {
            return sasToken?.product + `&t=${time}`;
        }

        return sasToken?.category + `&t=${time}`;
    }*/

    useEffect(() => {
        setImagesUrl(imagesPath);
        setImageCount(imagesPath.length);

    }, [imagesPath]);

    return (
        <div className="row">
            <div className="add-choosen">
                <div className="phone-img">
                    <Upload
                        accept="image/*"
                        listType="picture-card"
                        rootClassName=""
                        showUploadList={false}
                        onChange={() => setStatus("")}
                        customRequest={({ file }) => uploadFiles(file)}
                        disabled={loading}
                    >
                        {uploadButton}
                    </Upload>
                </div>
                {
                    imagesUrl?.map((e, i) => (
                        <div key={i + 1} className="phone-img">
                            <img
                                src={e + `?t=${new Date().getTime()}`}
                                alt="image"
                            />
                            <a className={loading ? "d-none" : ""}>
                                <X
                                    className="x-square-add remove-product"
                                    onClick={() => onRemoveImage(i)}
                                />
                            </a>
                        </div>
                    ))
                }
            </div>
            <div className={status ? "row alert alert-warning m-2" : "row"}>{status}</div>
        </div>
    );
};

ImageUploader.propTypes = {
    prefix: PropTypes.string.isRequired,
    imageGrouperId: PropTypes.string.isRequired,
    containerName: PropTypes.string.isRequired,
    imagesPath: PropTypes.array,
    onImagesChanged: PropTypes.func
};

export default ImageUploader;
