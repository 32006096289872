import React, { useEffect, useRef, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Table } from 'antd';
import { convertToOptions, fechaHoraFormat, formatearFecha, formatearMoneda, noOptionsText } from '../../core/utils';
import { Eye, Filter, HelpCircle, Printer, X } from 'react-feather';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCustomerOptions, setPaymentOptions, setSaleList, setShowRemoteResponseToast, setStoreOptions, setUserList } from '../../core/redux/action';
import { Users } from '../../services/internal/users';
import { showInternalErrorAlert } from '../components/customAlert';
import { all_routes } from '../../Router/all_routes';
import ToolsHead from '../components/toolsHead';
import FilterControl from '../components/filterControl';
import { Catalogs } from '../../services/internal/catalogs';
import { Sales } from '../../services/internal/sales';
import Receipt from '../components/receipt';
import { useReactToPrint } from 'react-to-print';
import { Functions, Modules, verifyPermission } from '../../Router/authorization';

const SaleList = () => {
    const dataSource = useSelector((state) => state.saleList);
    const storeOptions = useSelector((state) => state.storeOptions);
    const paymentOptions = useSelector((state) => state.paymentOptions);
    const userList = useSelector((state) => state.userList);
    const customerOptions = useSelector((state) => state.customerOptions);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const [filterCode, setFilterCode] = useState("");
    const [filterBranch, setBranch] = useState("");
    const [filterCustomer, setFilterCustomer] = useState("");
    const [filterUser, setFilterUser] = useState("");
    const [filterPayment, setFilterPayment] = useState("");
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const [currentSale, setCurrentSale] = useState(null);
    const route = all_routes;

    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef });

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el código de la venta
        </Tooltip>
    );

    const onChangeName = (e) => {
        setFilterCode(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadSales = async () => {

        let filters = {};
        if (filterCode) {
            filters.code = filterCode;
        }
        if (filterBranch) {
            filters.branchId = filterBranch.value;
        }
        if (filterCustomer) {
            filters.clientId = filterCustomer.value;
        }
        if (filterUser) {
            filters.saleUserId = filterUser.value;
        }
        if (filterPayment) {
            filters.paymentMethodId = filterPayment.value;
        }

        try {
            dispatch(fetchDataRequest());

            if (storeOptions.length === 0) {
                const responseB = await Catalogs.getOptions(token, Catalogs.Name.Branches);
                dispatch(setStoreOptions(responseB.data.data));
            }
            if (customerOptions.length === 0) {
                const responseP = await Catalogs.getOptions(token, Catalogs.Name.Clients);
                dispatch(setCustomerOptions(responseP.data.data));
            }
            if (userList.length === 0) {
                const responseU = await Users.getList(token, filters);
                dispatch(setUserList(responseU.data.data));
            }
            if (paymentOptions.length === 0) {
                const responseS = await Catalogs.getOptions(token, Catalogs.Name.PaymentMethod);
                dispatch(setPaymentOptions(responseS.data.data));
            }
            const response = await Sales.getList(token, filters);
            //console.log(response)
            dispatch(setSaleList(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const toggleFilterVisibility = () => {
        setUserOptions(convertToOptions(userList));
        setIsFilterVisible((prevVisibility) => !prevVisibility);
        onLoadSales();
    };

    const calculateImagePath = (userDetails) => {
        return userDetails?.imagePath ? userDetails?.imagePath : "assets/img/profiles/profile.png";
    }

    const calculateName = (userDetails) => {
        let fullName = `${userDetails.name ? userDetails.name : ''} ${userDetails.surname ? userDetails.surname : ''} ${userDetails.lastname ? userDetails.lastname : ''}`;
        return fullName.trim() ? fullName : userDetails.alias;
    }

    const onLoadSale = async (saleId) => {
        try {
            setCurrentSale(null);
            dispatch(fetchDataRequest());
            const response = await Sales.getSingle(token, saleId);
            setCurrentSale(response.data.data);
            dispatch(fetchDataSuccess());
        }
        catch (err) {
            handleException(err);
        }
    }

    const renderPrintAction = (saleId) => {
        if (!verifyPermission(rolePermissions, Modules.Ventas, Functions.Exportar)) {
            return;
        }

        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-minus">Imprimir Recibo</Tooltip>}
            >
                <a className="me-2 p-2" onClick={() => onLoadSale(saleId)} >
                    <Printer />
                    <div className="d-none">
                        {currentSale && <Receipt innerRef={contentRef} saleData={currentSale} />}
                    </div>
                </a>
            </OverlayTrigger>
        );
    }

    useEffect(() => {
        if (currentSale) {
            reactToPrintFn();
        }
    }, [currentSale]);

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadSales();
        }
    }, []);

    const columns = [
        {
            title: "Fecha y Hora",
            dataIndex: "registered",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record.registered ? record.registered : new Date().toISOString(), fechaHoraFormat)}</span>
            ),
            sorter: (a, b) => a.registered?.localeCompare(b.registered),
            defaultSortOrder: "descend"
        },
        {
            title: "Código de venta",
            dataIndex: "code",
            align: "center",
            render: (text, record) => (
                <span>
                    <Link to={`${route.saleDetails}?code=${record.id}`} className="text-orange">{record.code}</Link>
                </span>
            ),
            sorter: (a, b) => a.code?.localeCompare(b.code)
        },
        {
            title: "Sucursal",
            dataIndex: "branchId.name",
            render: (text, record) => (
                <span className="text-secondary fw-semibold">{record.branchId?.name}</span>
            ),
            sorter: (a, b) => a.branchId?.name.localeCompare(b.branchId?.name)
        },
        {
            title: "Vendedor",
            dataIndex: "userId",
            align: "center",
            render: (text, record) => (
                <span className="productimgname">
                    <Link key={record.id} to={`${route.profile}?code=${record.user?.id}`} className="product-img stock-img">
                        <ImageWithBasePath alt="avatar" src={calculateImagePath(record.user)} />
                    </Link>
                    <Link to={`${route.profile}?code=${record.user?.id}`} className="text-primary fw-semibold">{calculateName(record.user)}</Link>
                </span>
            ),
            sorter: (a, b) => a.user?.id - b.user?.id
        },
        {
            title: "Cliente",
            dataIndex: "client.name",
            render: (text, record) => (
                <span className="text-primary-emphasis fw-semibold">{record.client?.name ? record.client?.name : record.client?.name}</span>
            ),
            sorter: (a, b) => a.client?.name?.localeCompare(b.client?.name)
        },
        {
            title: "Caja",
            dataIndex: "terminal",
            align: "center",
            render: (text, record) => (
                <span>{record.terminal}</span>
            ),
            sorter: (a, b) => a?.terminal.localeCompare(b?.terminal)
        },

        {
            title: "Total",
            dataIndex: "total",
            align: "right",
            render: (text, record) => (
                <span className="text-info fw-bold">{formatearMoneda(record.total)}</span>
            ),
            sorter: (a, b) => a.total - b.total
        },
        {
            title: "Método de pago",
            dataIndex: "paymentMethod.id",
            align: "center",
            render: (text, record) => (
                <>
                    {record.paymentMethod?.id === 1 && (
                        <span className="badge badges-success">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 2 && (
                        <span className="badge badges-dark">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 3 && (
                        <span className="badge badges-info">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 4 && (
                        <span className="badge badges-orange">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 5 && (
                        <span className="badge badges-primary">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 6 && (
                        <span className="badge badges-black">{record.paymentMethod?.name}</span>
                    )}
                </>
            ),
            sorter: (a, b) => a.paymentMethod?.id - b.paymentMethod?.id
        },
        {
            title: "Corte de caja",
            align: "center",
            render: (text, record) => (
                record.breakSaleId
                    ? <Link className="text-blue fw-semibold" to={route.cashBreakDetails + `?code=${record.breakSaleId}`}>Ir al corte</Link>
                    : <span>Ninguno</span>
            ),
        },
        {
            title: "Acción",
            align: "center",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link className="me-2 p-2" to={`${route.saleDetails}?code=${record.id}`}>
                            <Eye />
                        </Link>
                        {renderPrintAction(record.id)}
                    </div>
                </div>
            )
        },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Lista de Ventas</h4>
                            <h6>Puedes ver la información de las ventas registradas en el sistema.</h6>
                        </div>
                    </div>
                    <ToolsHead
                        showExportData={verifyPermission(rolePermissions, Modules.Ventas, Functions.Exportar)}
                        showExcel={true}
                        onReload={onLoadSales}
                    />
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeName(e)}
                                        onBlur={onLoadSales}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                            <div className="search-path">
                                <div
                                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
                                    id="filter_search"
                                    onClick={toggleFilterVisibility}
                                >
                                    <Filter className="filter-icon"
                                    />
                                    <span>
                                        <X className="filter-icon" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* /Filter */}
                        <div
                            className={`card${isFilterVisible ? " visible" : ""}`}
                            id="filter_inputs"
                            style={{ display: isFilterVisible ? "block" : "none" }}
                        >
                            <div className="card-body pb-0">
                                <div className="row">
                                    <div className="col-lg-3 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={storeOptions}
                                            placeholder="Elige una sucursal"
                                            onChange={(value) => setBranch(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                            isClearable
                                        //isMulti
                                        />
                                    </div>
                                    <div className="col-lg-3 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={userOptions}
                                            onChange={(value) => setFilterUser(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            isClearable
                                            placeholder="Elige un vendedor"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={customerOptions}
                                            onChange={(value) => setFilterCustomer(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            isClearable
                                            placeholder="Elige un cliente"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={paymentOptions}
                                            onChange={(value) => setFilterPayment(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            isClearable
                                            placeholder="Elige un método de pago"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-sm-12 col-12 mb-3">
                                        <button
                                            className="btn btn-outline-primary w-100"
                                            onClick={() => onLoadSales()}
                                            disabled={loading}
                                        >
                                            <span data-feather="check" className="feather-check" />Aplicar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Filter */}
                        <div className="table-responsive">
                            <Table
                                className="table"
                                rowKey={(record) => record?.id}
                                columns={columns}
                                loading={loading}
                                dataSource={dataSource}
                                size="small"
                                pagination={
                                    {
                                        position: ["topRight"],
                                        total: dataSource.length,
                                        showTotal: (total) => `Total: ${total}`,
                                        showSizeChanger: true
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SaleList
