import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Edit, HelpCircle, PlusCircle, Trash2 } from 'react-feather';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import ToolsHead from '../components/toolsHead/index.jsx';
import { Table } from 'antd';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setHolidayList, setShowRemoteResponseToast } from '../../core/redux/action.jsx';
import { Holidays } from '../../services/internal/holidays/index.js';
import { showInternalErrorAlert, showSuccessAlert } from '../components/customAlert/index.jsx';
import { calcularDiasEntreFechas, fechaFormat, formatearFecha } from '../../core/utils.jsx';
import AddHolidays from '../../core/modals/hrm/addholidays.jsx';
import { Functions, Modules, verifyPermission } from '../../Router/authorization.jsx';

const CalendarHolidays = () => {
    const dataSource = useSelector((state) => state.holidayList);
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const [filterName, setFilterName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [newItem, setNewItem] = useState(false);
    const [currentId, setCurrentId] = useState(null);

    const onChangeName = (e) => {
        setFilterName(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadHolidays = async () => {

        let filters = {};
        filters.enabled = true;
        if (filterName) {
            filters.description = filterName;
        }

        try {
            dispatch(fetchDataRequest());
            const response = await Holidays.getList(token, filters);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setHolidayList(response.data.data));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onDeleteItem = async (id) => {
        try {
            dispatch(fetchDataRequest());
            const response = await Holidays.deleteSingle(token, id);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            showSuccessAlert("Hemos eliminado el día festivo.");
        }
        catch (err) {
            handleException(err);
        }
        await onLoadHolidays();
    }


    const showDeleteItemAlert = async (index, text) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onDeleteItem(index.id);
        } else {
            MySwal.close();
        }
    };

    const onNewItem = () => {
        setCurrentId(null);
        setNewItem(true);
        setShowModal(true);
    }

    const onEditItem = (index) => {
        setCurrentId(index.id);
        setNewItem(false);
        setShowModal(true);
    }

    const onHideModal = async (refresh) => {
        setCurrentId(null);
        setShowModal(false);
        if (refresh) {
            await onLoadHolidays();
        }
    }

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadHolidays();
        }
    }, []);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id
        },
        {
            title: "Descripción",
            dataIndex: "description",
            render: (text) => (
                <span className="text-secondary fw-semibold">{text}</span>
            ),
            sorter: (a, b) => a.description?.localeCompare(b.description)
        },
        {
            title: "Fecha de inicio",
            dataIndex: "start",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record.start, fechaFormat)}</span>
            ),
            sorter: (a, b) => a.start?.localeCompare(b.start)
        },
        {
            title: "Fecha fin",
            dataIndex: "end",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record.end, fechaFormat)}</span>
            ),
            sorter: (a, b) => a.end?.localeCompare(b.end)
        },
        {
            title: "Cantidad de días",
            dataIndex: "duration",
            align: "center",
            render: (text, record) => (
                <span className="badge badges-info">
                    {calcularDiasEntreFechas(new Date(record.end), new Date(record.start))}
                </span>
            )
        },
        {
            title: "Acción",
            dataIndex: "actions",
            align: "center",
            key: "actions",
            render: (record, index) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        {verifyPermission(rolePermissions, Modules.DiasFestivos, Functions.Modificar) &&
                            <a className="me-2 p-2" onClick={() => onEditItem(index)}>
                                <Edit className="feather-edit" />
                            </a>
                        }
                        {verifyPermission(rolePermissions, Modules.DiasFestivos, Functions.Eliminar) &&
                            <a className="confirm-text p-2" onClick={() => showDeleteItemAlert(index, "un día festivo")} >
                                <Trash2 className="feather-trash-2" />
                            </a>
                        }
                    </div>
                </div>
            ),
        },
    ];

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el nombre del día festivo
        </Tooltip>
    );

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Días festivos</h4>
                            <h6>Puedes gestionar los días festivos no laborales del año.</h6>
                        </div>
                    </div>
                    <ToolsHead
                        showExportData={verifyPermission(rolePermissions, Modules.DiasFestivos, Functions.Exportar)}
                        showExcel={true}
                        onReload={onLoadHolidays}
                    />
                    {verifyPermission(rolePermissions, Modules.DiasFestivos, Functions.Registrar) &&
                        <div className="page-btn">
                            <a
                                className="btn btn-added"
                                onClick={onNewItem}
                            >
                                <PlusCircle className="me-2" />
                                Nuevo día festivo
                            </a>
                        </div>
                    }
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeName(e)}
                                        onBlur={onLoadHolidays}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table
                                className="table"
                                rowKey={(record) => record?.id}
                                columns={columns}
                                loading={loading}
                                dataSource={dataSource}
                                size="small"
                                pagination={
                                    {
                                        position: ["topRight"],
                                        total: dataSource.length,
                                        showTotal: (total) => `Total: ${total}`,
                                        showSizeChanger: true
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                {/* /product list */}
            </div>
            {newItem
                ?
                showModal
                    ? <AddHolidays id="add-holiday" show={showModal} onHide={onHideModal} isNew={true} />
                    : null
                :
                showModal
                    ? <AddHolidays id="edit-holiday" show={showModal} onHide={onHideModal} currentId={currentId} />
                    : null
            }
        </div>
    )
}

export default CalendarHolidays;