import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ToolsHead from "../../components/toolsHead";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from "../../../core/redux/action";
import { showInternalErrorAlert } from "../../components/customAlert";
import { Table } from "antd";
import { Functions, Modules, verifyPermission } from "../../../Router/authorization";
import DatePicker, { registerLocale } from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { Calendar, RefreshCw } from "react-feather";
import variables from "../../../style/scss/utils/_variables.scss";
import es from 'date-fns/locale/es';
import { fechaFormat, fechaHoraFormat, formatearFecha } from "../../../core/utils";
import { Logs } from "../../../services/internal/logs";

export const EventLog = () => {

    registerLocale('es', es)

    const sd = new Date();
    const ed = new Date();
    sd.setHours(0, 0, 0);
    ed.setHours(23, 59, 59);

    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const { handleSubmit, setValue, formState: { errors }, control } = useForm();
    const [filterStart, setFilterStart] = useState(sd);
    const [filterEnd, setFilterEnd] = useState(ed);
    const [dataSource, setDataSource] = useState([]);

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadEvents = async () => {

        let filters = {};
        if (filterStart) {
            const date = new Date(filterStart);
            date.setHours(0, 0, 0);
            filters.dateStart = date.toISOString();
        }
        if (filterEnd) {
            const date = new Date(filterEnd);
            date.setHours(23, 59, 59);
            filters.dateEnd = date.toISOString();
        }

        try {
            dispatch(fetchDataRequest());
            const response = await Logs.getList(token, filters);
            //console.log(response)
            setDataSource(response.data.data);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onChangeStart = (field) => {
        const fe = new Date(filterEnd);
        if (fe?.getTime() < field?.getTime()) {
            setFilterEnd(fe?.setDate(field?.getDate() + 1));
        }
        setFilterStart(field);
    }

    const onChangeEnd = (field) => {
        setFilterEnd(field);
    }

    const onRefresh = async () => {
        setValue("start", sd);
        setValue("end", sd);
        setFilterStart(sd);
        setFilterEnd(ed);
        await onLoadEvents();
    }


    const renderInfoTooltip = (props, text) => (
        <Tooltip id="info-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadEvents();
        }
    }, []);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: 80,
            align: "center",
            sorter: (a, b) => a.id - b.id
        },
        {
            title: "Fecha",
            align: 'center',
            defaultSortOrder: "descend",
            render: (text, record) => (
                <span>{record.date ? formatearFecha(record.date ?? new Date().toISOString(), fechaHoraFormat) : "Aún no hay datos"}</span>
            ),
            sorter: (a, b) => a.date?.localeCompare(b.date)
        },
        {
            title: "Usuario",
            dataIndex: "userAlias",
            render: (text) => (
                <span className="text-primary fw-semibold">{text}</span>
            ),
            sorter: (a, b) => a.userName?.localeCompare(b.userName)
        },
        {
            title: "Evento",
            dataIndex: "message",
            sorter: (a, b) => a.message?.localeCompare(b.message)
        },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Bitácora de eventos</h4>
                            <h6>Puedes visualizar los eventos del sistema.</h6>
                        </div>
                    </div>
                    <ToolsHead
                        showExportData={true}
                        showExcel={verifyPermission(rolePermissions, Modules.Unidades, Functions.Exportar)}
                        onReload={onRefresh}
                    />
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <form className="pe-4 pt-4 ps-4" onSubmit={handleSubmit((data) => onLoadEvents(data))}>
                            <div className="row">
                                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                                    <div className="input-group">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={(props) => renderInfoTooltip(props, "Fecha Inicio")}
                                        >
                                            <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                                        </OverlayTrigger>
                                        <Controller
                                            control={control}
                                            name='start'
                                            render={({ field }) => (
                                                <DatePicker
                                                    maxDate={sd}
                                                    onChange={(date) => { field.onChange(date); onChangeStart(date) }}
                                                    selected={filterStart}
                                                    locale="es"
                                                    dateFormat={fechaFormat}
                                                    todayButton="Clic aquí para Hoy"
                                                    className={!errors.start ? "form-control" : "form-control is-invalid"}
                                                    wrapperClassName={"form-control"}
                                                    isDisabled={loading}
                                                    placeholderText="Fecha inicio"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                            )}
                                            rules={{ required: 'Requerido' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                                    <div className="input-group">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={(props) => renderInfoTooltip(props, "Fecha Fin")}
                                        >
                                            <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                                        </OverlayTrigger>
                                        <Controller
                                            control={control}
                                            name='end'
                                            render={({ field }) => (
                                                <DatePicker
                                                    minDate={filterStart}
                                                    onChange={(date) => { field.onChange(date); onChangeEnd(date) }}
                                                    selected={filterEnd}
                                                    locale="es"
                                                    dateFormat={fechaFormat}
                                                    todayButton="Clic aquí para Hoy"
                                                    className={!errors.end ? "form-control" : "form-control is-invalid"}
                                                    wrapperClassName={"form-control"}
                                                    isDisabled={loading}
                                                    placeholderText="Fecha fin"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                            )}
                                            rules={{ required: 'Requerido' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                                    <button
                                        className="btn btn-outline-primary w-100"
                                        onClick={() => onLoadEvents()}
                                        disabled={loading}
                                    >
                                        Filtrar <span><RefreshCw size={18} /></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="table-responsive">
                            <Table
                                className="table"
                                rowKey={(record) => record?.id}
                                columns={columns}
                                loading={loading}
                                dataSource={dataSource}
                                size="small"
                                pagination={
                                    {
                                        position: ["topRight"],
                                        total: dataSource.length,
                                        showTotal: (total) => `Total: ${total}`,
                                        showSizeChanger: true
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                {/* /product list */}
            </div>
        </div>
    );
};

