import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import variables from "../../../style/scss/utils/_variables.scss";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { HelpCircle, Layers } from 'react-feather';
import Select from "react-select";
import { noOptionsText, quantityRegex } from '../../utils';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setExpiredTypeOptions, setShowRemoteResponseToast, setStoreOptions } from '../../redux/action';
import { showInternalErrorAlert } from '../../../feature-module/components/customAlert';
import SearchProduct from '../../../feature-module/components/searchProduct';
import FormCreatedBy from '../../../feature-module/components/createdByForm';
import { Catalogs } from '../../../services/internal/catalogs';
import { StockManagement } from '../../../services/internal/stockManagement';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const EditStock = ({ id, writeOff = false, show = false, onHide }) => {

    const { register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const storeOptions = useSelector((state) => state.storeOptions);
    const expiredTypeOptions = useSelector((state) => state.expiredTypeOptions);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadCatalogs = async () => {
        setSelectedProduct(null);

        try {
            dispatch(fetchDataRequest());

            const responseA = await Catalogs.getOptions(token, Catalogs.Name.Branches);
            dispatch(setStoreOptions(responseA.data.data));

            if (writeOff) {
                const responseB = await Catalogs.getOptions(token, Catalogs.Name.ExpiredTypes);
                dispatch(setExpiredTypeOptions(responseB.data.data));
            }

            dispatch(fetchDataSuccess(responseA.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onSaveInventory = async (formData) => {
        try {
            let data = StockManagement.requestData;
            data = {};
            data.articleId = selectedProduct?.value;
            data.branchId = formData.stockBranches?.value;
            data.quantity = formData.stock;
            if (writeOff) {
                data.expiredTypeId = formData.expiredType?.value;
            } else {
                data.expiredTypeId = 1;
            }
            if (formData.comment) {
                let newComment = {
                    date: new Date().toISOString(),
                    user: userInfo?.given_name,
                    comment: formData.comment
                };
                data.comment = JSON.stringify(newComment);
            }

            dispatch(fetchDataRequest());

            const response = await StockManagement.postSingle(token, data);

            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            clearForm();
            onModalHide(null, true);
        }
        catch (err) {
            handleException(err);
        }
    }

    const clearForm = async () => {
        reset(formValues => ({
            ...formValues,
            stock: null,
            comment: null,
            stockBranches: null,
            expiredType: null,
        }));

        setSelectedProduct(null);
    }

    const showConfirmAlert = async (formData) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a ajustar las existencias del inventario, asegúrate de revisar las cantidades ingresadas.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "He confirmado",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onSaveInventory(formData);
        } else {
            MySwal.close();
        }
    }

    const onSearchProductError = (err) => {
        handleException(err);
    }

    const onModalHide = (e, refresh) => {
        e?.preventDefault();
        clearForm();
        onHide(refresh);
    }

    useEffect(() => {
        if (storeOptions.length === 0 || (writeOff && expiredTypeOptions.length === 0)) {
            onLoadCatalogs();
        }
    }, []);

    return (
        <Modal show={show} centered onHide={onModalHide} id={id} backdrop="static" keyboard={false}>
            <form onSubmit={handleSubmit((data) => showConfirmAlert(data))}>
                <Modal.Header className="custom-modal-header" closeButton={!loading}>
                    <Modal.Title>
                        <h4>{writeOff ? "Operación de inventario" : "Ajuste de existencias"}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormCreatedBy isNew={true} />
                    <SearchProduct
                        productSelectedCallback={(product) => setSelectedProduct(product)}
                        errorCallback={(err) => onSearchProductError(err)}
                    />
                    <div className="row">
                        {selectedProduct ?
                            <div className="col-lg-7 col-sm-7 col-12 mb-3">
                                <label className="form-label">Sucursal<span className="text-danger"> *</span></label>
                                <Controller
                                    name="stockBranches"
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Select
                                            inputRef={ref}
                                            options={storeOptions}
                                            placeholder="Elige una sucursal"
                                            className={errors.stockBranches ? "form-control is-invalid" : ""}
                                            value={value}
                                            onChange={onChange}
                                            noOptionsMessage={() => noOptionsText}
                                            isDisabled={loading}
                                            styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                            isClearable
                                        />
                                    )}
                                    rules={
                                        {
                                            validate: {
                                                sizes: (value) => {
                                                    return value || "Requerido";
                                                }
                                            }
                                        }
                                    }
                                />
                                {
                                    errors.stockBranches ?
                                        <div className="invalid-feedback">
                                            {errors.stockBranches.message}
                                        </div>
                                        : null
                                }
                            </div>
                            : null}
                        {selectedProduct ?
                            <div className="col-lg-5 col-sm-5 col-12 mb-3">
                                <label className="form-label">Existencias<span className="text-danger me-1"> *</span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-minus">
                                                La cantidad que captures registrará una operación de inventario positiva o negativa dependiendo las existencias registradas inicialmente.
                                            </Tooltip>}
                                    >
                                        <a className='pe-auto'><HelpCircle className="feather-14 " /></a>
                                    </OverlayTrigger>
                                </label>
                                <div className="input-group flex-wrap">
                                    <span className="input-group-text"><Layers size={15} color={variables.primaryColor} /></span>
                                    <input
                                        type="text"
                                        placeholder="0.0000"
                                        className={!errors.stock ? "form-control" : "form-control is-invalid"}
                                        {...register("stock",
                                            {
                                                required: "Requerido",
                                                pattern: {
                                                    value: quantityRegex,
                                                    message: "Requerimos una cantidad válida"
                                                }
                                            }
                                        )}
                                        maxLength={14}
                                        disabled={loading}
                                    />
                                    {
                                        errors.stock ?
                                            <div className="invalid-feedback">
                                                {errors.stock.message}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            : null}
                        {writeOff && selectedProduct ?
                            <div className="col-lg-12 col-sm-12 col-12 mb-3">
                                <label className="form-label">Tipo de operación<span className="text-danger"> *</span></label>
                                <Controller
                                    name="expiredType"
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Select
                                            inputRef={ref}
                                            options={expiredTypeOptions}
                                            placeholder="Elige una sucursal"
                                            className={errors.expiredType ? "form-control is-invalid" : ""}
                                            value={value}
                                            onChange={onChange}
                                            noOptionsMessage={() => noOptionsText}
                                            isDisabled={loading}
                                            styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                            isClearable
                                        />
                                    )}
                                    rules={
                                        {
                                            validate: {
                                                sizes: (value) => {
                                                    return value || "Requerido";
                                                }
                                            }
                                        }
                                    }
                                />
                                {
                                    errors.expiredType ?
                                        <div className="invalid-feedback">
                                            {errors.expiredType.message}
                                        </div>
                                        : null
                                }
                            </div>
                            : null
                        }
                    </div>
                    {selectedProduct ?
                        <div className="row">
                            <div className="col-lg-12 col-sm-12 col-12 mb-3">
                                <label className="form-label">Comentario</label>
                                <textarea
                                    rows={2}
                                    maxLength={300}
                                    placeholder="Describe brevemente por qué es necesario el ajuste."
                                    className={!errors.comment ? "form-control" : "form-control is-invalid"}
                                    disabled={loading}
                                    {...register("comment")}
                                />
                                {
                                    errors.comment ?
                                        <div className="invalid-feedback">
                                            {errors.comment.message}
                                        </div>
                                        : null
                                }
                                <p>Máximo 800 caracteres.</p>
                            </div>
                        </div>
                        : null}
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <button
                        className="btn btn-cancel me-2"
                        onClick={(e) => onModalHide(e, false)}
                        disabled={loading}
                    >
                        Cancelar
                    </button>
                    {
                        selectedProduct ?
                            loading ?
                                <button disabled={loading} className="btn btn-submit">
                                    <Spinner animation="border" role="status" size="sm" />
                                </button>
                                : <input
                                    type="submit"
                                    value="Ajustar"
                                    className="btn btn-submit"
                                />
                            : null
                    }
                </Modal.Footer>
            </form>
        </Modal>
    )
}

EditStock.propTypes = {
    id: PropTypes.string.isRequired,
    writeOff: PropTypes.bool,
    show: PropTypes.bool,
    onHide: PropTypes.func
};


export default EditStock
