import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import FormCreatedBy from "../../../feature-module/components/createdByForm";
import { Mail } from "react-feather";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from "../../redux/action";
import { showInternalErrorAlert } from "../../../feature-module/components/customAlert";
import { useForm } from "react-hook-form";
import variables from "../../../style/scss/utils/_variables.scss";
import { emailRegex, generarCodigo } from "../../utils";
import { Client } from "../../../services/internal/client";

const AddCustomerModal = ({ id, show = false, onHide }) => {
    const loading = useSelector(state => state.loading);
    const token = useSelector(state => state.token);
    const dispatch = useDispatch();
    const { setValue, getValues, register, handleSubmit, reset, formState: { errors } } = useForm();

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onSaveItem = async (formData) => {
        try {
            let data = Client.requestData;
            data = {};
            data.name = formData.name;
            data.code = formData.code;
            data.lastName = formData.lastName;
            data.seccondLastName = formData.seccondLastName;
            data.email = formData.email;
            data.stateId = 1;

            dispatch(fetchDataRequest());

            const response = await Client.postSingle(token, data);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            clearForm();
            onModalHide(null, true);
        }
        catch (err) {
            handleException(err);
        }
    }

    const onGenerateCode = (e) => {
        e?.preventDefault();
        setValue('code', generarCodigo());
    }

    const clearForm = () => {
        reset(formValues => ({
            ...formValues,
            name: null,
            location: null,
            email: null,
            phone: null,
            workshiftId: null,
            code: null
        }));
    }

    const onModalHide = (e, refresh) => {
        e?.preventDefault();
        clearForm();
        onHide(refresh);
    }

    useEffect(() => {
        const { code } = getValues();
        if (!code) {
            onGenerateCode();
        }
    }, []);


    return (
        <Modal show={show} centered onHide={onModalHide} id={id}>
            <form onSubmit={handleSubmit((data) => onSaveItem(data))}>
                <Modal.Header className="custom-modal-header" closeButton={!loading}>
                    <Modal.Title>
                        <h4>Registrar Cliente</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-12 mb-3">
                            <label className="form-label">Código de sistema<span className="text-danger"> *</span></label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className={!errors.code ? "form-control" : "form-control is-invalid"}
                                    placeholder="Genera un código"
                                    {...register("code",
                                        {
                                            required: "Genera un código por favor.",
                                        }
                                    )}
                                    disabled
                                />
                                <button
                                    className="btn btn-primary"
                                    onClick={(e) => onGenerateCode(e)}
                                    disabled={loading}
                                >
                                    Generar
                                </button>
                                {
                                    errors.code ?
                                        <div className="invalid-feedback">
                                            {errors.code.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12 mb-3">
                            <label className="form-label">Nombre(s)<span className="text-danger"> *</span></label>
                            <input
                                type="text"
                                className={!errors.name ? "form-control" : "form-control is-invalid"}
                                {...register("name",
                                    {
                                        required: "Nombre del cliente es requerido"
                                    }
                                )}
                                disabled={loading}
                                maxLength={300}
                            />
                            {
                                errors.name ?
                                    <div className="invalid-feedback">
                                        {errors.name.message}
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 mb-3">
                            <label className="form-label">Apellido Paterno</label>
                            <input
                                type="text"
                                className={!errors.lastName ? "form-control" : "form-control is-invalid"}
                                {...register("lastName")}
                                disabled={loading}
                                maxLength={300}
                            />
                            {
                                errors.lastName ?
                                    <div className="invalid-feedback">
                                        {errors.lastName.message}
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 mb-3">
                            <label className="form-label">Apellido Materno</label>
                            <input
                                type="text"
                                className={!errors.seccondLastName ? "form-control" : "form-control is-invalid"}
                                {...register("seccondLastName")}
                                disabled={loading}
                                maxLength={300}
                            />
                            {
                                errors.seccondLastName ?
                                    <div className="invalid-feedback">
                                        {errors.seccondLastName.message}
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12 mb-3">
                            <label className="form-label">Correo electrónico</label>
                            <div className="input-group flex-wrap">
                                <span className="input-group-text"><Mail size={15} color={variables.primaryColor} /></span>
                                <input
                                    type="text"
                                    placeholder="Escribe el email"
                                    className={!errors.email ? "form-control" : "form-control is-invalid"}
                                    {...register("email",
                                        {
                                            pattern: {
                                                value: emailRegex,
                                                message: "No tiene estructura de un email válido"
                                            },
                                        }
                                    )}
                                    disabled={loading}
                                    maxLength={300}
                                />
                                {
                                    errors.email ?
                                        <div className="invalid-feedback">
                                            {errors.email.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    <FormCreatedBy isNew={true} />
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <button
                        className="btn btn-cancel me-2"
                        onClick={(e) => onModalHide(e, false)}
                        disabled={loading}
                    >
                        Cancelar
                    </button>
                    {
                        loading ?
                            <button disabled={loading} className="btn btn-submit">
                                <Spinner animation="border" role="status" size="sm" />
                            </button>
                            :
                            <input type="submit" value="Registrar" className="btn btn-submit" />
                    }
                </Modal.Footer>
            </form>
        </Modal>

    );
};

AddCustomerModal.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func
};


export default AddCustomerModal;
