import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Minus, Plus } from "react-feather";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast, setStoreOptions } from "../../redux/action";
import { showInternalErrorAlert } from "../../../feature-module/components/customAlert";
import { Catalogs } from "../../../services/internal/catalogs";
import Select from "react-select";
import { noOptionsText } from "../../utils";
import { Recipes } from "../../../services/internal/recipes";

const ProduceRecipe = ({ id, show = false, onHide, recipe }) => {

    const loading = useSelector(state => state.loading);
    const token = useSelector(state => state.token);
    const storeOptions = useSelector(state => state.storeOptions);
    const userDetails = useSelector(state => state.userDetails);
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(1);
    const [quantityConfirm, setQuantityConfirm] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [currentStore, setCurrentStore] = useState(null);

    const onChangeQty = (e) => {
        e?.preventDefault();
        let qty = Number(e?.target.value);
        if (qty <= 0 || isNaN(qty)) {
            qty = 1;
        }
        if (qty > 9999) {
            qty = 9999;
        }
        setQuantity(qty);
    }

    const onPlusQty = () => {
        let qty = quantity;
        if (qty >= 9999) {
            qty = 9999;
        } else {
            qty += 1;
        }
        setQuantity(qty);
    }

    const onMinusQty = () => {
        let qty = quantity - 1;
        if (qty <= 0) {
            qty = 1;
        }
        setQuantity(qty);
    }

    const onConfirm = async () => {
        if (quantityConfirm !== quantity) {
            setConfirm(true);
        } else {
            await onProduceRecipe();
        }
    }

    const onChangeStore = (value) => {
        setConfirm(false);
        setQuantityConfirm(null);
        setCurrentStore(value);
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadStoreOptions = async () => {
        try {
            dispatch(fetchDataRequest());
            const response = await Catalogs.getOptions(token, Catalogs.Name.Branches);
            const sOptions = response.data.data;
            dispatch(setStoreOptions(sOptions));
            setCurrentStore(sOptions.find((s) => s.value === userDetails?.branch?.id));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onProduceRecipe = async () => {
        try {
            if (!recipe) {
                return;
            }
            let data = Recipes.requestDataExecute;
            data = {};
            data.branchId = currentStore?.value;
            data.quantity = quantity
            data.recipeId = recipe.id;
            dispatch(fetchDataRequest());
            const response = await Recipes.postExecute(token, data);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            clearData();
            onHide();
        }
        catch (err) {
            handleException(err);
        }
    }

    const clearData = () => {
        setConfirm(false);
        setQuantityConfirm(null);
        setQuantity(1);
    }

    const onModalHide = (e, refresh) => {
        e?.preventDefault();
        onHide(refresh);
    }

    useEffect(() => {
        if (storeOptions.length === 0) {
            onLoadStoreOptions();
        } else {
            setCurrentStore(storeOptions.find((s) => s.value === userDetails?.branch?.id));
        }
    }, [userDetails]);

    return (
        <Modal show={show} centered onHide={onModalHide} id={id} backdrop="static" keyboard={false}>
            <Modal.Header className="custom-modal-header" closeButton={!loading}>
                <Modal.Title>
                    <h4 className="capitalice">Producir {recipe?.name}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <p className="text-center text-info fw-semibold">Al ejecutar una receta afectarás el inventario de productos.</p>
                    <div className="col-12 mb-3">
                        <label className="form-label">Sucursal<span className="text-danger"> *</span></label>
                        <Select
                            options={storeOptions}
                            placeholder="Elige la sucursal"
                            value={currentStore}
                            onChange={(value) => onChangeStore(value)}
                            noOptionsMessage={() => noOptionsText}
                            isDisabled={loading}
                            styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                            isClearable
                        />
                    </div>
                    {currentStore &&
                        <div className="col-12 mb-3">
                            <label className="form-label">¿Cuántas veces ejecutáremos la receta?<span className="text-danger"> *</span></label>
                            <div className="input-group justify-content-center">
                                <button
                                    className="btn btn-outline-secondary me-2"
                                    onClick={onMinusQty}
                                    disabled={quantity <= 1 || loading}>
                                    <Minus />
                                </button>
                                <input
                                    type="text"
                                    className="form-control text-center me-2"
                                    maxLength={4}
                                    value={quantity}
                                    onChange={e => onChangeQty(e)}
                                    style={{ fontSize: '1.8rem' }}
                                    disabled={loading}
                                />
                                <button
                                    className="btn btn-outline-primary"
                                    onClick={onPlusQty}
                                    disabled={loading}
                                >
                                    <Plus />
                                </button>
                            </div>
                        </div>
                    }
                    {confirm && currentStore &&
                        <div className="col-12">
                            <input
                                type="text"
                                className={quantityConfirm !== quantity ? "form-control text-center is-invalid" : "form-control text-center is-valid"}
                                maxLength={4}
                                value={quantityConfirm}
                                onChange={e => setQuantityConfirm(Number(e.target.value))}
                                style={{ fontSize: '1.8rem' }}
                                disabled={loading}
                            />
                            <div className="invalid-feedback">
                                Por favor confirma la cantidad.
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
                <button
                    className="btn btn-cancel me-2"
                    onClick={(e) => onModalHide(e, false)}
                    disabled={loading}
                >
                    Regresar
                </button>
                {
                    loading &&
                    <button disabled={loading} className="btn btn-submit">
                        <Spinner animation="border" role="status" size="sm" />
                    </button>
                }
                {
                    currentStore && !loading &&
                    <button
                        className="btn btn-submit"
                        onClick={onConfirm}
                        disabled={loading}>
                        Producir
                    </button>
                }
            </Modal.Footer>
        </Modal>
    );
};

ProduceRecipe.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    recipe: PropTypes.object,
};


export default ProduceRecipe;
