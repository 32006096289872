import React from 'react';

import * as Icon from 'react-feather';
import { all_routes } from './all_routes';

const route = all_routes;

export const Modules = {
    Sucursales: "Sucursales",
    Productos: "Productos",
    Categorias: "Categorias",
    Unidades: "Unidades",
    Existencias: "Existencias",
    OperacionesInventario: "OperacionesInventario",
    TiposOperacionInventario: "TiposOperacionInventario",
    OrdenesCompra: "OrdenesCompra",
    Traslados: "Traslados",
    Pedidos: "Pedidos",
    Proveedores: "Proveedores",
    Recetarios: "Recetarios",
    Paquetes: "Paquetes",
    Menus: "Menus",
    Ventas: "Ventas",
    CortesCaja: "CortesCaja",
    SalidasEfectivo: "SalidasEfectivo",
    Clientes: "Clientes",
    PuntoVenta: "PuntoVenta",
    Areas: "Areas",
    Puestos: "Puestos",
    Usuarios: "Usuarios",
    Permisos: "Permisos",
    Roles: "Roles",
    Asistencia: "Asistencia",
    Vacaciones: "Vacaciones",
    TiposJustificacion: "TiposJustificacion",
    Horarios: "Horarios",
    DiasFestivos: "DiasFestivos",
    ReporteVentas: "ReporteVentas",
    ReporteIngresos: "ReporteIngresos",
    ReporteGastos: "ReporteGastos",
    ReporteSalidaInventario: "ReporteSalidaInventario",
    ReporteProduccion: "ReporteProduccion",
    ReporteProduccionArticulo: "ReporteProduccionArticulo",
    ReporteCuentasPorPagar: "ReporteCuentasPorPagar",
    ConfEmail: "ConfEmail",
    ConfMetodosPago: "ConfMetodosPago",
    ConfRecibos: "ConfRecibos",
    DashboardGeneral: "DashboardGeneral",
    DashboardVentas: "DashboardVentas",
    ConfSistema: "ConfSistema",
    Bitacora: "Bitacora"
};

export const Functions = {
    Aprobar: "Aprobar",
    Copiar: "Copiar",
    Eliminar: "Eliminar",
    Exportar: "Exportar",
    Importar: "Importar",
    Modificar: "Modificar",
    Producir: "Producir",
    Registrar: "Registrar",
    Transferir: "Transferir",
    Ver: "Ver",
};

export const buildSidebarData = (permissions) => {
    const sidebarData = [];

    const inicio = buildMenuInicio(permissions);
    if (inicio) {
        sidebarData.push(
            inicio,
        );
    }
    const inventario = buildMenuInventario(permissions);
    if (inventario) {
        sidebarData.push(
            inventario,
        );
    }
    const compras = buildMenuCompras(permissions);
    if (compras) {
        sidebarData.push(
            compras,
        );
    }
    const produccion = buildMenuProduccion(permissions);
    if (produccion) {
        sidebarData.push(
            produccion,
        );
    }
    const ventas = buildMenuVentas(permissions);
    if (ventas) {
        sidebarData.push(
            ventas,
        );
    }
    const personal = buildMenuPersonal(permissions);
    if (personal) {
        sidebarData.push(
            personal,
        );
    }
    const reportes = buildMenuReportes(permissions);
    if (reportes) {
        sidebarData.push(
            reportes,
        );
    }

    sidebarData.push(buildMenuConfiguracion(permissions));
    return sidebarData;
}

const buildMenuInicio = (permissions) => {
    const subMenu = {
        label: "Inicio",
        submenuOpen: true,
        showSubRoute: false,
        submenuHdr: "Inicio",
        submenuItems: []
    };

    const dasboards = buildMenuDashboard(permissions);
    if (dasboards) {
        subMenu.submenuItems.push(
            dasboards,
        );
    }

    if (checkModule(permissions, Modules.Sucursales, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Sucursales", link: route.storelist, icon: <Icon.Home />, showSubRoute: false, submenu: false },
        );
    }

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

const buildMenuDashboard = (permissions) => {
    const subMenu = {
        label: "Dashboard",
        icon: <Icon.Grid />,
        submenu: true,
        showSubRoute: false,
        submenuItems: []
    };

    if (checkModule(permissions, Modules.DashboardGeneral, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "General", link: "/" },
        );
    }

    if (checkModule(permissions, Modules.DashboardVentas, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Ventas", link: route.salesdashboard });
    }

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

const buildMenuInventario = (permissions) => {
    const subMenu = {
        label: "Inventario",
        submenuOpen: true,
        showSubRoute: false,
        submenuHdr: "Inventario",
        submenuItems: []
    };

    if (checkModule(permissions, Modules.Productos, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Productos", link: route.productlist, icon: <Icon.Box />, showSubRoute: false, submenu: false },
        );
    }

    if (checkModule(permissions, Modules.Productos, Functions.Registrar)) {
        subMenu.submenuItems.push(
            { label: "Registrar producto", link: route.addproduct, icon: <Icon.PlusSquare />, showSubRoute: false, submenu: false },
        );
    }

    if (checkModule(permissions, Modules.Categorias, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Categorías", link: route.categorylist, icon: <Icon.Codepen />, showSubRoute: false, submenu: false },
        );
    }
    if (checkModule(permissions, Modules.Unidades, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Unidades de medida", link: route.units, icon: <Icon.Hash />, showSubRoute: false, submenu: false },
        );
    }
    if (checkModule(permissions, Modules.Existencias, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Existencias", link: route.managestock, icon: <Icon.Package />, showSubRoute: false, submenu: false },
        );
    }
    if (checkModule(permissions, Modules.OperacionesInventario, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Operaciones de inventario", link: route.stockadjustment, icon: <Icon.Codesandbox />, showSubRoute: false, submenu: false },
        );
    }
    if (checkModule(permissions, Modules.TiposOperacionInventario, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Tipos de operación", link: route.expiredtypes, icon: <Icon.Repeat />, showSubRoute: false, submenu: false }
        );
    }

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

const buildMenuCompras = (permissions) => {
    const subMenu = {
        label: "Compras",
        submenuOpen: true,
        submenuHdr: "Compras",
        showSubRoute: false,
        submenuItems: []
    };

    if (checkModule(permissions, Modules.OrdenesCompra, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Órdenes de compra", link: route.purchaselist, icon: <Icon.ShoppingBag />, showSubRoute: false, submenu: false },
        );
    }

    if (checkModule(permissions, Modules.Traslados, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Traslados", link: route.stocktransfer, icon: <Icon.Truck />, showSubRoute: false, submenu: false },
        );
    }

    if (checkModule(permissions, Modules.Pedidos, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Pedidos", link: route.purchaseProductList, icon: <Icon.Clipboard />, showSubRoute: false, submenu: false },
        );
    }
    if (checkModule(permissions, Modules.Proveedores, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Proveedores", link: route.suppliers, icon: <Icon.Users />, showSubRoute: false, submenu: false },
        );
    }

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

const buildMenuProduccion = (permissions) => {
    const subMenu = {
        label: "Producción",
        submenuOpen: true,
        submenuHdr: "Producción",
        submenu: true,
        showSubRoute: false,
        submenuItems: []
    };

    if (checkModule(permissions, Modules.Recetarios, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Recetarios", link: route.recipebook, icon: <Icon.Book />, showSubRoute: false, submenu: false },
        );
    }

    if (checkModule(permissions, Modules.Paquetes, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Paquetes", link: route.packagegrid, icon: <Icon.Layers />, showSubRoute: false, submenu: false },
        );
    }

    if (checkModule(permissions, Modules.Menus, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Menús", link: route.menugrid, icon: <Icon.BookOpen />, showSubRoute: false, submenu: false },
        );
    }

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

const buildMenuVentas = (permissions) => {
    const subMenu = {
        label: "Ventas",
        submenuOpen: true,
        submenuHdr: "Ventas",
        submenu: false,
        showSubRoute: false,
        submenuItems: []
    };

    if (checkModule(permissions, Modules.Ventas, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Ventas", link: route.saleslist, icon: <Icon.ShoppingCart />, showSubRoute: false, submenu: false },
        );
    }

    if (checkModule(permissions, Modules.CortesCaja, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Cortes de caja", link: route.cashbreak, icon: <Icon.Inbox />, showSubRoute: false, submenu: false },
        );
    }

    if (checkModule(permissions, Modules.SalidasEfectivo, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Salidas de efectivo", link: route.cashoutflowlist, icon: <Icon.CornerUpRight />, showSubRoute: false, submenu: false },
        );
    }

    if (checkModule(permissions, Modules.Clientes, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Clientes", link: route.customers, icon: <Icon.Users />, showSubRoute: false, submenu: false },
        );
    }

    if (checkModule(permissions, Modules.PuntoVenta, Functions.Ver)) {
        if (process.env.REACT_APP_SIMPLE_POS_ENABLED === "true") {
            subMenu.submenuItems.push(
                { label: "Punto de venta", link: route.simplepos, icon: <Icon.HardDrive />, showSubRoute: false, submenu: false },
            );
        } else {
            subMenu.submenuItems.push(
                { label: "Punto de venta", link: route.pos, icon: <Icon.HardDrive />, showSubRoute: false, submenu: false },
            );
        }
    }

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

const buildMenuPersonal = (permissions) => {
    const subMenu = {
        label: "Personal",
        submenuOpen: true,
        showSubRoute: false,
        submenuHdr: "Personal",
        submenuItems: []
    };

    if (checkModule(permissions, Modules.Areas, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Áreas", link: route.departmentgrid, icon: <Icon.Flag />, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.Puestos, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Puestos", link: route.designation, icon: <Icon.User />, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.Usuarios, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Usuarios", link: route.employeegrid, icon: <Icon.Users />, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.Permisos, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Permisos", link: route.permissions, icon: <Icon.Shield />, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.Roles, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Roles", link: route.rolespermission, icon: <Icon.UserCheck />, showSubRoute: false },
        );
    }

    const asistencia = buildMenuAsistencia(permissions);
    if (asistencia) {
        subMenu.submenuItems.push(
            asistencia,
        );
    }

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

const buildMenuAsistencia = (permissions) => {
    const subMenu = {
        label: "Asistencia",
        link: "#",
        icon: <Icon.Calendar />,
        showSubRoute: false,
        submenu: true,
        submenuItems: []
    };

    if (checkModule(permissions, Modules.Asistencia, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Asistencia", link: route.attendanceemployee },
        );
    }

    if (checkModule(permissions, Modules.Vacaciones, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Vacaciones", link: route.leavesemployee },
        );
    }

    if (checkModule(permissions, Modules.TiposJustificacion, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Tipos de justificación", link: route.leavestype },
        );
    }

    if (checkModule(permissions, Modules.Horarios, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Horarios", link: route.shift },
        );
    }

    if (checkModule(permissions, Modules.DiasFestivos, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Días festivos", link: route.holidays }
        );
    }

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

const buildMenuReportes = (permissions) => {
    const subMenu = {
        label: "Reportes",
        submenuOpen: true,
        showSubRoute: false,
        submenuHdr: "Reportes",
        submenuItems: []
    };

    if (checkModule(permissions, Modules.ReporteVentas, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Reporte de ventas", link: route.salesreport, icon: <Icon.PieChart />, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.ReporteIngresos, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Reporte de ingresos", link: route.incomereport, icon: <Icon.PieChart />, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.ReporteGastos, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Reporte de gastos", link: route.expensereport, icon: <Icon.BarChart />, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.ReporteSalidaInventario, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Reporte de inventario", link: route.inventoryreport, icon: <Icon.BarChart2 />, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.ReporteProduccion, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Reporte de producción", link: route.supplierreport, icon: <Icon.PieChart />, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.ReporteProduccionArticulo, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Reporte de producción por artículo", link: route.customerreport, icon: <Icon.BarChart />, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.ReporteCuentasPorPagar, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Reporte de cuentas por pagar", link: route.invoicereport, icon: <Icon.BarChart2 />, showSubRoute: false }
        );
    }

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

const buildMenuConfiguracion = (permissions) => {
    const subMenu = {
        label: "Configuración",
        submenu: true,
        showSubRoute: false,
        submenuHdr: "Configuración",
        submenuItems: [
            {
                label: "General",
                submenu: true,
                showSubRoute: false,
                icon: <Icon.Settings />,
                submenuItems: [
                    { label: "Perfil", link: route.profile },
                ]
            },
        ]
    };

    const pos = buildMenuPos(permissions);
    if (pos) {
        subMenu.submenuItems.push(
            pos,
        );
    }

    const sistema = buildMenuSistema(permissions);
    if (sistema) {
        subMenu.submenuItems.push(
            sistema,
        );
    }

    subMenu.submenuItems.push(
        { label: "Cerrar sesión", link: route.signout, icon: <Icon.LogOut />, showSubRoute: false }
    );

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

const buildMenuPos = (permissions) => {
    const subMenu = {
        label: "Punto de venta", submenu: true,
        showSubRoute: false,
        icon: <Icon.Smartphone />,
        submenuItems: []
    };

    if (checkModule(permissions, Modules.ConfRecibos, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Recibos", link: route.invoicesettings, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.ConfMetodosPago, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Métodos de pago", link: route.paymentgateway, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.ReporteIngresos, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Tarifas de impuestos", link: route.taxrates, showSubRoute: false }
        );
    }

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

const buildMenuSistema = (permissions) => {
    const subMenu = {
        label: "Sistema", submenu: true,
        showSubRoute: false,
        icon: <Icon.Monitor />,
        submenuItems: []
    };

    if (checkModule(permissions, Modules.Bitacora, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Bitácora", link: route.eventlog, showSubRoute: false },
        );
    }

    if (checkModule(permissions, Modules.ConfSistema, Functions.Ver)) {
        subMenu.submenuItems.push(
            { label: "Email", link: route.emailsettings, showSubRoute: false },
        );
    }

    return subMenu.submenuItems?.length > 0 ? subMenu : null;
}

export const checkModule = (permissions, moduleName, functionName) => {

    switch (moduleName) {
        case Modules.Sucursales:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Productos:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Categorias:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Unidades:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Existencias:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.OperacionesInventario:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.TiposOperacionInventario:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.OrdenesCompra:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Traslados:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Pedidos:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Proveedores:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Recetarios:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Paquetes:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Menus:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Ventas:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.CortesCaja:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.SalidasEfectivo:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Clientes:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.PuntoVenta:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Areas:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Puestos:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Usuarios:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Permisos:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Roles:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Asistencia:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Vacaciones:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.TiposJustificacion:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Horarios:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.DiasFestivos:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.ReporteVentas:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.ReporteIngresos:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.ReporteGastos:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.ReporteSalidaInventario:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.ReporteProduccion:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.ReporteProduccionArticulo:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.ReporteCuentasPorPagar:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.ConfEmail:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.ConfMetodosPago:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.ConfRecibos:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.DashboardGeneral:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.DashboardVentas:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.ConfSistema:
            return checkModuleFunction(permissions, moduleName, functionName);
        case Modules.Bitacora:
            return checkModuleFunction(permissions, moduleName, functionName);
        default:
            return false;
    }
}

const checkModuleFunction = (permissions, moduleName, functionName) => {
    switch (functionName) {
        case Functions.Aprobar:
            return verifyPermission(permissions, moduleName, functionName);
        case Functions.Copiar:
            return verifyPermission(permissions, moduleName, functionName);
        case Functions.Eliminar:
            return verifyPermission(permissions, moduleName, functionName);
        case Functions.Exportar:
            return verifyPermission(permissions, moduleName, functionName);
        case Functions.Importar:
            return verifyPermission(permissions, moduleName, functionName);
        case Functions.Modificar:
            return verifyPermission(permissions, moduleName, functionName);
        case Functions.Producir:
            return verifyPermission(permissions, moduleName, functionName);
        case Functions.Registrar:
            return verifyPermission(permissions, moduleName, functionName);
        case Functions.Transferir:
            return verifyPermission(permissions, moduleName, functionName);
        case Functions.Ver:
            return verifyPermission(permissions, moduleName, functionName);
        default:
            return false;
    }
}

export const verifyPermission = (permissions, moduleName, functionName) => {
    if (!permissions) {
        return false;
    }

    const module = permissions?.modules?.find((p) => p?.name === moduleName);
    if (!module) {
        return false;
    }
    const hasFunction = module?.functions?.filter((m) => m?.name === functionName);

    if (hasFunction?.length === 0) {
        return false;
    }
    return true;
}

