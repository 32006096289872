import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setLeaveTypeOptions, setShiftList, setShowRemoteResponseToast, setStoreOptions } from "../../redux/action";
import { showInternalErrorAlert } from "../../../feature-module/components/customAlert";
import { CheckIns } from "../../../services/internal/checkIns";
import variables from "../../../style/scss/utils/_variables.scss";
import { Clock } from "react-feather";
import { convertirHoraStringADate, fechaHoraFormat, formatearFecha, horaFormat, noOptionsText } from "../../utils";
import { Controller, useForm } from "react-hook-form";
import { Catalogs } from "../../../services/internal/catalogs";
import Select from "react-select";
import { Branches } from "../../../services/internal/branches";
import { Workshifts } from "../../../services/internal/workshifts";

const CheckInModal = ({ id, show = false, onHide, isCheckOut }) => {

    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const userDetails = useSelector((state) => state.userDetails);
    const leaveTypeOptions = useSelector((state) => state.leaveTypeOptions);
    const storeOptions = useSelector((state) => state.storeOptions);
    const shiftList = useSelector((state) => state.shiftList);
    const token = useSelector((state) => state.token);
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const [reasonChecked, setReasonChecked] = useState(false);
    const [currentStore, setCurrentStore] = useState(null);
    const [currentShift, setCurrentShift] = useState(null);
    const [isOutOfTIme, setOutOfTIme] = useState(false);
    const [checkInData, setCheckInData] = useState(null);

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadOptions = async () => {
        try {

            dispatch(fetchDataRequest());

            if (leaveTypeOptions.length === 0) {
                const response = await Catalogs.getOptions(token, Catalogs.Name.LeaveTypes);
                dispatch(setLeaveTypeOptions(response.data.data));
            }

            if (storeOptions.length === 0) {
                const response = await Catalogs.getOptions(token, Catalogs.Name.Branches);
                setCurrentStore(response.data.data.find((f) => f.value === userDetails.branch?.id));
                dispatch(setStoreOptions(response.data.data));
            } else {
                setCurrentStore(storeOptions.find((f) => f.value === userDetails.branch?.id));
            }

            if (shiftList.length === 0) {
                const response = await Workshifts.getList(token, { enabled: true });
                setCurrentShift(response.data.data.find((f) => f.id === userDetails.branch?.workshiftId));
                dispatch(setShiftList(response.data.data));
            } else {
                const cShift = shiftList.find((f) => f.id === userDetails.branch?.workshiftId);
                setCurrentShift(cShift);
                checkOutOfTime(cShift?.timeIn);
            }

            const response = await CheckIns.getSingle(token, userDetails.id);
            setCheckInData(response.data.data);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onCheckIn = async (formData) => {
        try {
            let data = CheckIns.requestData;
            data = {};
            data.userId = userDetails.id;
            if (data.justifyId) {
                data.justifyId = formData.justifyId?.value;
            }
            if (data.comment) {
                data.comment = formData.comment;
            }

            dispatch(fetchDataRequest());
            if (!isCheckOut) {
                const response = await CheckIns.postSingle(token, data);
                dispatch(fetchDataSuccess(response.data.success));
            } else {
                const response = await CheckIns.putSingle(token, data);
                dispatch(fetchDataSuccess(response.data.success));
            }
            dispatch(setShowRemoteResponseToast(true));
            onModalHide();
        }
        catch (err) {
            handleException(err);
        }
    }

    const onReasonChecked = (checked) => {
        setReasonChecked(checked === 'false')
    }

    const onChangeStore = async (option) => {
        setCurrentStore(option);
        try {

            dispatch(fetchDataRequest());
            const response = await Branches.getSingle(token, option.value);
            const cShift = shiftList.find((f) => f.id === response.data.data.workshiftId);
            setCurrentShift(cShift);
            dispatch(fetchDataSuccess(true));
        }
        catch (err) {
            handleException(err);
        }
    }

    const checkOutOfTime = (timeIn) => {
        const result = convertirHoraStringADate(timeIn).getTime() < new Date().getTime();
        setReasonChecked(result);
        setOutOfTIme(result);
    }

    const onModalHide = () => {
        onHide();
    }

    useEffect(() => {
        if (userDetails) {
            onLoadOptions();
        }
    }, [userDetails]);

    return (
        <Modal show={show} centered onHide={onModalHide} id={id} backdrop="static" keyboard={false}>
            <form onSubmit={handleSubmit((data) => onCheckIn(data))}>
                <Modal.Header className="custom-modal-header" closeButton={!loading}>
                    <Modal.Title>
                        <h4>{isCheckOut ? "Check-Out de Asistencia" : "Check-In de Asistencia"}</h4>
                    </Modal.Title>
                </Modal.Header>
                {checkInData?.dateOut ?
                    <Modal.Body>
                        <p>Ya hemos registrado tu salida:</p>
                        <h5 className="text-primary fw-bold text-center">
                            {formatearFecha(checkInData?.dateOut, fechaHoraFormat)}
                        </h5>
                    </Modal.Body>
                    :
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                <label className="form-label">Mi Sucursal</label>
                                <Select
                                    options={storeOptions}
                                    placeholder="Elige la sucursal"
                                    value={currentStore}
                                    onChange={(value) => onChangeStore(value)}
                                    noOptionsMessage={() => noOptionsText}
                                    isDisabled={loading}
                                    styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                />
                            </div>
                            {!isCheckOut &&
                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                    <label className="form-label">Horario de entrada</label>
                                    <div className="input-group flex-wrap">
                                        <span className="input-group-text"><Clock size={15} color={variables.primaryColor} /></span>
                                        <input
                                            type="text"
                                            value={formatearFecha(convertirHoraStringADate(currentShift?.timeIn), horaFormat)}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            }
                            {isCheckOut &&
                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                    <label className="form-label">Horario de salida</label>
                                    <div className="input-group flex-wrap">
                                        <span className="input-group-text"><Clock size={15} color={variables.primaryColor} /></span>
                                        <input
                                            type="text"
                                            value={formatearFecha(convertirHoraStringADate(currentShift?.timeOut), horaFormat)}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            }
                            {!isCheckOut &&
                                <div className="col-12 mb-3">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={e => onReasonChecked(e.target.value)}
                                            value={reasonChecked}
                                            checked={reasonChecked}
                                            disabled={loading || isOutOfTIme}
                                            id="flexCheckDefault2" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault2" role="pointer">
                                            Justificación por fuera de horario
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            !isCheckOut && reasonChecked &&
                            <div className="col-12 mb-3">
                                <Controller
                                    name="justifyId"
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Select
                                            inputRef={ref}
                                            className={errors.justifyId ? "form-control is-invalid" : ""}
                                            options={leaveTypeOptions}
                                            placeholder="Elige una opción"
                                            value={value}
                                            onChange={onChange}
                                            noOptionsMessage={() => noOptionsText}
                                            isDisabled={loading}
                                            isClearable
                                        />
                                    )}
                                    rules={{ required: 'Requerido' }}
                                />
                                {
                                    errors.justifyId ?
                                        <div className="invalid-feedback">
                                            {errors.justifyId.message}
                                        </div>
                                        : null
                                }
                            </div>
                        }
                        <div className="col-12">
                            <label className="form-label">Comentario</label>
                            <textarea
                                rows={2}
                                maxLength={200}
                                className={!errors.comment ? "form-control" : "form-control is-invalid"}
                                disabled={loading}
                                {...register("comment")}
                            />
                            <p>Máximo 200 caracteres.</p>
                        </div>
                    </Modal.Body>
                }
                <Modal.Footer className='justify-content-center'>
                    {
                        loading ?
                            <button disabled={loading} className="btn btn-submit">
                                <Spinner animation="border" role="status" size="sm" />
                            </button>
                            :
                            <button
                                className="btn btn-cancel me-2"
                                onClick={(e) => onModalHide(e, false)}
                                disabled={loading}
                            >
                                Regresar
                            </button>
                    }
                    {!checkInData?.dateOut &&
                        <input
                            className="btn btn-primary me-2"
                            type="submit"
                            disabled={loading}
                            value={isCheckOut ? "Registrar Salida" : "Registrar Entrada"}
                        />
                    }
                </Modal.Footer>
            </form>
        </Modal>
    );
};

CheckInModal.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    isCheckOut: PropTypes.bool,
};


export default CheckInModal;
