import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import FormCreatedBy from "../../../feature-module/components/createdByForm";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spinner } from 'react-bootstrap';
import { Categories } from '../../../services/internal/categories';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCategoryOptions, setConceptOptions, setDepartmentOptions, setDesignationOptions, setExpiredTypeOptions, setLeaveTypeOptions, setRoleOptions, setShowRemoteResponseToast, setUnitOptions, setUserList } from '../../redux/action';
import { showInternalErrorAlert } from '../../../feature-module/components/customAlert';
import { Unmed } from '../../../services/internal/unmed';
import { ExpiredTypes } from '../../../services/internal/expiredTypes';
import { Areas } from '../../../services/internal/areas';
import Select from "react-select";
import { Users } from '../../../services/internal/users';
import { Designations } from '../../../services/internal/designations';
import { Roles } from '../../../services/internal/roles';
import { LeaveTypes } from '../../../services/internal/leaveTypes';
import ImageUploader from '../../../feature-module/components/fileUpload/ImageUpload';
import { RecipeBook } from '../../../services/internal/recipeBook';
import { generarCodigo } from '../../utils';
import { Concept } from '../../../services/internal/concept';

const AddCatalog = ({ id, show = false, onHide, isNew = false, type = "unit", currentId }) => {
    const userList = useSelector(state => state.userList);
    const loading = useSelector(state => state.loading);
    const token = useSelector(state => state.token);
    const dispatch = useDispatch();
    const { setValue, register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const [pageTitle, setPageTitle] = useState("");
    const [userOptions, setUserOptions] = useState([]);
    const [imagesPath, setImagesPath] = useState([]);

    const calculateResponse = (isLoad, data) => {

        switch (type) {
            case "category":
                if (isNew) {
                    return Categories.postSingle(token, data);
                }

                if (isLoad) {
                    return Categories.getSingle(token, data.id)
                }

                return Categories.putSingle(token, data);
            case "unit":
                if (isNew) {
                    return Unmed.postSingle(token, data);
                }

                if (isLoad) {
                    return Unmed.getSingle(token, data.id)
                }

                return Unmed.putSingle(token, data);
            case "role":
                if (isNew) {
                    return Roles.postSingle(token, data);
                }

                if (isLoad) {
                    return Roles.getSingle(token, data.id)
                }

                return Roles.putSingle(token, data);
            case "designation":
                if (isNew) {
                    return Designations.postSingle(token, data);
                }

                if (isLoad) {
                    return Designations.getSingle(token, data.id)
                }

                return Designations.putSingle(token, data);
            case "recipebook":
                if (isNew) {
                    return RecipeBook.postSingle(token, data);
                }

                if (isLoad) {
                    return RecipeBook.getSingle(token, data.id)
                }

                return RecipeBook.putSingle(token, data);
            case "recipe":
                if (isNew) {
                    return Categories.postSingle(token, data);
                }

                if (isLoad) {
                    return Categories.getSingle(token, data.id)
                }

                return Categories.putSingle(token, data);
            case "department":
                if (isNew) {
                    return Areas.postSingle(token, data);
                }

                if (isLoad) {
                    return Areas.getSingle(token, data.id)
                }

                return Areas.putSingle(token, data);
            case "leave":
                if (isNew) {
                    return LeaveTypes.postSingle(token, data);
                }

                if (isLoad) {
                    return LeaveTypes.getSingle(token, data.id)
                }

                return LeaveTypes.putSingle(token, data);
            case "cash":
                if (isNew) {
                    return Concept.postSingle(token, data);
                }

                if (isLoad) {
                    return Concept.getSingle(token, data.id)
                }

                return Concept.putSingle(token, data);
            default:
                if (isNew) {
                    return ExpiredTypes.postSingle(token, data);
                }

                if (isLoad) {
                    return ExpiredTypes.getSingle(token, data.id)
                }

                return ExpiredTypes.putSingle(token, data);
        }
    }

    const clearOptionsCache = (type) => {

        switch (type) {
            case "category":
                dispatch(setCategoryOptions([]));
                break;
            case "unit":
                dispatch(setUnitOptions([]));
                break;
            case "role":
                dispatch(setRoleOptions([]));
                break;
            case "designation":
                dispatch(setDesignationOptions([]));
                break;
            case "recipebook":
                return; //dispatch(setRecipeBookList([]));break;
            case "recipe":
                return; //dispatch(setRecipeList([]));break;
            case "department":
                dispatch(setDepartmentOptions([]));
                break;
            case "leave":
                dispatch(setLeaveTypeOptions([]));
                break;
            case "cash":
                dispatch(setConceptOptions([]));
                break;
            default:
                dispatch(setExpiredTypeOptions([]));
        }
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onSaveItem = async (formData) => {
        try {
            let data = Categories.requestData;
            data = {};
            if (currentId) {
                data.id = currentId;
            }
            data.name = formData.name;
            data.description = formData.description;
            //data.enabled = formData.enabled === "activo";
            data.abbreviation = formData.abbreviation;
            data.idResponsible = formData.idResponsible?.value;
            if (imagesPath?.length > 0) {
                data.imagePath = imagesPath[0];
            } else {
                data.imagePath = null;
            }

            dispatch(fetchDataRequest());

            const response = await calculateResponse(false, data);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            clearForm();
            clearOptionsCache();
            onModalHide(null, true);
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadItem = async () => {
        try {
            let data = Categories.requestData;
            data = {};
            data.id = currentId;

            dispatch(fetchDataRequest());

            const response = await calculateResponse(true, data);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));

            setValue('name', response.data.data.name);
            setValue('description', response.data.data.description);
            setValue('abbreviation', response.data.data.abbreviation);
            setValue('idResponsible', userOptions.find((i) => i.value === response.data.data.idResponsible));
            if (response.data.data.enabled) {
                setValue('enabled', 'activo');
            } else {
                setValue('enabled', 'inactivo');
            }
            if (response.data.data.imagePath) {
                setImagesPath([response.data.data.imagePath]);
            }
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadUserList = async () => {
        let filters = {};
        filters.enabled = true;

        try {
            dispatch(fetchDataRequest());

            const response = await Users.getList(token, filters);
            //console.log(response)
            dispatch(setUserList(response.data.data));
            setUserOptions(convertUserOptions(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const convertUserOptions = (data) => {
        let options = [];
        data.map((i) => {
            options.push(
                {
                    label: calculateLabel(i),
                    value: i.id
                });
        });
        return options;
    }

    const calculateLabel = (item) => {
        let label = '';
        label = `${item.name ? item.name : ''} ${item.surname ? item.surname : ''} ${item.lastname ? item.lastname : ''}`;
        label = label.trim() ? label : item.alias;
        return label;
    }

    const clearForm = () => {
        reset(formValues => ({
            ...formValues,
            name: null,
            description: null,
            abbreviation: null,
            enabled: null,
            idResponsible: null
        }));
    }

    const onModalHide = (e, refresh) => {
        e?.preventDefault();
        clearForm();
        onHide(refresh);
    }

    const onImagesChanged = (newImages) => {
        setImagesPath(newImages);
    }

    useEffect(() => {
        if (currentId) {
            onLoadItem();
        }

        switch (type) {
            case "category":
                isNew ? setPageTitle("Registrar categoría") : setPageTitle("Actualizar categoría")
                break;
            case "unit":
                isNew ? setPageTitle("Registrar unidad de medida") : setPageTitle("Actualizar unidad de medida")
                break;
            case "role":
                isNew ? setPageTitle("Registrar rol") : setPageTitle("Actualizar rol")
                break;
            case "designation":
                isNew ? setPageTitle("Registrar puesto") : setPageTitle("Actualizar puesto")
                break;
            case "recipebook":
                isNew ? setPageTitle("Registrar recetario") : setPageTitle("Actualizar recetario")
                break;
            case "recipe":
                isNew ? setPageTitle("Registrar receta") : setPageTitle("Actualizar receta")
                break;
            case "department":
                isNew ? setPageTitle("Registrar área") : setPageTitle("Actualizar área")
                if (userList.length === 0) {
                    onLoadUserList();
                }
                if (userOptions.length === 0) {
                    setUserOptions(convertUserOptions(userList));
                }
                break;
            case "leave":
                isNew ? setPageTitle("Registrar justificación") : setPageTitle("Actualizar justificación")
                break;
            case "cash":
                isNew ? setPageTitle("Registrar salida de efectivo") : setPageTitle("Actualizar salida de efectivo")
                break;
            default:
                isNew ? setPageTitle("Registrar tipo de salida") : setPageTitle("Actualizar tipo de salida")
                break;
        }
    }, [currentId, type]);


    return (
        <Modal show={show} centered onHide={onModalHide} id={id}>
            <form onSubmit={handleSubmit((data) => onSaveItem(data))}>
                <Modal.Header className="custom-modal-header" closeButton={!loading}>
                    <Modal.Title>
                        <h4>{pageTitle}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label className="form-label">Nombre <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className={!errors.name ? "form-control" : "form-control is-invalid"}
                                disabled={loading}
                                {...register("name",
                                    {
                                        required: "Requerido.",
                                    }
                                )}
                                maxLength={300}
                            />
                            {
                                errors.name ?
                                    <div className="invalid-feedback">
                                        {errors.name.message}
                                    </div>
                                    : null
                            }
                        </div>
                        {type === "unit" ?
                            <div className="col-12 mb-3">
                                <label className="form-label">Nombre corto</label>
                                <input
                                    type="text"
                                    className={!errors.abbreviation ? "form-control" : "form-control is-invalid"}
                                    disabled={loading}
                                    {...register("abbreviation")}
                                    maxLength={300}
                                />
                                {
                                    errors.abbreviation ?
                                        <div className="invalid-feedback">
                                            {errors.abbreviation.message}
                                        </div>
                                        : null
                                }
                            </div>
                            : null}
                        {type === "outflow"
                            || type === "designation"
                            || type === "role"
                            || type === "category"
                            || type === "department"
                            || type === "leave"
                            || type === "cash" ?
                            <div className="col-12 mb-3">
                                <label className="form-label">Descripción</label>
                                <textarea
                                    rows={2}
                                    maxLength={300}
                                    className={!errors.description ? "form-control" : "form-control is-invalid"}
                                    disabled={loading}
                                    {...register("description")}
                                />
                                {
                                    errors.description ?
                                        <div className="invalid-feedback">
                                            {errors.description.message}
                                        </div>
                                        : null
                                }
                                <p>Máximo 300 caracteres.</p>
                            </div>
                            : null}
                        {type === "department" ?
                            <div className="col-12 mb-3">
                                <label className="form-label">Responsable</label>
                                <Controller
                                    name="idResponsible"
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Select
                                            inputRef={ref}
                                            className={errors.idResponsible ? "form-control is-invalid" : ""}
                                            options={userOptions}
                                            placeholder="Asigna un responsable"
                                            value={value}
                                            onChange={onChange}
                                            isClearable
                                            styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                            noOptionsMessage={() => "Sin opciones"}
                                            isDisabled={loading}
                                        />
                                    )}
                                />
                                {
                                    errors.idResponsible ?
                                        <div className="invalid-feedback">
                                            {errors.idResponsible.message}
                                        </div>
                                        : null
                                }
                            </div>
                            : null}
                        {type === "category"
                            || type === "recipebook"
                            || type === "recipe" ?
                            <div className="col-12 mb-3">
                                <ImageUploader
                                    prefix={`${type}_image`}
                                    containerName="imagenes"
                                    imageGrouperId={`${type}/${currentId ? currentId : generarCodigo()}`}
                                    imagesPath={imagesPath}
                                    onImagesChanged={onImagesChanged} />
                            </div>
                            : null}
                        {/*!isNew ?
                            <div className="col-lg-12 mb-3">
                                <label className="form-label">Estatus</label>
                                <div className="col-lg-12 d-flex justify-content-center">
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            id="estatus_activo"
                                            type="radio"
                                            value="activo"
                                            disabled={loading}
                                            {...register("enabled")}
                                        />
                                        <label className="form-check-label" htmlFor="estatus_activo">Activo</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            id="estatus_inactivo"
                                            type="radio"
                                            value="inactivo"
                                            disabled={loading}
                                            {...register("enabled")}
                                        />
                                        <label className="form-check-label" htmlFor="estatus_inactivo">Inactivo</label>
                                    </div>
                                </div>
                            </div>
                            : null*/}
                    </div>
                    <FormCreatedBy isNew={isNew} />
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <button
                        className="btn btn-cancel me-2"
                        onClick={(e) => onModalHide(e, false)}
                    >
                        Cancelar
                    </button>
                    {
                        loading ?
                            <button disabled={loading} className="btn btn-submit">
                                <Spinner animation="border" role="status" size="sm" />
                            </button>
                            :
                            <input type="submit" value={isNew ? "Registrar" : "Actualizar"} className="btn btn-submit" />
                    }
                </Modal.Footer>
            </form>
        </Modal>
    )
}

AddCatalog.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    type: PropTypes.string.isRequired,
    isNew: PropTypes.bool,
    currentId: PropTypes.number,
    onHide: PropTypes.func
};


export default AddCatalog
