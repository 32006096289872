import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Plus, X } from 'react-feather';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from '../../../core/redux/action';
import { showInternalErrorAlert } from '../customAlert';
import { Articles } from '../../../services/internal/articles';
import Slider from 'react-slick';
import { getFilledSlides } from '../../../core/utils';
import ImageWithBasePath from '../../../core/img/imagewithbasebath';

const CategorySelector = ({ category, requiredSlotQuantity, optionalSlotQuantity, onValidSelection }) => {

  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  const [requiredProducts, setRequiredProducts] = useState([]);
  const [optionalProducts, setOptionalProducts] = useState([]);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentSlot, setCurrentSlot] = useState(null);

  const fixedSliderAmount = 5;

  const sliderSettings = {
    dots: false,
    autoplay: false,
    infinite: true,
    margin: 0,
    speed: 500,
    slidesToShow: 3
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadProducts = async (currentCategoryId) => {
    let filters = {};
    filters.enabled = true;
    if (currentCategoryId) {
      filters.categoryId = currentCategoryId;
    }

    try {
      dispatch(fetchDataRequest());
      const response = await Articles.getList(token, filters);
      //console.log(response)
      setFilteredProducts(response.data.data);
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onShowProductsModal = async (slot) => {
    setShowProductsModal(true);
    setCurrentSlot(slot);
    await onLoadProducts(category.id);
  }

  const onHideProductsModal = () => {
    setCurrentSlot(null);
    setShowProductsModal(false);
  }

  const onProductSelected = (product) => {
    const slot = currentSlot;
    slot.productId = product.id;
    slot.name = product.name;
    slot.unit = product.unmed?.name;
    slot.imagePath = product.imageUrls?.length > 0 ? product.imageUrls[0] : "";
    if (slot.id?.startsWith("rp")) {
      setRequiredProducts(prev => {
        const reqProducts = [...prev.filter((f) => f.id !== slot.id), slot].sort((a, b) => a.id.localeCompare(b.id));
        if (onValidSelection) {
          onValidSelection(category, isValid(), reqProducts, optionalProducts);
        }
        return reqProducts;
      });
    } else {
      setOptionalProducts(prev => {
        const optProducts = [...prev.filter((f) => f.id !== slot.id), slot].sort((a, b) => a.id.localeCompare(b.id));
        if (onValidSelection) {
          onValidSelection(category, isValid(), requiredProducts, optProducts);
        }
        return optProducts;
      });
    }
    setShowProductsModal(false);


  }

  const onRemoveProduct = (slot) => {
    const cleanedSlot = slot;
    cleanedSlot.productId = null;
    cleanedSlot.name = null;
    cleanedSlot.unit = null;
    cleanedSlot.imagePath = null;
    if (cleanedSlot.id?.startsWith("rp")) {
      setRequiredProducts(prev => {
        const reqProducts = [...prev.filter((f) => f.id !== slot.id), cleanedSlot].sort((a, b) => a.id.localeCompare(b.id));
        if (onValidSelection) {
          onValidSelection(category, isValid(), reqProducts, optionalProducts);
        }
        return reqProducts;
      });
    } else {
      setOptionalProducts(prev => {
        const optProducts = [...prev.filter((f) => f.id !== slot.id), cleanedSlot].sort((a, b) => a.id.localeCompare(b.id));
        if (onValidSelection) {
          onValidSelection(category, isValid(), requiredProducts, optProducts);
        }
        return optProducts;
      });
    }
  }

  const isValid = () => {
    return requiredProducts.filter((f) => f.name !== null).length === requiredSlotQuantity;
  }

  useEffect(() => {
    if (requiredSlotQuantity) {
      let rp = [];
      for (let i = 0; i < requiredSlotQuantity; i++) {
        rp.push({
          id: "rp-" + i,
          productId: null,
          name: null,
          unit: null,
          imagePath: null,
        });
      }
      setRequiredProducts(rp);
    } else {
      setRequiredProducts([]);
    }
    if (optionalSlotQuantity) {
      let op = [];
      for (let i = 0; i < optionalSlotQuantity; i++) {
        op.push({
          id: "op-" + i,
          productId: null,
          name: null,
          unit: null,
          imagePath: null,
        });
      }
      setOptionalProducts(op);
    } else {
      setOptionalProducts([]);
    }
  }, [category, requiredSlotQuantity, optionalSlotQuantity]);

  return (
    <>
      <div className="card p-2">
        <h4 className="text-info mb-3">{category.name}</h4>
        <div className="row pos-products">
          {requiredProducts.map((item) => (
            <div
              key={item.id}
              className="col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-3 mb-3"
            >
              <div
                key={item.id}
                className="product-info card"
                style={{ border: '2px solid #e1e1e1' }}
              >
                {item.name &&
                  <a className={loading ? "d-none" : "d-flex justify-content-end"}>
                    <X
                      onClick={() => onRemoveProduct(item)}
                    />
                  </a>
                }
                <a
                  className="img-bg"
                  onClick={() => onShowProductsModal(item)}
                >
                  {item.imagePath
                    ?
                    <ImageWithBasePath
                      src={item.imagePath}
                      alt="Producto"
                    />
                    :
                    <div style={{ padding: '30%' }}><Plus size={40} /></div>
                  }
                </a>
                <h5 className="text-purple">{item.unit}</h5>
                <h4 className="text-secondary text-capitalize">{item?.name}</h4>
                {!item.name && <h4>Elige el producto <span className="text-danger">*</span></h4>}
              </div>
            </div>
          ))}
          {optionalProducts.map((item) => (
            <div
              key={item.id}
              className="col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-3 mb-3"
            >
              <div
                className="product-info card"
                style={{ border: '2px dashed #e1e1e1' }}
              >
                {item.name &&
                  <a className={loading ? "d-none" : "d-flex justify-content-end"}>
                    <X
                      onClick={() => onRemoveProduct(item)}
                    />
                  </a>
                }
                <a
                  className="img-bg"
                  onClick={() => onShowProductsModal(item)}
                >
                  {item.imagePath
                    ?
                    <ImageWithBasePath
                      src={item.imagePath}
                      alt="Producto"
                    />
                    :
                    <div style={{ padding: '30%' }}><Plus size={40} /></div>
                  }
                </a>
                <h5 className="text-purple">{item.unit}</h5>
                <h4 className="text-secondary text-capitalize">{item?.name}</h4>
                {!item.name && <h4>Elige el producto</h4>}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal show={showProductsModal} centered onHide={onHideProductsModal} backdrop="static" keyboard={false} size='lg'>
        <Modal.Header className="custom-modal-header" closeButton={!loading}>
          <Modal.Title>
            <h4>{category.name}</h4>
          </Modal.Title>
        </Modal.Header>
        {loading &&
          <Modal.Body>
            <div id="global-loader" style={{ position: 'relative', height: 80 }}>
              <div className="whirly-loader"></div>
            </div>
          </Modal.Body>
        }
        {filteredProducts.length === 0 ?
          <Modal.Body>
            <aside>
              <div className="block-section">
                <div className="order-total text-center">
                  <h6 className="text-primary">No hay productos para esta categoría</h6>
                </div>
              </div>
            </aside>
          </Modal.Body>
          :
          <Modal.Body>
            <p>Presiona sobre la imagen para elegir el producto</p>
            <Slider {...sliderSettings}>
              {getFilledSlides(fixedSliderAmount, filteredProducts).map((item) => (
                <div
                  key={item.code}
                  id={item.id}
                  className="pos-products"
                  onClick={() => onProductSelected(item)}
                >
                  <div className="product-info card">
                    <a className="img-bg">
                      <ImageWithBasePath
                        src={item.imageUrls?.length > 0
                          ? item.imageUrls[0]
                          : "assets/img/product/default.png"}
                        alt="Producto"
                      />
                    </a>
                    <h5 className="text-purple">{item.unmed?.name}</h5>
                    <h4 className="text-secondary text-uppercase">{item.name}</h4>
                  </div>
                </div>
              ))}
            </Slider>
          </Modal.Body>
        }
        <Modal.Footer className='justify-content-center'>
          <button
            className="btn btn-cancel me-2"
            onClick={(e) => onHideProductsModal(e, false)}
            disabled={loading}
          >
            Regresar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

CategorySelector.propTypes = {
  category: PropTypes.object.isRequired,
  requiredSlotQuantity: PropTypes.number.isRequired,
  optionalSlotQuantity: PropTypes.number.isRequired,
  onValidSelection: PropTypes.func,
};

export default CategorySelector;