import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import FormCreatedBy from "../../../feature-module/components/createdByForm";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from 'date-fns/locale/es';
import { Calendar } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Holidays } from "../../../services/internal/holidays";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from "../../redux/action";
import { showInternalErrorAlert } from "../../../feature-module/components/customAlert";
import { Modal, Spinner } from "react-bootstrap";
import variables from "../../../style/scss/utils/_variables.scss";
import { calcularDiasEntreFechas, fechaFormat } from "../../utils";

registerLocale('es', es)

const sd = new Date();
const ed = new Date();
sd.setHours(0, 0, 0, 0);
ed.setHours(0, 0, 0, 0);


const AddHolidays = ({ id, show = false, isNew = false, currentId, onHide }) => {
    const loading = useSelector(state => state.loading);
    const token = useSelector(state => state.token);
    const dispatch = useDispatch();
    const { setValue, register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const [startDate, setStartDate] = useState(sd);
    const [endDate, setEndDate] = useState(ed);

    const calculateResponse = (isLoad, data) => {

        if (isNew) {
            return Holidays.postSingle(token, data);
        }

        if (isLoad) {
            return Holidays.getSingle(token, data.id);
        }

        return Holidays.putSingle(token, data);
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onSaveItem = async (formData) => {
        try {
            let data = Holidays.requestData;
            data = {};

            data.id = currentId;
            data.description = formData.description;

            formData.start.setHours(0, 0, 0, 0);
            formData.end.setHours(0, 0, 0, 0);

            data.start = formData.start;
            data.end = formData.end;

            dispatch(fetchDataRequest());

            const response = await calculateResponse(false, data);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            clearForm();
            onModalHide(null, true);
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadItem = async () => {
        try {
            let data = Holidays.requestData;
            data = {};
            data.id = currentId;

            dispatch(fetchDataRequest());

            const response = await calculateResponse(true, data);
            //console.log(response)

            setValue('description', response.data.data.description);
            let start = new Date(response.data.data.start);
            let end = new Date(response.data.data.end);
            setValue('start', start);
            setValue('end', end);
            setStartDate(start);
            setEndDate(end);

            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const clearForm = () => {
        reset(formValues => ({
            ...formValues,
            description: null,
            start: null,
            end: null,
        }));
    }

    const onModalHide = (e, refresh) => {
        e?.preventDefault();
        clearForm();
        onHide(refresh);
    }

    /*const onGenerateCode = () => {
        setValue('code', generarCodigo());
    }*/

    useEffect(() => {
        if (currentId) {
            onLoadItem();
        }
    }, [currentId]);

    return (
        <Modal show={show} centered onHide={onModalHide} id={id}>
            <form onSubmit={handleSubmit((data) => onSaveItem(data))}>
                <Modal.Header className="custom-modal-header" closeButton={!loading}>
                    <Modal.Title>
                        <h4>{isNew ? "Registrar día festivo" : "Actualizar día festivo"}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-12 mb-3">
                            <label className="form-label">Descripción<span className="text-danger"> *</span></label>
                            <textarea
                                rows={2}
                                maxLength={300}
                                className={!errors.description ? "form-control" : "form-control is-invalid"}
                                disabled={loading}
                                {...register("description", {
                                    required: 'Es necesaria una descripción'
                                })}
                            />
                            {
                                errors.description ?
                                    <div className="invalid-feedback">
                                        {errors.description.message}
                                    </div>
                                    : null
                            }
                            <p>Máximo 300 caracteres.</p>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 mb-3">
                            <label className="form-label">Fecha inicio<span className="text-danger"> *</span></label>
                            <div className="input-group flex-wrap">
                                <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                                <Controller
                                    control={control}
                                    name='start'
                                    render={({ field }) => (
                                        <DatePicker
                                            onChange={(date) => { field.onChange(date); setStartDate(date); }}
                                            selected={field.value}
                                            locale="es"
                                            dateFormat={fechaFormat}
                                            todayButton="Clic aquí para Hoy"
                                            className={!errors.start ? "form-control" : "form-control is-invalid"}
                                            wrapperClassName={"form-control"}
                                        />
                                    )}
                                    rules={{ required: 'Requerido' }}
                                />
                                {
                                    errors.start ?
                                        <div className="invalid-feedback">
                                            {errors.start.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 mb-3">
                            <label className="form-label">Fecha fin<span className="text-danger"> *</span></label>
                            <div className="input-group flex-wrap">
                                <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                                <Controller
                                    control={control}
                                    name='end'
                                    render={({ field }) => (
                                        <DatePicker
                                            minDate={startDate}
                                            onChange={(date) => { field.onChange(date); setEndDate(date); }}
                                            selected={field.value}
                                            locale="es"
                                            dateFormat={fechaFormat}
                                            todayButton="Clic aquí para Hoy"
                                            className={!errors.end ? "form-control" : "form-control is-invalid"}
                                            wrapperClassName={"form-control"}
                                        />
                                    )}
                                    rules={{ required: 'Requerido' }}
                                />
                                {
                                    errors.end ?
                                        <div className="invalid-feedback">
                                            {errors.end.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12 mb-3">
                            <label className="form-label">Cantidad de días</label>
                            <div className="input-group">
                                <input type="text"
                                    className="form-control"
                                    value={calcularDiasEntreFechas(startDate, endDate)}
                                    disabled />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <FormCreatedBy isNew={isNew} />
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <button
                        className="btn btn-cancel me-2"
                        onClick={(e) => onModalHide(e, false)}
                        disabled={loading}
                    >
                        Cancelar
                    </button>
                    {
                        loading ?
                            <button disabled={loading} className="btn btn-submit">
                                <Spinner animation="border" role="status" size="sm" />
                            </button>
                            :
                            <input type="submit" value={isNew ? "Registrar" : "Actualizar"} className="btn btn-submit" />
                    }
                </Modal.Footer>
            </form>
        </Modal>
    );
};

AddHolidays.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    isNew: PropTypes.bool,
    currentId: PropTypes.number,
    onHide: PropTypes.func
};


export default AddHolidays;
