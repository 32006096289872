import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../../../Router/all_routes";
import {
    ArrowLeft,
    ChevronDown,
    ChevronUp,
    Info,
    LifeBuoy,
    List,
    PlusCircle,
    Image,
    DollarSign
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setArticleList, setCategoryOptions, setShowRemoteResponseToast, setToogleHeader, setUnitOptions } from "../../../core/redux/action";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import PropTypes from 'prop-types';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import FormCreatedBy from "../createdByForm";
import AddCatalog from "../../../core/modals/inventory/addcatalog";
import { HelpCircle } from "react-feather";
import { showInternalErrorAlert } from "../customAlert";
import { formatearMoneda, generarCodigo, noOptionsText, priceRegex, removeUrlsParams } from "../../../core/utils";
import { Controller, useForm } from "react-hook-form";
import { Articles } from "../../../services/internal/articles";
import variables from "../../../style/scss/utils/_variables.scss";
import { Catalogs } from "../../../services/internal/catalogs";
import ImageUploader from "../fileUpload/ImageUpload";
import { Images } from "../../../services/internal/images";

registerLocale('es', es)


const FormProduct = ({ isNew = false }) => {
    const categoryOptions = useSelector((state) => state.categoryOptions);
    const unitOptions = useSelector((state) => state.unitOptions);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setValue, getValues, register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [showUnitModal, setShowUnitModal] = useState(false);
    const [imagesUrl, setImagesUrl] = useState([]);
    const [code, setCode] = useState(null);
    const [productId, setProductId] = useState(null);

    const [searchParams] = useSearchParams();
    const paramProductId = searchParams.get('code');
    const route = all_routes;

    const data = useSelector((state) => state.toggle_header);

    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Colapsar
        </Tooltip>
    );

    const renderInfoTooltip = (props) => (
        <Tooltip id="info-tooltip" {...props}>
            Agrega o quita existencias del inventario
        </Tooltip>
    );

    const calculateResponse = (isLoad, data) => {

        if (isNew) {
            return Articles.postSingle(token, data);
        }

        if (isLoad) {
            return Articles.getSingle(token, data.id);
        }

        return Articles.putSingle(token, data);
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadCatalogs = async () => {
        try {
            dispatch(fetchDataRequest());

            const responseC = await Catalogs.getOptions(token, Catalogs.Name.Categories);
            dispatch(setCategoryOptions(responseC.data.data));

            const responseU = await Catalogs.getOptions(token, Catalogs.Name.Unmeds);
            dispatch(setUnitOptions(responseU.data.data));

            dispatch(fetchDataSuccess(responseU.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadItem = async () => {
        try {
            let data = Articles.requestData;
            data = {};
            data.id = paramProductId ? Number(paramProductId) : productId;
            if (isNaN(data.id)) {
                return;
            }

            dispatch(fetchDataRequest());

            let categories = [];
            let unmeds = [];
            if (categoryOptions.length === 0) {
                const responseC = await Catalogs.getOptions(token, Catalogs.Name.Categories);
                categories = responseC.data.data;
                dispatch(setCategoryOptions(responseC.data.data));
            } else {
                categories = categoryOptions;
            }
            if (unitOptions.length === 0) {
                const responseU = await Catalogs.getOptions(token, Catalogs.Name.Unmeds);
                unmeds = responseU.data.data;
                dispatch(setUnitOptions(responseU.data.data));
            } else {
                unmeds = unitOptions;
            }

            const response = await calculateResponse(true, data);

            setValue('name', response.data.data.name);
            setValue('description', response.data.data.description);
            setValue('code', response.data.data.code);
            setValue('idCategory', categories.find((i) => i.value === response.data.data.categoryId));
            setValue('idUnmed', unmeds.find((i) => i.value === response.data.data.unmedId));
            setValue('salePrice', formatearMoneda(response.data.data.salePrice, false));
            setValue('buyPrice', formatearMoneda(response.data.data.buyPrice, false));
            setValue('recipeResult', response.data.data.recipeResult);
            setValue('inventoried', response.data.data.inventoried ? 'activo' : 'inactivo');

            setCode(response.data.data.code);
            setProductId(response.data.data.id);
            let imageUrls = response.data.data.imageUrls;
            if (imageUrls?.length > 0) {
                setImagesUrl(imageUrls);
            }

            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onSaveItem = async (formData) => {
        try {
            let data = Articles.requestData;
            data = {};
            if (productId) {
                data.id = productId;
            }

            data.name = formData.name;
            data.description = formData.description;
            data.code = formData.code;
            data.categoryId = formData.idCategory?.value;
            data.unmedId = formData.idUnmed?.value;
            data.salePrice = Number(formData.salePrice);
            data.buyPrice = Number(formData.buyPrice);
            data.recipeResult = formData.recipeResult;
            data.inventoried = formData.inventoried === 'activo';

            dispatch(fetchDataRequest());

            const response = await calculateResponse(false, data);
            setProductId(response.data.data.id);
            dispatch(fetchDataSuccess(response.data.success));


            if (imagesUrl?.length > 0) {
                let imagesData = Images.requestData;
                imagesData = {};
                imagesData.articleId = response.data.data.id;
                imagesData.imagePaths = removeUrlsParams(imagesUrl);

                const responseI = isNew
                    ? await Images.postProduct(token, imagesData)
                    : await Images.putProduct(token, imagesData);
                dispatch(fetchDataSuccess(responseI.data.success));
            }
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setArticleList([]));
            dispatch(setShowRemoteResponseToast(true));
            clearForm();
        }
        catch (err) {
            handleException(err);
        }
    }

    const onImagesChanged = (imagesUrl) => {
        setImagesUrl(imagesUrl);
    }

    const onHideCategoryModal = async () => {
        await onLoadCatalogs();
        setShowCategoryModal(false);
    }

    const onHideUnitModal = async () => {
        await onLoadCatalogs();
        setShowUnitModal(false);
    }

    const clearForm = async () => {
        if (isNew) {
            reset(formValues => ({
                ...formValues,
                name: null,
                description: null,
                code: generarCodigo(),
                idCategory: null,
                idUnmed: null,
                salePrice: null,
                buyPrice: null,
                recipeResult: null,
                inventoried: null,
                stockBranches: [],
                stock: null
            }));
            setImagesUrl([]);
            navigate(route.productlist);
        } else {
            await onLoadItem();
        }
    }

    const onGenerateCode = (e) => {
        e?.preventDefault();
        const newCode = generarCodigo();
        setCode(newCode);
        setValue('code', newCode);

    }

    const onSamePrice = (value) => {
        if (value) {
            const { salePrice } = getValues();
            setValue('buyPrice', salePrice);
        } else {
            setValue('buyPrice', '');
        }
    }

    useEffect(() => {
        if (isNew === false && paramProductId) {
            onLoadItem();
        } else {
            onLoadCatalogs();
        }

        if (!code) {
            onGenerateCode();
        }
    }, [code, paramProductId]);

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>{isNew ? "Registrar producto" : "Actualizar producto"}</h4>
                            <h6>{isNew ? "Puedes registrar un producto y su información." : "Puedes actualizar un producto y su información."}</h6>
                        </div>
                    </div>
                    <ul className="table-top-head">
                        <li>
                            <div className="page-btn">
                                <Link
                                    to={route.productlist}
                                    className="btn btn-secondary"
                                    disabled={loading}
                                >
                                    <ArrowLeft className="me-2" />
                                    Lista de Productos
                                </Link>
                            </div>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                                <a
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Colapsar"
                                    id="collapse-header"
                                    className={data ? "active" : ""}
                                    onClick={() => {
                                        dispatch(setToogleHeader(!data));
                                    }}
                                >
                                    <ChevronUp className="feather-chevron-up" />
                                </a>
                            </OverlayTrigger>
                        </li>
                    </ul>
                </div>
                {/* /add */}
                <form onSubmit={handleSubmit((data) => onSaveItem(data))}>
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingOne">
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-controls="collapseOne"
                                        >
                                            <div className="addproduct-icon">
                                                <h5>
                                                    <Info className="add-info" />
                                                    <span>Información del producto</span>
                                                </h5>
                                                <a>
                                                    <ChevronDown className="chevron-down-add" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Nombre del producto<span className="text-danger"> *</span></label>
                                                    <input
                                                        type="text"
                                                        className={!errors.name ? "form-control" : "form-control is-invalid"}
                                                        {...register("name",
                                                            {
                                                                required: "Nombre es requerido.",
                                                            }
                                                        )}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                    {
                                                        errors.name ?
                                                            <div className="invalid-feedback">
                                                                {errors.name.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Código de sistema<span className="text-danger"> *</span></label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className={!errors.code ? "form-control" : "form-control is-invalid"}
                                                            placeholder="Genera un código"
                                                            {...register("code",
                                                                {
                                                                    required: "Genera un código por favor.",
                                                                }
                                                            )}
                                                            disabled
                                                        />
                                                        {
                                                            isNew ?
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={(e) => onGenerateCode(e)}
                                                                    disabled={loading}
                                                                >
                                                                    Generar
                                                                </button>
                                                                : null
                                                        }
                                                        {
                                                            errors.code ?
                                                                <div className="invalid-feedback">
                                                                    {errors.code.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <div className="add-newplus">
                                                        <label className="form-label">Categoría<span className="text-danger"> *</span></label>
                                                        <a
                                                            onClick={() => setShowCategoryModal(true)}
                                                        >
                                                            <PlusCircle className="plus-down-add text-secondary" />
                                                            <span className="text-secondary">Nueva</span>
                                                        </a>
                                                    </div>
                                                    <Controller
                                                        name="idCategory"
                                                        control={control}
                                                        render={({ field: { onChange, value, ref } }) => (
                                                            <Select
                                                                inputRef={ref}
                                                                className={errors.idCategory ? "form-control is-invalid" : ""}
                                                                options={categoryOptions}
                                                                placeholder="Elige una categoría"
                                                                value={value}
                                                                onChange={onChange}
                                                                noOptionsMessage={() => noOptionsText}
                                                                isDisabled={loading}
                                                                isClearable
                                                            />
                                                        )}
                                                        rules={{ required: 'Requerido' }}
                                                    />
                                                    {
                                                        errors.idCategory ?
                                                            <div className="invalid-feedback">
                                                                {errors.idCategory.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <div className="add-newplus">
                                                        <label className="form-label">Unidad de medida<span className="text-danger"> *</span></label>
                                                        <a
                                                            onClick={() => setShowUnitModal(true)}
                                                        >
                                                            <PlusCircle className="plus-down-add text-secondary" />
                                                            <span className="text-secondary">Nueva</span>
                                                        </a>
                                                    </div>
                                                    <Controller
                                                        name="idUnmed"
                                                        control={control}
                                                        render={({ field: { onChange, value, ref } }) => (
                                                            <Select
                                                                inputRef={ref}
                                                                className={errors.idUnmed ? "form-control is-invalid" : ""}
                                                                options={unitOptions}
                                                                placeholder="Elige una opción"
                                                                value={value}
                                                                onChange={onChange}
                                                                noOptionsMessage={() => noOptionsText}
                                                                isDisabled={loading}
                                                                isClearable
                                                            />
                                                        )}
                                                        rules={{ required: 'Requerido' }}
                                                    />
                                                    {
                                                        errors.idUnmed ?
                                                            <div className="invalid-feedback">
                                                                {errors.idUnmed.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            {/* Editor */}
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="form-label">Descripción</label>
                                                    <textarea
                                                        className="form-control"
                                                        rows={5}
                                                        maxLength={800}
                                                        placeholder="Escribe una descripción..."
                                                        {...register("description",
                                                            {
                                                                maxLength: 800
                                                            }
                                                        )}
                                                        disabled={loading}
                                                    />
                                                    <p className="mb-3">Máximo 800 caracteres</p>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label className="form-label me-1">¿Inventariado?<span className="text-danger"> *</span></label>
                                                    <OverlayTrigger placement="top" overlay={renderInfoTooltip}>
                                                        <a
                                                            className="link-primary"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            title="Inventariado"
                                                            id="collapse-header"
                                                        >
                                                            <HelpCircle size={18} />
                                                        </a>
                                                    </OverlayTrigger>
                                                    <div className="form-check mb-2">
                                                        <input
                                                            className={!errors.inventoried ? "form-check-input" : "form-check-input is-invalid"}
                                                            id="estatus_activo"
                                                            type="radio"
                                                            value="activo"
                                                            {...register("inventoried",
                                                                {
                                                                    required: "Es necesario marcar este campo."
                                                                })
                                                            }
                                                            disabled={loading}
                                                        />
                                                        <label className="form-check-label" htmlFor="estatus_activo">Si</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className={!errors.inventoried ? "form-check-input" : "form-check-input is-invalid"}
                                                            id="estatus_inactivo"
                                                            type="radio"
                                                            value="inactivo"
                                                            {...register("inventoried")}
                                                            disabled={loading}
                                                        />
                                                        <label className="form-check-label" htmlFor="estatus_inactivo">No</label>
                                                        {
                                                            errors.inventoried ?
                                                                <div className="invalid-feedback">
                                                                    {errors.inventoried.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /Editor */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample2"
                            >
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingTwo">
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-controls="collapseTwo"
                                        >
                                            <div className="text-editor add-list">
                                                <div className="addproduct-icon list icon">
                                                    <h5>
                                                        <LifeBuoy className="add-info" />
                                                        <span>Precios</span>
                                                    </h5>
                                                    <a>
                                                        <ChevronDown className="chevron-down-add" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample2"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Precio unitario de compra</label>
                                                    <div className="input-group flex-wrap">
                                                        <span className="input-group-text"><DollarSign size={15} color={variables.primaryColor} /></span>
                                                        <input
                                                            type="text"
                                                            placeholder="Escribe el precio de compra"
                                                            className={!errors.buyPrice ? "form-control" : "form-control is-invalid"}
                                                            {...register("buyPrice",
                                                                {
                                                                    pattern: {
                                                                        value: priceRegex,
                                                                        message: "No tiene estructura válida"
                                                                    }
                                                                }
                                                            )}
                                                            disabled={loading}
                                                        />
                                                        {
                                                            errors.buyPrice ?
                                                                <div className="invalid-feedback">
                                                                    {errors.buyPrice.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Precio unitario de venta<span className="text-danger"> *</span></label>
                                                    <div className="input-group flex-wrap">
                                                        <span className="input-group-text"><DollarSign size={15} color={variables.primaryColor} /></span>
                                                        <input
                                                            type="text"
                                                            placeholder="Escribe el precio de venta"
                                                            className={!errors.salePrice ? "form-control" : "form-control is-invalid"}
                                                            {...register("salePrice",
                                                                {
                                                                    required: "Es necesario el precio de venta.",
                                                                    pattern: {
                                                                        value: priceRegex,
                                                                        message: "No tiene estructura válida"
                                                                    },
                                                                    validate: {
                                                                        prices: (value) => {
                                                                            const { buyPrice } = getValues();
                                                                            return Number(buyPrice ? buyPrice : 0) <= Number(value) || "El precio de venta debe ser mayor o igual al precio de compra.";
                                                                        },
                                                                        mayorCero: (value) => {
                                                                            return Number(value) > 0 || "El precio debe ser mayor a cero.";
                                                                        }
                                                                    }
                                                                }
                                                            )}
                                                            disabled={loading}
                                                        />
                                                        {
                                                            errors.salePrice ?
                                                                <div className="invalid-feedback">
                                                                    {errors.salePrice.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-sm-12 col-12 mb-4">
                                                    <label className="checkboxs" >
                                                        <input
                                                            type="checkbox"
                                                            onClick={(e) => onSamePrice(e.target.checked)}
                                                            disabled={loading}
                                                        />
                                                        <span className="checkmarks" />
                                                        Mismo precio unitario de compra que al de venta
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample3"
                            >
                                <div className="accordion-item">
                                    <div
                                        className="accordion-header"
                                        id="headingThree"
                                    >
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-controls="collapseThree"
                                        >
                                            <div className="addproduct-icon list">
                                                <h5>
                                                    <Image className="add-info" />
                                                    <span>Imágenes</span>
                                                </h5>
                                                <a>
                                                    <ChevronDown className="chevron-down-add" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {code &&
                                        <div
                                            id="collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample3"
                                        >
                                            <div className="accordion-body">
                                                <ImageUploader
                                                    prefix="product_image"
                                                    imageGrouperId={code}
                                                    containerName="productos"
                                                    imagesPath={imagesUrl}
                                                    onImagesChanged={onImagesChanged}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample4"
                            >
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingFour">
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-controls="collapseFour"
                                        >
                                            <div className="text-editor add-list">
                                                <div className="addproduct-icon list">
                                                    <h5>
                                                        <List className="add-info" />
                                                        <span>Otros datos</span>
                                                    </h5>
                                                    <a>
                                                        <ChevronDown className="chevron-down-add" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample4"
                                    >
                                        <div className="accordion-body">
                                            <FormCreatedBy />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="d-flex justify-content-center mb-4">
                            {
                                loading ?
                                    <button disabled={loading} className="btn btn-submit">
                                        <Spinner animation="border" role="status" size="sm" />
                                    </button>
                                    :
                                    <input type="submit" value={isNew ? "Registrar" : "Actualizar"} className="btn btn-submit" />
                            }
                        </div>
                    </div>
                </form>
                {/* /add */}
            </div>
            {
                showUnitModal
                    ? <AddCatalog id="add-unit" type="unit" show={showUnitModal} onHide={onHideUnitModal} isNew />
                    : null
            }
            {
                showCategoryModal
                    ? <AddCatalog id="add-category" type="category" show={showCategoryModal} onHide={onHideCategoryModal} isNew />
                    : null
            }
        </div>
    );
};

FormProduct.propTypes = {
    isNew: PropTypes.bool
};

export default FormProduct;
