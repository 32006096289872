import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { useBarcode } from 'next-barcode';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setNotes, setShowRemoteResponseToast } from '../../core/redux/action';
import { showInternalErrorAlert } from '../components/customAlert';
import { Link, useSearchParams } from 'react-router-dom';
import ToolsHead from '../components/toolsHead';
import { fechaFormat, fechaHoraFormat, formatearFecha, timeZone } from '../../core/utils';
import NotesTable from '../components/notes';
import { all_routes } from '../../Router/all_routes';
import { Vacations } from '../../services/internal/vacations';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import es from 'date-fns/locale/es';

const LeaveEmployeeDetails = () => {

    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const token = useSelector((state) => state.token);
    const loading = useSelector((state) => state.loading);
    const codeParam = searchParams.get('code');
    const [dataItem, setDataItem] = useState(null);
    const route = all_routes;
    const { inputRef } = useBarcode({
        value: dataItem?.code,
        options: {
            //format: "EAN13",
        }
    });

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadItem = async () => {
        try {
            let data = Vacations.requestData;
            data = {};
            data.id = Number(codeParam);
            if (isNaN(data.id)) {
                return;
            }
            dispatch(fetchDataRequest());

            const response = await Vacations.getSingle(token, codeParam);

            setDataItem(response.data.data);
            //console.log(response)
            if (response.data.data?.comment) {
                dispatch(setNotes(JSON.parse(response.data.data?.comment)));
            } else {
                dispatch(setNotes(null));
            }
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    useEffect(() => {
        if (codeParam) {
            onLoadItem();
        }
    }, [codeParam]);

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="page-title">
                        <h4>Ficha de Vacaciones</h4>
                        <h6>Información completa de la solicitud de vacaciones.</h6>
                    </div>
                    <ToolsHead showExportData={true} showPrint={true} onReload={onLoadItem} />
                </div>
                {/* /add */}
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                                        <div className="bar-code-view">
                                            <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                                        <div className="bar-code-view">
                                            {loading &&
                                                <div id="global-loader" style={{ position: 'relative', height: 80 }}>
                                                    <div className="whirly-loader"></div>
                                                </div>
                                            }
                                            <svg ref={inputRef} />
                                        </div>
                                    </div>
                                </div>
                                <div className="productdetails mb-4">
                                    <ul className="product-bar">
                                        <li>
                                            <h4>Código</h4>
                                            <h6 className="text-orange fw-semibold">{dataItem?.code}</h6>
                                        </li>
                                        <li>
                                            <h4>Fecha</h4>
                                            <h6 className="text-secondary">{dataItem?.requestedDate ? formatearFecha(dataItem?.requestedDate, fechaHoraFormat) : "NA"}</h6>
                                        </li>
                                        <li>
                                            <h4>Solicitada por:</h4>
                                            <h6 className="text-primary">{dataItem?.requesterUser ? dataItem?.requesterUser?.alias : "NA"}</h6>
                                        </li>
                                        <li>
                                            <h4>Fecha de aprobación</h4>
                                            <h6 className="text-secondary">{dataItem?.approvedDate ? formatearFecha(dataItem?.approvedDate, fechaHoraFormat) : "NA"}</h6>
                                        </li>
                                        <li>
                                            <h4>Aprobada por:</h4>
                                            <h6 className="text-primary">{dataItem?.approvalUser ? dataItem?.approvalUser?.alias : "NA"}</h6>
                                        </li>
                                        <li>
                                            <h4>Fecha Inicio</h4>
                                            <h6 className="text-info">{formatearFecha(dataItem?.dateStart, fechaFormat)}</h6>
                                        </li>
                                        <li>
                                            <h4>Fecha fin</h4>
                                            <h6 className="text-info">{formatearFecha(dataItem?.dateEnd, fechaFormat)}</h6>
                                        </li>
                                        <li>
                                            <h4>Estatus</h4>
                                            <h6 className="text-purple fw-semibold">{dataItem?.status?.value}</h6>
                                        </li>
                                    </ul>
                                </div>
                                {dataItem &&
                                    <div className="row">
                                        <div className="col-2" />
                                        <div className="col-lg-8 col-md-12 col-sm-12">
                                            <FullCalendar
                                                timeZone={timeZone}
                                                plugins={[dayGridPlugin]}
                                                buttonText={{
                                                    today: 'Hoy',
                                                }}
                                                headerToolbar={{
                                                    left: 'title',
                                                    center: '',
                                                    right: 'prev,next',
                                                }}
                                                initialDate={dataItem.dateStart}
                                                firstDay={1}
                                                locale={es}
                                                initialView='dayGridMonth'
                                                events={[{ title: 'Vacaciones', start: dataItem.dateStart, end: dataItem.dateEnd }]}
                                            />
                                        </div>
                                        <div className="col-2" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="row p-3">
                                <h4>Comentarios</h4>
                            </div>
                            <div className="card-body">
                                <NotesTable />
                            </div>
                        </div>
                        {
                            dataItem?.transferId ?
                                <div className="card">
                                    <div className="row p-3">
                                        <h4>Traslado</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row text-center">
                                            <Link
                                                to={route.transferdetails + "?code=" + dataItem?.transferId}
                                                className="text-blue fw-bold">
                                                Ir al traslado
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                : null}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaveEmployeeDetails
