import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PlusCircle, HelpCircle } from 'feather-icons-react/build/IconComponents';
import { useDispatch, useSelector } from 'react-redux';
import { Edit, Trash2 } from 'react-feather';
import ToolsHead from '../components/toolsHead';
import AddCatalog from '../../core/modals/inventory/addcatalog';
import { Table } from 'antd';
import { Categories } from '../../services/internal/categories';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCategoryList, setShowRemoteResponseToast } from '../../core/redux/action';
import { showInternalErrorAlert, showSuccessAlert } from '../components/customAlert';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import { Functions, Modules, verifyPermission } from '../../Router/authorization';

const CategoryList = () => {
    const dataSource = useSelector((state) => state.categoryList);
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const [filterName, setFilterName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [newItem, setNewItem] = useState(false);
    const [currentId, setCurrentId] = useState(null);

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el nombre de la categoría
        </Tooltip>
    );

    const onChangeName = (e) => {
        setFilterName(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure({ data: err.response.data, status: err.response.status, statusText: err.response.statusText }));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadCategories = async () => {

        let filters = {};
        filters.enabled = true;
        if (filterName) {
            filters.name = filterName;
        }

        try {
            dispatch(fetchDataRequest());
            const response = await Categories.getList(token, filters);
            //console.log(response)
            dispatch(setCategoryList(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onDeleteItem = async (id) => {
        try {
            dispatch(fetchDataRequest());
            const response = await Categories.deleteSingle(token, id);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            showSuccessAlert("Hemos eliminado categoría.");
        }
        catch (err) {
            handleException(err);
        }
        await onLoadCategories();
    }

    const showDeleteItemAlert = async (index, text) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onDeleteItem(index.id);
        } else {
            MySwal.close();
        }
    };

    const onNewItem = () => {
        setCurrentId(null);
        setNewItem(true);
        setShowModal(true);
    }

    const onEditItem = (index) => {
        setCurrentId(index.id);
        setNewItem(false);
        setShowModal(true);
    }

    const onHideModal = async (refresh) => {
        setCurrentId(null);
        setShowModal(false);
        if (refresh) {
            await onLoadCategories();
        }
    }

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadCategories();
        }
    }, []);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id
        },
        {
            title: "Categoría",
            dataIndex: "name",
            render: (text, record) => (
                <div className="productimgname">
                    <a key={record.id} className="product-img stock-img pe-none">
                        <ImageWithBasePath
                            className="me-2"
                            alt="categoria"
                            src={record.imagePath
                                ? record.imagePath
                                : "assets/img/categories/default.png"}
                        />
                        <span className="text-secondary fw-semibold">{record.name}</span>
                    </a>
                </div>
            ),
            sorter: (a, b) => a.name?.localeCompare(b.name)
        },
        {
            title: "Descripción",
            dataIndex: "description",
            sorter: (a, b) => a.description?.localeCompare(b.description)
        },
        {
            title: "Cantidad de productos",
            dataIndex: "articleCount",
            align: "center",
            render: (text) => (
                <span className="badge badges-info">{text}</span>
            ),
            sorter: (a, b) => a.articleCount?.length - b.articleCount?.length,
        },
        {
            title: 'Acción',
            dataIndex: 'actions',
            align: "center",
            key: 'actions',
            render: (record, index) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        {verifyPermission(rolePermissions, Modules.Categorias, Functions.Modificar) &&
                            <a className="me-2 p-2" onClick={() => onEditItem(index)}>
                                <Edit className="feather-edit" />
                            </a>
                        }
                        {verifyPermission(rolePermissions, Modules.Categorias, Functions.Eliminar) &&
                            <a className="confirm-text p-2" onClick={() => showDeleteItemAlert(index, "una categoría")} >
                                <Trash2 className="feather-trash-2" />
                            </a>
                        }
                    </div>
                </div>
            )
        },
    ]

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Categorías</h4>
                            <h6>Puedes gestionar la información de las categorías del sistema.</h6>
                        </div>
                    </div>
                    <ToolsHead
                        showExportData={true}
                        showExcel={verifyPermission(rolePermissions, Modules.Categorias, Functions.Exportar)}
                        onReload={onLoadCategories}
                    />
                    {verifyPermission(rolePermissions, Modules.Categorias, Functions.Registrar) &&
                        <div className="page-btn">
                            <a
                                className="btn btn-added"
                                onClick={onNewItem}
                            >
                                <PlusCircle className="me-2" />
                                Nueva Categoría
                            </a>
                        </div>
                    }
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeName(e)}
                                        onBlur={onLoadCategories}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table
                                className="table"
                                rowKey={(record) => record?.id}
                                columns={columns}
                                loading={loading}
                                dataSource={dataSource.filter((i) => i.id !== null)}
                                size="small"
                                pagination={
                                    {
                                        position: ["topRight"],
                                        total: dataSource.filter((i) => i.id !== null).length,
                                        showTotal: (total) => `Total: ${total}`,
                                        showSizeChanger: true
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                {/* /product list */}
            </div>
            {newItem
                ?
                showModal
                    ? <AddCatalog id="add-category" show={showModal} onHide={onHideModal} type='category' isNew={true} />
                    : null
                :
                showModal
                    ? <AddCatalog id="edit-category" show={showModal} onHide={onHideModal} type='category' currentId={currentId} />
                    : null
            }
        </div>
    )
}

export default CategoryList
